// Auth
export const LOGIN = '/admin/adminLogin';
export const FORGOT = '/admin/adminForgetPassword';
export const RESET = '/admin/resetAdminPassword';
export const CHANGE_PASSWORD = '/admin/changeAdminPassword';

// driver Management

export const GET_DRIVER = '/driver/driverList';
export const CREATE_DRIVER = '/driver/addDriverByAdmin';
export const UPDATE_DRIVER = '/driver/updateDriver';
export const DELETE_DRIVER = '/driver/deleteDriver/';
export const ENABLE_DISBLED_DRIVER = '/driver/activeInactiveDriver'
export const CREATE_TRIP = '/trip/create';
export const GET_TRIP_LIST = '/trip/drivertrips/';


// Customer Management
export const GET_CUSTOMER = '/customer/customerList';
export const ENABLE_DISBLED_CUSTOMER = '/customer/activeInactiveCustomer';

// product Management
export const GET_ALL_PRODUCT = '/products/getAllProduct';
export const CREATE_PRODUCT = '/products/createProduct';
export const UPDATE_PRODUCT = '/products/updateProduct';
export const DELETE_PRODUCT = '/products/deleteProduct/';
export const ENABLE_DISBLED_PRODUCT = '/products/activeInactiveProduct';
export const FEATURED_PRODUCT = '/products/updateFeaturedProduct';


// order Management
export const GET_ORDERS = '/order/getOrdersByAdminCon';
export const REFUND_ORDER_PAYMENT = '/order/paymentRefund';
export const GET_ORDER_DETAIL = '/order/orderDetail';

//notifications
export const LIST_NOTIFICATIONS = '/admin/notificationList';
export const READ_NOTIFICATIONS = '/admin/readNotification';
export const READ_ALL_NOTIFICATIONS = '/admin/readAllNotification';
