import React from "react";
import { Card, CardBody, Label } from "reactstrap";
//i18n
import { useTranslation } from "react-i18next";

import HookForm from '../../components/hookForm/hookForm';
import TextField from "../../components/textField/textField";
import CustomButton from '../../components/customButton/customButton';

import { ChangePassParams } from "../../interface/authInterface";

/**
 * Represents the props for the PasswordResetComponent.
 * @interface ChangePasswordComponentProps
 * @property {() => void} t_show1 - Callback function to show additional information.
 * @property {() => void} t_show2 - Callback function to show additional information.
 * @property {() => void} t_show - Callback function to show information.
 * @property {boolean} showconfirm - Flag indicating whether to show the confirmation.
 * @property {boolean} show - Flag indicating whether to show the component.
 * @property {boolean} showPass - Flag indicating whether to showPass the component.
 */
interface ChangePasswordComponentProps {
    resetForm: any;
    t_show2: () => void;
    t_show: () => void;
    t_show1: () => void;
    multiErrorFields: any;
    showconfirm: boolean;
    show: boolean;
    showPass: boolean;
    onFormSubmit: (value: ChangePassParams) => void;
    busy: boolean;
}

/**
 * A React functional component that renders a password reset form.
 * @param {PasswordResetComponentProps} props - The component props.
 * @returns The rendered password reset form.
 */
const ChangePasswordComponent: React.FC<ChangePasswordComponentProps> = (props) => {
    const { t } = useTranslation();
    const { resetForm, t_show2, t_show, t_show1, multiErrorFields, showconfirm, show, onFormSubmit, busy, showPass } = props;

    return (
        <React.Fragment>
            <div className="auth-pages page-content change-password-page">
                <div className="heading">{t('CHANGE_PASSWORD')}</div>
                <Card className="overflow-hidden change-password-style">
                    <CardBody className="Card_Body">
                        <div className="p-2">
                            <HookForm
                                defaultForm={{}}
                                onSubmit={(e) => onFormSubmit(e)}>
                                {(formMethod) => {
                                    return (
                                        <div className={formMethod?.formState.errors ? 'invalid' : 'sdfgsd'}>
                                            <div className='mb-4'>
                                                <div className='d-flex'>
                                                    <Label>{t("OLD_PASSWORD")}</Label>
                                                    <Label className='requiredStyle'>* </Label>
                                                </div>
                                                <div className="input-group auth-pass-inputgroup"
                                                >
                                                    <TextField
                                                        formMethod={formMethod}
                                                        rules={resetForm.oldpassword.validate}
                                                        name={resetForm.oldpassword.name}
                                                        errors={formMethod?.formState.errors}
                                                        type={!showPass ? "text" : "password"}
                                                        placeholder={t("ENTER_OLD_PASSWORD")}
                                                    />
                                                    <button
                                                        onClick={t_show1}
                                                        className='btn btn-light '
                                                        type='button'
                                                        id='password-addon'
                                                        tabIndex={-1}                                                                >
                                                        <i className={!showPass ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i>{" "}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <div className='d-flex'>
                                                    <Label>{t('NEW_PASSWORD')}</Label>
                                                    <Label className="requiredStyle">*</Label>
                                                </div>
                                                <div className="input-group auth-pass-inputgroup">
                                                    <TextField
                                                        formMethod={formMethod}
                                                        rules={resetForm.newpassword.validate}
                                                        multiErrorFields={multiErrorFields}
                                                        errors={formMethod?.formState.errors}
                                                        name={resetForm.newpassword.name}
                                                        type={!show ? 'text' : 'password'}
                                                        placeholder={t('ENTER_NEW_PASSWORD')}
                                                        defaultValue=""
                                                        value={undefined}
                                                        onChange={(value) => {
                                                            formMethod?.watch('password') &&
                                                                formMethod.trigger('password');
                                                        }}
                                                    />
                                                    <button tabIndex={-1} onClick={t_show} className="btn btn-light " type="button" id="password-addon">
                                                        <i className={!show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i> </button>
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <div className='d-flex'>
                                                    <Label>{t('CONFIRM_PASSWORD')}</Label>
                                                    <Label className="requiredStyle">*</Label>
                                                </div>
                                                <div className="input-group auth-pass-inputgroup">
                                                    <TextField
                                                        formMethod={formMethod}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                            },
                                                            validate: {
                                                                matchPassword: (value: string) => (value &&
                                                                    value === formMethod?.watch('newpassword')) || t('BOTH_PASSWORD_MUST_BE_MATCH')
                                                            }
                                                        }}
                                                        name={resetForm.password.name}
                                                        errors={formMethod?.formState.errors}
                                                        type={!showconfirm ? 'text' : 'password'}
                                                        placeholder={t('CONFIRM_PASSWORD')}
                                                        onChange={(e) => {
                                                            formMethod?.watch('newpassword') &&
                                                                formMethod.trigger('newpassword');
                                                        }}
                                                        value={undefined}
                                                    />
                                                    <button tabIndex={-1} onClick={t_show2} className="btn btn-light " type="button" id="password-addon">
                                                        <i className={!showconfirm ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i> </button>
                                                </div>
                                            </div>
                                            <div className="d-grid button-row-style">
                                                <CustomButton
                                                    className='custom-btn padding8-16 font14 bgDarkGreen txtColor'
                                                    type="submit" title={t('CHANGE')}
                                                    disabled={!formMethod?.formState.isValid}
                                                    loading={busy} />
                                            </div>
                                        </div>
                                    );
                                }}
                            </HookForm>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}
export default ChangePasswordComponent;
