/**
 * Imports the necessary functions and components from the '@testing-library/react' library
 * and the 'App' component from the './App' file.
 * @returns None
 */
import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

/**
 * Import translation JSON files for different languages.
 * @returns None
 */
import translationGr from "./locales/gt/translation.json";
import translationSP from "./locales/hi/translation.json";
import translationENG from "./locales/en/translation.json";

/**
 * An object that contains different language translations as resources.
 * @property {Object} gt - The translation resource for the "gt" language.
 * @property {Object} hi - The translation resource for the "hi" language.
 * @property {Object} eng - The translation resource for the "eng" language.
 */
const resources = {
  gt: {
    translation: translationGr,
  },
  hi: {
    translation: translationSP,
  },
  eng: {
    translation: translationENG,
  },
};

const language = localStorage.getItem("I18N_LANGUAGE");

/**
 * Checks if the "language" variable is falsy (null, undefined, false, 0, empty string) and if so,
 * sets the "I18N_LANGUAGE" item in the localStorage to "eng" (English).
 * @param {any} language - The language variable to check.
 * @returns None
 */
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "eng");
}
/**
 * Initializes the i18n library with the provided configuration.
 * @param {object} resources - The translation resources.
 * @param {string} [lng] - The language to use. Defaults to the value stored in localStorage or "eng".
 * @param {string} [fallbackLng] - The fallback language to use. Defaults to "eng".
 * @param {boolean} [keySeparator] - Whether to use a key separator. Defaults to false.
 * @param {object} [interpolation] - The interpolation configuration.
 * @param {boolean} [interpolation.escapeValue] - Whether to escape the values. Defaults to false.
 * @returns None
 */

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "eng",
    fallbackLng: "eng",

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
