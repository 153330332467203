import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { customerData, enableCustomerErrorMessage, enableCustomerParams, enableCustomerRes } from "../../../interface/customerInterface";
import customerService from "../services/customerService";




/**
 * Async thunk action creator that fetches the list of customers.
 * @returns A promise that resolves to the response data of the customer list.
 */
export const customerListResponce = createAsyncThunk("customer/list", async (queryParams: any) => {
    const res = await customerService.getCustomer(queryParams);
    return res?.data;
});

export const enableDisbledCustomerResponce = createAsyncThunk("customer/enable" , async (data: enableCustomerParams) => {
     const res = await customerService.enableDisbledCustomer(data);
     return {res, params:data};
})

interface AuthState {
    customerResponce:  any, 
    enableDisbledCustomerRes: AxiosResponse<string, enableCustomerRes>
    enableCustomerError: enableCustomerErrorMessage,
    customerQueryParams: any,
    totalCustomer: any
    isLoading: boolean
  }



  const customerSlice = createSlice({
    name: "driver",
    initialState: {
        customerResponce: [],
        enableDisbledCustomerRes: {},
        enableCustomerError: {},
        customerQueryParams: {page: 1, doc: 10},
        totalCustomer: {},
        isLoading: false
    
    } as AuthState,
    reducers: {
      setCustomerQueryParams: (state, action) => {
        state.customerQueryParams = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(customerListResponce.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(customerListResponce.fulfilled, (state, action) => {          
          state.isLoading = false;
          state.totalCustomer = action.payload.totalCustomer
          // state.customerResponce = action.payload;
          if (state.customerQueryParams.page === 1) state.customerResponce = action.payload.customers
          else state.customerResponce = [...state.customerResponce, ...action.payload.customers];
        })
        .addCase(customerListResponce.rejected, (state) => {
          state.isLoading = false;
        });

// enbale-disbled
        builder
     .addCase(enableDisbledCustomerResponce.pending, (state) => {
        state.isLoading = true;
        })
     .addCase(enableDisbledCustomerResponce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.enableDisbledCustomerRes = action.payload.res
        state.customerResponce = state.customerResponce.map((item: any) => {        
        if (item._id === action.payload?.params?.id) {            
          return { ...item, status: action.payload?.params?.status };
        }
        return item;
      });

    })
    .addCase(enableDisbledCustomerResponce.rejected, (state, action) => {
       state.isLoading = false;
       state.enableCustomerError = action?.error
      });  

    },
  });

  export const { setCustomerQueryParams } = customerSlice.actions

export default customerSlice.reducer;