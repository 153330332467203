/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import moment from "moment";
import momentTz from 'moment-timezone';
import "./driverScheduleTripComponent";
import DriverScheduleTripComponent from './driverScheduleTripComponent';

import { AppDispatch } from "../../../interface/driverInterface";
import { showToast } from "../../../store/features/toast/toastSlice";
import useWindowDimension from "../../../components/hooks/useWindowDimension";
import { createTripResponce } from "../../../store/features/driverManagement/driversSlice";
import { Helmet } from 'react-helmet';

/**
 * A container component for scheduling a trip for a driver.
 * @param {React.FC} props - The props for the component.
 * @returns The rendered component.
 */
const DriverScheduleTripContainer: React.FC = (props) => {
  const navigate = useNavigate();
  const dimensions = useWindowDimension();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const driverDetails = location?.state || {};
  const [busy, setBusy] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [compareDate, setCompareDate] = useState('');
  const [compareTime, setCompareTime] = useState('');
  const [latAndLng, setLatAndLng] = useState({ lat: 56.462002, lng: -2.970700 });
  const [locationValue, setLocationValue] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [pageTitleHeight, setPageTitleHeight] = useState<number>(0);
  const [buttonRowHeight, setButtonRowHeight] = useState<number>(0);
  const [bottomButtonRowHeight, setBottomButtonRowHeight] = useState<number>(0);
  const [rangeValue, setRangeValue] = useState<number>(2);
  const [timeError, setTimeError] = useState("")
  const [currentTime, setCurrentTime] = useState("")




  useEffect(() => {
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    const buttonRowHeight = document.getElementsByClassName("back-row-style")[0] as HTMLElement;
    const pageTitleHeight = document.getElementsByClassName("heading")[0] as HTMLElement;
    const bottomButtonRowHeight = document.getElementsByClassName("Form_buton")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
    if (pageTitleHeight) {
      setPageTitleHeight(pageTitleHeight.offsetHeight);
    }
    if (buttonRowHeight) {
      setButtonRowHeight(buttonRowHeight.offsetHeight);
    }
    if (bottomButtonRowHeight) {
      setBottomButtonRowHeight(bottomButtonRowHeight.offsetHeight);
    }
    if (!localStorage.getItem('TripId')) {
      const newNumber = Math.floor(1000 + Math.random() * 9000);
      localStorage.setItem('TripId', newNumber.toString())
    }

    window.localStorage.setItem('timezone', '')
  }, []);

  

  const [expirydatetime, setExpirydatetime] = useState<Date | string | undefined>(undefined);


  const currentDate = new Date();
  // const currentFormat = "YYYY-MM-DD";
  const currentFormat = "YYYY-MM-DD HH:mm a";
  const currentformattedDate = moment(currentDate).format(currentFormat);

  const newFormat = "YYYY-MM-DD";
  const mincurrentformattedDate = moment(currentDate).format(newFormat);

  // const currentformattedTime = moment(currentDate).format(newTimeFormat);

  const newTimeFormat = 'HH:mm'
  const date = selectedDate
  const isToday = moment(date).isSame(currentDate, 'day');

  let minimumTime;

  if (isToday) {
    minimumTime = moment(currentDate).format(newTimeFormat);
  } else {
    minimumTime = moment(date).startOf('day').format(newTimeFormat);
  }  

  /**
   * Formats a given date into a string with the format "day month year, hours:minutes AM/PM".
   * @param {Date} inputDate - The date to format.
   * @returns {string} The formatted date string.
   */
  function formatDate(inputDate: Date) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];


    const dateObject = new Date(inputDate);
    const day = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();
    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");


    const formattedDate = `${day} ${month} ${year}, ${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedDate;
  }

  /**
   * useEffect hook that sets the expirydatetime state variable to the formatted initial date.
   * @returns None
   */
  useEffect(() => {
    // Check if expirydatetime is defined
    // if (expirydatetime) {
    const initialDate = new Date(); // Convert expirydatetime to a Date object
    const initialFormattedDate = formatDate(initialDate);
    setExpirydatetime(initialFormattedDate);
    // }
  }, [expirydatetime]);


  /**
   * Event handler for the change event of the trip start date input field.
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
   * @returns None
   */
  function onChangeTripStartDate(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target?.validity?.valid) return;
    const dt = e?.target?.value; // Assuming 'dt' is a string like '2023-11-07'
    const dateObj: any = new Date(dt); // Convert the string to a Date object
    const formattedDate = formatDate(dateObj);
    setSelectedDate(dt);
    setCompareDate(dateObj === 'Invalid Date' ?  dateObj : dateObj?.toISOString())
    // Rest of your code
  }

  const scheduleForm = {
    tripStartDate: {
      name: "tripStartDate",
      validate: {
        required: {
          value: true,
        },
      },
    },
    tripStartTime: {
      name: "tripStartTime",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };


  const onTimeChange = (e: any) => {
      setCurrentTime(e?.target?.value);
  }
  /**
   * Handles the form submission event and creates a new trip.
   * @param {any} value - The form values submitted.
   * @returns None
   */
  const onFormSubmit = async (value: any) => { 
    setBusy(true);
    const formattedTime = moment(value?.tripStartTime, "HH:mm").format("hh:mm"); 
    let tripStartTime: any = new Date(`${value?.tripStartDate}T${moment(value?.tripStartTime).format('HH:mm')}:00`)
    tripStartTime = moment(tripStartTime).format('DD/MM/YYYY, HH:mm:ss');
    const tripId = localStorage.getItem('TripId')
    const dateObj = {
      date: value?.tripStartDate, // Replace with your date
    };
    const timeObj = {
      time: formattedTime, // Replace with your time
    };
    console.log(`formattedTime ----->>>>`, formattedTime, value?.tripStartDate, value);
    const combinedDateTimeString = `${dateObj.date} ${timeObj.time}`;
    const combinedDateTime = new Date(combinedDateTimeString); 
       const timeZoneResponse = await fetch(
         `https://maps.googleapis.com/maps/api/timezone/json?location=${latAndLng?.lat},${latAndLng?.lng}&timestamp=${combinedDateTime.getTime() / 1000}&key=AIzaSyBqgb0DG-GLY1jQOvjh3PxU1ZkKSeCxNyI`
       );
       const timeZoneData = await timeZoneResponse.json();       
       
       const timeZone = timeZoneData?.timeZoneId;
      setTimeZone(timeZone)     
    
    const params = {
      driverid: driverDetails?._id,
      tripstartdate: combinedDateTime,
      tripstarttime: tripStartTime,
      triparea: (locationValue as any)?.label,
      servingradius: rangeValue,
      lat: latAndLng?.lat,
      long: latAndLng?.lng,
      tripid: tripId,
      timezone: timeZone
    }

    dispatch(createTripResponce((params as any))).then((resultAction) => {
      if (createTripResponce.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            show: false,
            message: (resultAction as any)?.payload?.message,
            type: "success",
          })
        );
        navigate("/driver-management");
        // setAddScheduleLoader(false)
      } else if (createTripResponce.rejected.match(resultAction)) {
        setBusy(false);
        dispatch(
          showToast({
            show: false,
            message: resultAction?.error?.message,
            type: "error",
          })
        );

      }
    })
  };
  /**
   * Handles the change event of an autocomplete input field.
   * @param {any} value - The selected value from the autocomplete input field.
   * @returns None
   * @throws {Error} If there is an error fetching the geolocation.
   */
  const onChangeAutocomplate = async (value: any, formMethod: any, scheduleForm: any) => {
    
    try {
      const results = await geocodeByAddress(value?.label);
      const latLng = await getLatLng(results[0]);
      setLatAndLng(latLng);
      console.log(`latLng, typeof latLng ----->>>>`, latLng, typeof latLng);
      const timeZoneResponse = await fetch(
        `https://maps.googleapis.com/maps/api/timezone/json?location=${latLng?.lat},${latLng?.lng}&timestamp=${new Date().getTime() / 1000}&key=AIzaSyBqgb0DG-GLY1jQOvjh3PxU1ZkKSeCxNyI`
      );
      const timeZoneData = await timeZoneResponse.json();
      const timeZoneVal = timeZoneData?.timeZoneId;
      setTimeZone(timeZoneVal)
      setLocationValue(value)

      const today = new Date(selectedDate);
      const selectedDateTime: any = new Date(today?.toDateString() + ' ' + currentTime)
      const currentTimeWithTimeZone = momentTz(momentTz.tz(timeZoneVal).format('YYYY-MM-DDTHH:mm:ss[Z]'));
        const selectedTimeWithTimeZone = momentTz(`${selectedDate}T${currentTime}:00Z`);
        setCompareTime(selectedDateTime);
        if (selectedTimeWithTimeZone >= currentTimeWithTimeZone) {
          setTimeError("")
        } else {
          setTimeError('aasddd')
        }


      // && timeZone !== ''
      if (timeZoneVal !== timeZone && timeZone !== '' ){
          
        formMethod.setValue(scheduleForm.tripStartDate.name, "");
        formMethod.setValue(scheduleForm.tripStartTime.name, "");
      }
            // if (timeZoneVal !== window.localStorage.getItem('timezone')) {
            //   window.localStorage.setItem('timezone', timeZoneVal)
            // }
      
    } catch (error) {
      console.error('Error fetching geolocation:', error);
    }
  };

  const clearDateWithTimeZone = () => {
    setSelectedDate('');
  }
  return (
  <>
    <Helmet>
      <title>Schedule Trip | Scooply Admin Panel</title>
      <meta name="description" content="Scooply Online Ice-Cream delivery platform." />
    </Helmet>

    <DriverScheduleTripComponent
      dimensions={dimensions}
      headerHeight={headerHeight}
      pageTitleHeight={pageTitleHeight}
      buttonRowHeight={buttonRowHeight}
      bottomButtonRowHeight={bottomButtonRowHeight}
      scheduleForm={scheduleForm}
      handleSubmitSchedule={onFormSubmit}
      onCancelSchedule={() => {
        navigate("/driver-management");
      }}
      currentformattedDate={currentformattedDate}
      expirydatetime={expirydatetime}
      onChangeTripStartDate={onChangeTripStartDate}
      rangeValue={rangeValue}
      setRangeValue={setRangeValue}
      onChangeAutocomplate={onChangeAutocomplate}
      locationValue={locationValue}
      latAndLng={latAndLng}
      driverDetails={driverDetails}
      currentDate={mincurrentformattedDate}
      currentformattedTime={minimumTime}
      timeError={timeError}
      setTimeError={setTimeError}
      compareDate={compareDate}
      setCompareTime={setCompareTime}
      busy={busy}
      selectedDate={selectedDate}
      onTimeChange={onTimeChange}
      currentTime={currentTime}
      timeZone={timeZone}
      setLocationValue={setLocationValue}
      clearDateWithTimeZone={clearDateWithTimeZone}
    />
  </>);
};

export default DriverScheduleTripContainer;
