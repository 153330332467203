import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { AddProductParams, createErrorProduct, createProductData, cupdateErrorProduct, deleteProductData, deleteProductError, enableProductError, EnableProductParams, enbaleProductRes, featuredProductError, featuredProductRes, isFeaturedProductParams, productData, updateProductParams, updateProductRes } from "../../../interface/productInterface";
import productService from "../services/productService";


export const productListResponce = createAsyncThunk("product/list", async (queryParams: any) => {
    const res = await productService.getAllProduct(queryParams);
    return res?.data;
});

export const createProductResponce = createAsyncThunk("product/create", async (data:AddProductParams) => {
  const res = await productService.createProducts(data);
  return res;
});


export const updateProductResponce = createAsyncThunk("product/update", async (data: updateProductParams) => {
  const res = await productService.updateProduct(data);
  return {res, params:data}
});


export const deleteProductResponce = createAsyncThunk("product/delete", async (data: string) => {
  const res = await productService.deleteProduct(data);
  return {res, params: data};
});

export const enableDisbledProductResponce = createAsyncThunk("product/enable", async (data: EnableProductParams) => {
    const res = await productService.enableDisbledProduct(data);
    return {res, params:data};
});

export const idFeaturedProductResponce = createAsyncThunk("product/featured", async (data: isFeaturedProductParams) => {
  const res = await productService.isFeaturedProduct(data);
  return {res, params:data};
});


interface ProductState {
    productResponce: any,
    createProductRes: AxiosResponse<string, createProductData>,
    createProductError: createErrorProduct,
    updateProductRes: AxiosResponse<string, updateProductRes>,
    updateProductError: cupdateErrorProduct,
    deleteProductRes: AxiosResponse<string, deleteProductData>,
    deleteProductError: deleteProductError,
    isLoading: boolean,
    enableDisbledProductRes: AxiosResponse<string, enbaleProductRes>,
    enableProductError: enableProductError
    featuredProductRes: AxiosResponse<string, featuredProductRes>,
    featuredProductError: featuredProductError,
    programQueryParams: any,
    productTotal:  any
  }

const productSlice = createSlice({
    name: "driver",
    initialState: {
      productResponce: [],
      createProductRes: {},
      createProductError: {},
      updateProductRes: {},
      updateProductError: {},
      deleteProductRes: {},
      deleteProductError: {},
      enableDisbledProductRes: {},
      enableProductError: {},
      featuredProductRes: {},
      featuredProductError: {},
      programQueryParams: {page: 1, doc: 8},
      productTotal: '',
      isLoading: false
    } as ProductState,
    reducers: {
      // setProductResponce: (state, action) => {        
      //   state.productResponce = action.payload.payload
      // }
      setProgramQueryParams: (state, action) => {
         
        state.programQueryParams = action.payload
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(productListResponce.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(productListResponce.fulfilled, (state, action) => {  
          state.isLoading = false;
          state.productTotal = action.payload.totalProducts
          // state.productResponce = action.payload;
          if (state.programQueryParams.page === 1) {
            state.productResponce = action.payload.products;

          } else {

            const existingIds = new Set(state.productResponce.map((item: any) => item._id));
            const newItems = action.payload.products.filter((item: any) => !existingIds.has(item._id));
            state.productResponce = state.productResponce.concat(newItems);

            // console.log('action.payload.products', action.payload.products);
            // state.productResponce = state.productResponce.concat(action.payload.products);
            // state.productResponce.push(action.payload.products);
            // const variableData = state.productResponce;
            // console.log('...state.productResponce', variableData);
            // state.productResponce = [...variableData, ...action.payload.products];
            // console.log('state.productResponce',state.productResponce);
          }
        })
        .addCase(productListResponce.rejected, (state) => {
          state.isLoading = false;
        });
 // create

      builder
      .addCase(createProductResponce.pending, (state) => {
        state.isLoading = true;
       })
      .addCase(createProductResponce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createProductRes = action.payload;
      })
      .addCase(createProductResponce.rejected, (state, action) => {
       state.isLoading = false;
       state.createProductError = action?.error
      });


// delete
      builder
    .addCase(deleteProductResponce.pending, (state) => {
        state.isLoading = true;
        })
    .addCase(deleteProductResponce.fulfilled, (state, action) => {            
        state.isLoading = false;
        state.deleteProductRes = action.payload.res
        state.productResponce = state.productResponce?.filter((item: any) => item?._id !== action?.payload?.params)
        })
   .addCase(deleteProductResponce.rejected, (state, action) => {
        state.isLoading = false;
        state.deleteProductError = action?.error
      });  


       // update

    builder
    .addCase(updateProductResponce.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(updateProductResponce.fulfilled, (state, action) => {      
      state.isLoading = false;
      state.updateProductRes = action.payload.res
      state.productResponce = state.productResponce?.map((item: any) => {
        if (item?._id === action?.payload?.params?.id) {              
          return { 
             ...item,
             productname: action.payload.params.productname,
             description: action.payload.params.description,
             price: action.payload.params.price,
             isFeatured: false,
             image: action.payload.params.image
            }     
        }
        return item;
      })
    })
    .addCase(updateProductResponce.rejected, (state, action) => {
      state.isLoading = false;
      state.updateProductError = action?.error
    });

// enbale-disbled
     builder
     .addCase(enableDisbledProductResponce.pending, (state) => {
        state.isLoading = true;
        })
     .addCase(enableDisbledProductResponce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.enableDisbledProductRes = action.payload.res
        state.productResponce = state.productResponce.map((item: any) => {        
        if (item._id === action.payload?.params?.id) {            
          return { ...item, status: action.payload?.params?.status };
        }
        return item;
      });
    })
    .addCase(enableDisbledProductResponce.rejected, (state, action) => {
       state.isLoading = false;
       state.enableProductError = action?.error
      });  


// Featured 
      builder
      .addCase(idFeaturedProductResponce.pending, (state) => {
        state.isLoading = true;
       })
      .addCase(idFeaturedProductResponce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.featuredProductRes = action.payload?.res
        state.productResponce = state.productResponce.map((item: any) => {
          if (item?._id === action.payload.params.id) {
            return {
              ...item,
              isfeatured: action.payload.params.isFeatured
            };
          }
            return item;
        })
      })
      .addCase(idFeaturedProductResponce.rejected, (state, action) => {
       state.isLoading = false;
       state.featuredProductError = action?.error
      });

    },  
  });

//   export const {
//     setProductResponce
   
// } = productSlice.actions;
export const { setProgramQueryParams } = productSlice.actions

export default productSlice.reducer;
