/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * An object containing various regular expressions for common validation purposes.
 * @property {string} BASE_URL - The base URL for the application.
 * @property {object} REGEX - An object containing various regular expressions.
 *   @property {RegExp} EMAIL - Regular expression for validating email addresses.
 *   @property {RegExp} SPECIALCHARACTERS - Regular expression for finding special characters in a string.
 *   @property {RegExp} NUMBER - Regular expression for finding numbers in a string.
 *   @property {RegExp} NAME - Regular expression for validating names.
 *   @property {RegExp} ALPHABETCOMMA - Regular expression for validating strings with alphabets, numbers, and commas.
 */
const Constant = {
    BASE_URL: process.env.REACT_APP_BASE_URL,
    REGEX: {
        EMAIL: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
        SPECIALCHARACTERS: /[!@#$%^&*)(+=._-]/g,
        NUMBER: /[0-9]/,
        // NAME: /^[a-zA-Z.'\- ]+$/,
        // NAME: /^[a-zA-Z\-\s.\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]*$/,
        NAME: /^[ a-zA-Z.'\-\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F-\u024F\u1E00-\u1EFF]+$/,
        ALPHABETCOMMA: /^[ a-zA-Z0-9.]+$/i,
        ADDRESS: /^[a-zA-Z0-9\s.,#-]+$/,
        ALPHANUMERIC: /^[a-zA-Z0-9\s\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u024F]+$/i,
        LOWERCASEUPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,
        NUMERIC: /^\d*\.?\d*$/,
        NUMONLY: /^\d*$/,
        QUANTITY: /^[0-9]{8}$/,
        PERCENTAGE: /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/,
        POSITIVENUMBERS: /^[1-9][0-9]*$/,
        URL: /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[A-Za-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
        LOWERCASE: /[a-z]/,
        UPPERCASE: /[A-Z]/,
        SPECIALCHARSREGEX: /^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?\\/~`|-]+$/,
        PAYNUMBER: /^\d{5}$/,
        AMOUNT: /^\$?((0\.[1-9]\d{0,1})|([1-9]\d{0,4}))(,\d{2})*(\.\d{1,2})?$/
    },
    SESSIONSTORAGEKEYS: {
        REMEMBER_ME: 'scooply_rememberMe',
        SCOOPLY_REMEMBER_ME_EMAIL: 'scooply_rememberMe_email',
        SCOOPLY_REMEMBER_ME_PASSWORD: 'scooply_rememberMe_password',
        OTP: 'otp',
        RESENDOTP: 'resendOtp',
        RESET_TOKEN: 'resetToken',
        EXPIRE_TIME: 'expireTime'
    },
    LOCALSTORAGEKEYS: {
        ACCESSTOKEN: 'accessToken',
        RESET_PASS: 'resetPass',
        REFRESHTOKEN: 'refreshToken',
        TRIP_ID: 'TripId',
        CUSTOMERNAME: 'customerName',
        DRIVERNAME: 'driverName',
        CURRENTAB: 'currentTab',
        DRIVERSEARCHVALUE: 'driverSearchValue',
        SEARCHVALUE: 'searchValue',
        CUSTOMERSEARCHVALUE:'customerSearchValue',
        PENDING_ORDER_DATETIME: 'orderDate'
        // REFRESHTOKEN: 'refreshToken'
    },
    GOOGLEMAPAPI: {
        APIKEY: "AIzaSyBqgb0DG-GLY1jQOvjh3PxU1ZkKSeCxNyI"
    },
    ORDER_STATUS: {
        REFUND: 'refund',
        REFUNDED: 'refunded',
        FAILED: 'failed',
        NO_SHOW: 'no show',
        PENDING: 'pending',
        DELIVERED: 'delivered'
    },
    ORDER_API_STATUS: {
        PENDING: "PENDING",
        CUSTOMER_NO_SHOW: "NO-SHOW-CUSTOMER",
        DRIVER_NO_SHOW: "NO-SHOW-DRIVER",
        DELIVERED: "DELIVERED",
        REFUNDED: "REFUNDED",
        PAYMENT_FAILED: "PAYMENT_FAILED"
    }
};
export default Constant;
