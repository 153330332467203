import * as React from 'react';
import { Card, CardBody, Col, Container, Row, Button } from "reactstrap";
//i18n
import { useTranslation } from "react-i18next";
import OtpInput from 'react-otp-input';

import protect from "../../../assets/images/protect.svg";

import HookForm from '../../../components/hookForm/hookForm';
import CustomButton from '../../../components/customButton/customButton';

import "../auth.css";
import Logo from '../../../components/logo/logo';
import CardTopHeader from '../../../components/cardTopHeader/cardTopHeader';
import { IotpInterface } from './otpinterface';

/**
 * Represents the props for the AuthTwoStepVerification component.
 * @interface AuthTwoStepVerificationProps
 * @property {boolean} busy - Indicates if the component is currently busy.
 * @property {(e: React.FormEvent) => void} onFormSubmit - Event handler for form submission.
 * @property {string} digitValue - The current value of the OTP input field.
 * @property {(otp: string) => void} onOtpChange - Event handler for OTP input change.
 * @property {number} timer - The remaining time in seconds for the OTP validity.
 * @property {() => void} handleResendClick - Event handler for resend button click.
 */
interface OtpVerificationProps {
    busy: boolean;
    onFormSubmit: (e: React.FormEvent) => void;
    digitValue: string;
    onOtpChange: (otp: string) => void;
    timer: number;
    handleResendClick: () => void;
    otpForm: IotpInterface;
    email: string
}

/**
 * A React functional component that renders a two-step verification form.
 * @param {OtpVerificationProps} props - The props for the component.
 * @returns The rendered two-step verification form.
 */
const OtpVerificationComponnet: React.FC<OtpVerificationProps> = (props) => {
    const { t } = useTranslation();
    const { busy, onFormSubmit, digitValue, onOtpChange, timer, handleResendClick, email } = props;

    return (
        <>
            <div className="auth-pages">
                <Container>
                    <Row className="justify-content-center">
                        <Logo />
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden forgotStyle">
                                <CardTopHeader
                                    labelTitle={t('Verification')}
                                    textShow={false}
                                    rightImage={protect}
                                    labelText={''}
                                />
                                <CardBody className="Card_Body">
                                    <div className="p-3">
                                        <p className="forgotTxtStyle">
                                            {t('PLEASE_ENTER_THE_4-DIGIT_CODE_WEVE_SENT_TO_YOU')}{" "}<br></br><br></br>
                                            <span className="fw-semibold">
                                                {email}
                                            </span>
                                        </p>
                                        <HookForm
                                            defaultForm={{}}
                                            onSubmit={(e: React.FormEvent) => onFormSubmit(e)}>
                                            {(formMethod) => {
                                                return (
                                                    <>
                                                        <Row className="otp-form">
                                                            <OtpInput
                                                                onChange={onOtpChange}
                                                                value={digitValue}
                                                                numInputs={4}
                                                                inputType={'tel'}
                                                                renderSeparator={(): JSX.Element => <span>  </span>}
                                                                containerStyle='otp-container mb-4'
                                                                renderInput={(inputProps, index) => {
                                                                    return (
                                                                        <input
                                                                            {...inputProps}
                                                                            key={index}
                                                                            className={inputProps.value !== '' ? ' otp-input-none-style' : 'otpInputFillStyle otp-input-none-style'}
                                                                        />
                                                                    )
                                                                }}
                                                            />

                                                        </Row>
                                                        <div className="mb-4 resend-code-style">
                                                            <p>{t('NO_CODE')}</p>
                                                            {timer > 0 ? (
                                                                <p className='resend-code-timer-style'>{t('SEND_AGAIN_IN')}<span>{timer}s</span></p>
                                                            ) : (
                                                                <Button onClick={handleResendClick}>
                                                                    {t('SEND_AGAIN')}
                                                                </Button>
                                                            )}
                                                        </div>
                                                        <div className="d-grid">
                                                            <CustomButton
                                                                className='custom-btn padding8-16 font14 bgDarkGreen txtColor'
                                                                type="submit"
                                                                title={t('Verify')}
                                                                disabled={digitValue?.length < 4}
                                                                loading={busy}
                                                                children={undefined}
                                                                icon={undefined}
                                                            />
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        </HookForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="copy-right-text-style text-center">
                                <p>{t('© 2023 Scooply')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default OtpVerificationComponnet;
