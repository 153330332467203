/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from 'lodash';

import CustomerManagementComponent from './customerManagementComponent';
import useWindowDimension from "../../components/hooks/useWindowDimension";

import { AppDispatch } from "../../interface/driverInterface";
import { showToast } from "../../store/features/toast/toastSlice";

import "./customerManagementComponent";
import { customerListResponce, enableDisbledCustomerResponce, setCustomerQueryParams } from "../../store/features/customerManagement/customerSlice";
import { customerData } from "../../interface/customerInterface";
import { Helmet } from 'react-helmet';
import Constant from "../../util/constant";
/**
 * A container component for managing customer data.
 * @returns A React functional component.
 */
const CustomerManagementContainer: React.FC = (props) => {
  const { t } = useTranslation();
  const pageSizeRef = useRef(15);
  const buttonDisable = useRef(false);
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const dimensions = useWindowDimension();
  const costomerList = useSelector((state) => (state as any)?.customer?.customerResponce)
  console.log('costomerList: ', costomerList);
  const totalCustomer = useSelector((state) => (state as any)?.customer?.totalCustomer)
  console.log('totalCustomer: ', totalCustomer);
  const customerQueryParams = useSelector((state) => (state as any)?.customer?.customerQueryParams)
  const [searchValue, setSearchValue] = useState<string | any>(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERSEARCHVALUE) || '');
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [defaultNumber,] = useState('');
  const [itemName, setItemName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [enableBtnLoader, setEnableBtnLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [placeHolder, setPlaceHolder] = useState<boolean>(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [pageTitleHeight, setPageTitleHeight] = useState<number>(0);
  const [buttonRowHeight, setButtonRowHeight] = useState<number>(0);
  const closeDeactivateModal = () => setShowStatusModal(false);
  const [loadeMore, setLoadMore] = useState<boolean>(true);

  const onTableFilterChange = (filterText: any) => {
    setSearchValue(filterText);
  };

  const searchFilterClear = () => {
    tableContainerRef.current!.querySelectorAll("button")[0].click();
  };


  useEffect(() => {
    let search
    if (searchValue) {search = searchValue}
    dispatch(setCustomerQueryParams({page: 1, doc: 12,  search: search}))
    getList({page: 1, doc: 12, search: search})
    window.localStorage.setItem('currentTab', '')
  }, [searchValue]);

  useEffect(() => {
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.CUSTOMERSEARCHVALUE, searchValue)
 
  }, [searchValue]);


  /**
   * Retrieves a list of customers asynchronously and handles the response.
   * @returns None
   */
  const getList = async (queryParams: any) => {

   setLoading(true)
    try {
      const responce = await dispatch(customerListResponce(queryParams))
  console.log('responce', responce);
  
      if (responce?.payload?.customers?.length < 10) {
        setLoadMore(false)
      } else {
        setLoadMore(true)
      }
      pageSizeRef.current += responce?.payload?.customers?.length;
      setPlaceHolder(false);
      setLoading(false);
      
    } catch (error) {
      setPlaceHolder(false);
      setLoading(false);
    }


    // dispatch(customerListResponce()).then((resultAction) => {
    //   if (customerListResponce.fulfilled.match(resultAction)) {
    //     setPlaceHolder(false);
    //   } else if (customerListResponce.rejected.match(resultAction)) {
    //     console.log('resultAction', resultAction);
    //   }
    // })
  }

  const columns = [
    {
      label: (
        <>
          {t("CUSTOMER_DETAILS")}
        </>
      ),
      key: "fullname",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("PHONE_NUMBER")}
        </>
      ),
      key: "phonenumber",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("LOCATION")}
        </>
      ),
      key: "location",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("NO_OF_ORDERS")}
        </>
      ),
      key: "nooforders",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("ACTIONS")}
        </>
      ),
      key: "actions",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("VIEW_ORDERS")}
        </>
      ),
      key: "vieworders",
      filter: false,
      sorting: false,
    },
  ];


  /**
   * Handles the action when a checkbox is checked.
   * @param {customerData} e - The event object.
   * @param {customerData} item - The customer data object associated with the checkbox.
   * @returns None
   */
  const onCheckAction = (e: customerData, item: customerData) => {
    setCustomerId(item?._id);
    setItemName(item?.fullname || '-');
    const status = item?.status;
    if (status === "active") {
      setShowStatusModal(true);
    } else {
      UnableAction(e, item._id);
    }
  };

  /**
   * Handles the logic for proceeding with a certain action.
   * @returns None
   */
  const onProcced = async () => {
    setEnableBtnLoader(true);
    const params = {
      status: 'deactive',
      id: customerId
    }
    dispatch(enableDisbledCustomerResponce(params)).then((resultAction) => {
      if (enableDisbledCustomerResponce.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            show: false,
            message: (resultAction as any)?.payload.res.message,
            type: "success",
          })
        );
        setShowStatusModal(false);
        setEnableBtnLoader(false);
      } else if (enableDisbledCustomerResponce.rejected.match(resultAction)) {
        setShowStatusModal(false);
        setEnableBtnLoader(false);
        dispatch(
          showToast({
            show: false,
            message: resultAction?.error?.message,
            type: "error",
          })
        );
      }
    })
  };

  /**
   * Handles the action when a user is unable to perform a certain action.
   * @param {any} e - The event object.
   * @param {any} id - The ID of the user.
   * @returns None
   */
  const UnableAction = async (e: customerData, id: string) => {
    if (!buttonDisable.current) {
      buttonDisable.current = true
      const params = {
        status: 'active',
        id: id
      }
      dispatch(enableDisbledCustomerResponce(params)).then((resultAction) => {
        if (enableDisbledCustomerResponce.fulfilled.match(resultAction)) {
          dispatch(
            showToast({
              show: false,
              message: (resultAction as any)?.payload.res.message,
              type: "success",
            })
          );
          setShowStatusModal(false);
          buttonDisable.current = false
        } else if (enableDisbledCustomerResponce.rejected.match(resultAction)) {
          buttonDisable.current = false
          dispatch(
            showToast({
              show: false,
              message: resultAction?.error?.message,
              type: "error",
            })
          );
        }
      })
    }
  };


  /**
   * Loads more items when triggered, with a delay of 2000 milliseconds.
   * @returns None
   */
  const loadMoreItems = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      pageSizeRef.current += 10;
    }, 2000);
  };
  /**
   * Debounces the handleTableScroll function to prevent it from being called too frequently.
   * When the table container is scrolled, it checks if the scroll position is at the bottom
   * and if there are more items to load. If both conditions are met, it calls the loadMoreItems function.
   * @returns None
   */
  const handleTableScroll = debounce(() => {
    console.log('inside the condition');
    
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      const isAtBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight;
        console.log('costomerList', costomerList?.length, totalCustomer);
        
      if (totalCustomer > costomerList?.length) {
        console.log('not in');
        
        dispatch(setCustomerQueryParams({ page : customerQueryParams?.page + 1 , doc: customerQueryParams?.doc}))
        getList({page : customerQueryParams?.page + 1 , doc: customerQueryParams?.doc})
        // loadMoreItems();
      }
    }
  }, 200);

  /**
   * Adds an event listener to the table container element to handle scroll events.
   * The event listener is added when the component mounts and removed when the component unmounts.
   * @param {Function} handleTableScroll - The function to handle the table scroll event.
   * @returns None
   */
  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleTableScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleTableScroll);
      }
    };
  }, [handleTableScroll]);
  /**
   * useEffect hook that calculates and sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    const pageTitleHeight = document.getElementsByClassName("heading")[0] as HTMLElement;
    const buttonRowHeight = document.getElementsByClassName("addBtn")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
    if (pageTitleHeight) {
      setPageTitleHeight(pageTitleHeight.offsetHeight);
    }
    if (buttonRowHeight) {
      setButtonRowHeight(buttonRowHeight.offsetHeight);
    }
  }, []);


  return (
    <>
      <Helmet>
        <title>Customer Management | Scooply Admin Panel</title>
        <meta name="description" content="View customer orders, see your most loyal customers, and manage customer info." />
      </Helmet>
      <CustomerManagementComponent
        onSlideSwitch={onCheckAction}
        columns={columns}
        onProcced={onProcced}
        customerData={costomerList}
        showStatusModal={showStatusModal}
        noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1700123419/Scooply/web/project-image/customer.svg'}
        NoDataTitle={t("NO_RESULT_FOUND")}
        NoDataDesc={t("PLEASE_TRY_AGAIN")}
        emptyDataTitle={t("NO_CUSTOMERS_TO_SHOW")}
        noDataFoundText={t('PLEASE_TRY_AGAIN_LATER')}
        searchValueData={`Your search <strong>“${searchValue}”</strong> did not match any customer.`}
        noDataAddButtonTitle={t("ADD_DRIVER")}
        searchClearTitle={t("CLEAR_SEARCH")}
        closeDeactiveModal={closeDeactivateModal}
        onTableFilterChange={onTableFilterChange}
        placeHolderShimer={placeHolder}
        dimensions={dimensions}
        defaultNumber={defaultNumber}
        headerHeight={headerHeight}
        pageTitleHeight={pageTitleHeight}
        buttonRowHeight={buttonRowHeight}
        searchValue={searchValue}
        itemName={itemName}
        searchFilterClear={searchFilterClear}
        loading={loading}
        tableContainerRef={tableContainerRef}
        itemsPerPage={pageSizeRef.current}
        handleTableBodyScroll={handleTableScroll}
        enableBtnLoader={enableBtnLoader}
      />
    </>
  );
};

export default CustomerManagementContainer;
