import React, { useState, useEffect, ChangeEvent } from 'react';
interface CheckBoxProps {
    labelTitle: string;
    id?: string;
    onCheckedChange?: (checked: boolean) => void;
    register?: React.RefObject<HTMLInputElement>;
    name?: string;
    disabled?: boolean;
    checkboxClass?: string;
    checked?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = (props) => {
    const { labelTitle, id, onCheckedChange, register, name, disabled, checkboxClass, } = props;
    const [checked, setChecked] = useState<boolean>(props.checked || false);

    /**
     * useEffect hook that updates the state of the checkbox when the checked prop changes.
     */
    useEffect(() => {
        setChecked(props.checked || false);
    }, [props.checked]);

    /**
     * Updates the state of the checkbox and calls the onCheckedChange function if it exists.
     */
    const onChecked = (e: ChangeEvent<HTMLInputElement>) => {
        setChecked(e.target.checked);
        onCheckedChange && onCheckedChange(e.target.checked);
    };

    return (
        <div className="checkbox-main">
            <input
                type="checkbox"
                ref={register}
                disabled={disabled}
                name={name}
                value={labelTitle}
                id={id}
                className={'checkbox ' + checkboxClass}
                checked={checked}
                onChange={onChecked}
            />
            <label className='checkboxLabel' id="checkLabel" htmlFor={id}>{labelTitle}</label>
        </div>
    );
};

CheckBox.defaultProps = {
    id: '',
    checked: false,
    labelTitle: 'Title'
};

export default CheckBox;
