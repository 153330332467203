import React from "react";

interface CustomLoadingHTMLProps {
  // tddataList: any[];
}

const CustomLoadingHTML: React.FC<CustomLoadingHTMLProps> = (props) => {
  // const { tddataList } = props;
  return (
    <>
      <div className="infinate-load">
        {/* <div className="spinner-border text-primary" role="status"></div> */}
        <table className='table-shimer'>
          <tr>
            <td className="loading-shimer-col">
              <div className="Shimer"></div>
            </td>
            <td className="loading-shimer-col">
              <div className="Shimer"></div>
            </td>
            <td className="loading-shimer-col">
              <div className="Shimer"></div>
            </td>
            <td className="loading-shimer-col">
              <div className="Shimer"></div>
            </td>
            <td className="loading-shimer-col">
              <div className="Shimer"></div>
            </td>
          </tr>
          {/* <tr>
            {tddataList.map((item: any) => {
              return (
                <td className="loading-shimer-col">
                  <div className="Shimer"></div>
                </td>
              )
            })}
          </tr> */}

        </table >
      </div>

    </>
  );
};

export default CustomLoadingHTML;
