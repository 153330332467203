import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";

import logo from "../../assets/images/sidebar_logo.svg";
import logoSmall from "../../assets/images/sidebar_logo_small.svg";
import Constant from "../../util/constant";

interface SidebarContentProps extends WithTranslation {
  type: string;
}

const SidebarContent: FC<SidebarContentProps> = ({ t, type }) => {
  const [isSubMenuOpen, setSubMenuOpen] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  const handleClick = () => {
    // Add your custom logic here
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.DRIVERSEARCHVALUE, '');
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.CUSTOMERSEARCHVALUE, '');
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.SEARCHVALUE, '');
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME, '');
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME, '');
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.TRIP_ID, '');
  };

  return (
    <>
      <div id="sidebar-menu">
        <div className="navbar-brand-box">
          <Link to="#" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoSmall} alt="" />
            </span>
            <span className="logo-lg">
              <img src={logo} alt="" />
            </span>
          </Link>
        </div>
        <ul className="metis-menu list-unstyled" id="side-menu">
          <li className="menu-title">{t("MANAGEMENT")}</li>
          <>
            <li className="mm-active">
              <NavLink
                to="/order-management"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClick}
              >
                <div className="left-menu">
                  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1814_3861)">
                      <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#FA70AE" />
                      <path d="M31.9999 15.9993C31.9999 14.7331 31.8528 13.5012 31.5748 12.3199L23.0206 3.76562L8.97925 28.233L12.3204 31.5742C13.5018 31.8522 14.7337 31.9993 15.9999 31.9993C24.8365 31.9993 31.9999 24.8359 31.9999 15.9993Z" fill="#E2348B" />
                      <path d="M23.0206 28.2329L21.2654 26.8836L19.5102 28.2329L17.7551 26.8836L15.9999 28.2329L14.2447 26.8836L12.4896 28.2329L10.7344 26.8836L8.97925 28.2329V3.76562L10.7344 5.01569L12.4896 3.76562L14.2447 5.01569L15.9999 3.76562L17.7551 5.01569L19.5102 3.76562L21.2654 5.01569L23.0206 3.76562V28.2329Z" fill="white" />
                      <path d="M21.2655 5.01575L19.5103 3.76562L17.7552 5.01575L16 3.76562V28.233L17.7552 26.8837L19.5103 28.233L21.2655 26.8837L23.0207 28.233V3.76562L21.2655 5.01575Z" fill="#E9EDF5" />
                      <path d="M19.0623 9.39831C19.0623 7.70969 17.6885 6.33594 15.9999 6.33594C14.3113 6.33594 12.9375 7.70969 12.9375 9.39831C12.9373 9.91994 13.0705 10.4329 13.3244 10.8886L15.2263 16.8056H16.7734L18.6753 10.8886C18.9292 10.433 19.0624 9.91995 19.0623 9.39831ZM15.9999 7.96094C16.7924 7.96094 17.4372 8.60575 17.4372 9.39831C17.4372 9.51581 17.4229 9.63213 17.3951 9.74481H14.6047C14.5767 9.63145 14.5625 9.51509 14.5625 9.39831C14.5625 8.60575 15.2073 7.96094 15.9999 7.96094ZM15.9999 13.9019L15.1859 11.3699H16.8138L15.9999 13.9019Z" fill="#707789" />
                      <path d="M16 6.33594V7.96094C16.7926 7.96094 17.4374 8.60575 17.4374 9.39831C17.4374 9.51581 17.4231 9.63213 17.3952 9.74481H16V11.3698H16.8139L16 13.9019V16.8056H16.7735L18.6754 10.8886C18.9293 10.433 19.0625 9.91994 19.0623 9.39831C19.0624 7.70975 17.6886 6.33594 16 6.33594Z" fill="#555A66" />
                      <path d="M15.6051 24.6299C14.7422 24.5484 14.1154 24.2391 13.6514 23.7914L14.3922 22.7412C14.7829 23.132 15.3854 23.4576 16.1261 23.4576C16.6064 23.4576 16.8995 23.2704 16.8995 23.0099C16.8995 22.2691 13.8224 22.8634 13.8224 20.7712C13.8224 19.9491 14.4492 19.2082 15.6051 19.0699V18.3047H16.5087V19.0781C17.16 19.1676 17.7299 19.4118 18.1938 19.8026L17.4286 20.8038C16.9727 20.4294 16.3785 20.2421 15.8412 20.2421C15.4342 20.2421 15.2551 20.4049 15.2551 20.6329C15.2551 21.3248 18.3241 20.8201 18.3241 22.8389C18.3241 23.8158 17.681 24.4996 16.5088 24.6299V25.3707H15.6052L15.6051 24.6299Z" fill="#707789" />
                      <path d="M17.4286 20.8039L18.1938 19.8026C17.7298 19.4118 17.1599 19.1676 16.5087 19.0781V18.3047H16V20.2481C16.4932 20.2811 17.0172 20.4659 17.4286 20.8039ZM16 21.0522V22.5434C16.5057 22.6508 16.8994 22.745 16.8994 23.0099C16.8994 23.2704 16.6064 23.4577 16.1261 23.4577C16.0841 23.4576 16.042 23.4565 16 23.4543V25.3708H16.5087V24.6299C17.6809 24.4997 18.324 23.8159 18.324 22.839C18.3241 21.4677 16.9084 21.2607 16 21.0522Z" fill="#555A66" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1814_3861">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <span>{t("ORDERS")}</span>
                </div>
              </NavLink>
            </li>
            <li className="mm-active">
              <NavLink
                to="/driver-management"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClick}
              >
                <div className="left-menu">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_1814_3884)">
                      <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#FA70AE" />
                      <path d="M32.0004 16.0003C32.0004 15.3996 31.9672 14.8065 31.9027 14.223L22.0742 4.39453L8.76761 7.40928L14.099 12.7407L4.40942 22.8808L13.3024 31.7738C14.1938 31.9249 15.0963 32.0006 16.0004 32.0003C24.8369 32.0003 32.0004 24.8368 32.0004 16.0003Z" fill="#E2348B" />
                      <path d="M18.6167 7.64453H20.4917V12.7624H18.6167V7.64453Z" fill="#AFB4C8" />
                      <path d="M12.9136 7.64453H14.7886V12.7624H12.9136V7.64453Z" fill="#CDD2E1" />
                      <path d="M27.5913 22.7073H4.40942V10.8516H23.1297L27.5913 16.0031V22.7073Z" fill="white" />
                      <path d="M27.5911 16.0031L23.1295 10.8516H17.8364V22.7073H27.5911V16.0031Z" fill="#E9EDF5" />
                      <path d="M4.40942 20.3438H27.5913V22.8821H4.40942V20.3438Z" fill="#E9EDF5" />
                      <path d="M17.8364 20.3438H27.5911V22.8821H17.8364V20.3438Z" fill="#CDD2E1" />
                      <path d="M11.0064 23.5505C11.234 22.3142 10.4162 21.1275 9.17998 20.9C7.94371 20.6724 6.75704 21.4901 6.52948 22.7264C6.30191 23.9627 7.11962 25.1493 8.35589 25.3769C9.59215 25.6045 10.7788 24.7867 11.0064 23.5505Z" fill="#555A66" />
                      <path d="M25.4644 23.4927C25.6654 22.2519 24.8224 21.083 23.5815 20.8821C22.3407 20.6811 21.1718 21.5241 20.9708 22.7649C20.7699 24.0058 21.6129 25.1746 22.8537 25.3756C24.0946 25.5766 25.2634 24.7336 25.4644 23.4927Z" fill="#333940" />
                      <path d="M24.7867 12.7617H20.948V15.9998H27.5911L24.7867 12.7617Z" fill="#1CBBF9" />
                      <path d="M5.5625 14.1367H16.8988V19.0655H5.5625V14.1367Z" fill="#80DBFF" />
                      <path d="M6.69613 15.8114C6.07 15.8114 5.5625 15.3038 5.5625 14.6778V12.7617H7.82975V14.6778C7.82975 15.3038 7.32219 15.8114 6.69613 15.8114Z" fill="#FA70AE" />
                      <path d="M8.96346 15.8114C8.3374 15.8114 7.82983 15.3038 7.82983 14.6778V12.7617H10.0971V14.6778C10.0971 15.3038 9.58958 15.8114 8.96346 15.8114Z" fill="#FF475A" />
                      <path d="M11.2308 15.8114C10.6047 15.8114 10.0972 15.3038 10.0972 14.6778V12.7617H12.3644V14.6778C12.3644 15.3038 11.8569 15.8114 11.2308 15.8114ZM15.7654 15.8114C15.1393 15.8114 14.6317 15.3038 14.6317 14.6778V12.7617H16.899V14.6778C16.899 15.3038 16.3914 15.8114 15.7654 15.8114Z" fill="#FA70AE" />
                      <path d="M13.4979 15.8114C12.8718 15.8114 12.3643 15.3038 12.3643 14.6778V12.7617H14.6315V14.6778C14.6316 15.3038 14.124 15.8114 13.4979 15.8114Z" fill="#FF475A" />
                      <path d="M13.6834 7.64456H9.48579C8.81379 7.64456 8.26904 7.09981 8.26904 6.42775C8.26904 5.75575 8.81379 5.21094 9.48579 5.21094H13.6834C14.3554 5.21094 14.9001 5.75569 14.9001 6.42775C14.9001 7.09981 14.3554 7.64456 13.6834 7.64456Z" fill="white" />
                      <path d="M19.8508 9.43962H12.0444V3.41406H19.8508C21.5147 3.41406 22.8636 4.76294 22.8636 6.42681C22.8636 8.09075 21.5147 9.43962 19.8508 9.43962Z" fill="#FFDC4A" />
                      <path d="M19.8508 3.41406H17.8364V9.43962H19.8508C21.5147 9.43962 22.8636 8.09075 22.8636 6.42681C22.8636 4.76287 21.5147 3.41406 19.8508 3.41406Z" fill="#FDBA12" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1814_3884">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>{t("DRIVERS")}</span>
                </div>
              </NavLink>
            </li>
            <li className="mm-active">
              <NavLink
                to="/customer-management"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClick}
              >
                <div className="left-menu">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_1814_3914)">
                      <path d="M25.8733 28.5913C29.6037 25.6621 32 21.1109 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 21.0898 2.37656 25.6244 6.08056 28.5549L25.8733 28.5913Z" fill="#FA70AE" />
                      <path d="M20.5203 5.10547L9.80005 13.7469L12.8785 16.8254L12.4419 16.9631L24.0677 28.5889L25.8732 28.5922C29.4634 25.7732 31.8178 21.4517 31.9898 16.575L20.5203 5.10547Z" fill="#E2348B" />
                      <path d="M23.0859 30.3503C24.0906 29.853 25.0402 29.2516 25.9193 28.556V24.4197C25.9193 22.1477 24.3082 20.1947 22.0776 19.7628L15.9999 18.5859L9.92219 19.7628C7.69163 20.1947 6.08057 22.1477 6.08057 24.4197V28.556C6.95958 29.2516 7.90923 29.853 8.91388 30.3503H23.0859Z" fill="white" />
                      <path d="M22.0778 19.7627L16 18.5859L15.9778 18.5903V30.3503H23.086C24.0907 29.853 25.0403 29.2516 25.9193 28.556V24.4197C25.9193 22.1476 24.3083 20.1947 22.0778 19.7627Z" fill="#E9EDF5" />
                      <path d="M15.9999 21.4009C14.9204 21.4009 14.0452 20.5257 14.0452 19.4462V16.8398H17.9547V19.4462C17.9547 20.5258 17.0795 21.4009 15.9999 21.4009Z" fill="#FFBEAA" />
                      <path d="M15.9778 16.8398V21.4003C15.9852 21.4004 15.9926 21.4009 16 21.4009C17.0796 21.4009 17.9548 20.5257 17.9548 19.4462V16.8398H15.9778Z" fill="#FAA68E" />
                      <path d="M12.1928 12.9641C12.3806 12.1695 11.8887 11.373 11.0941 11.1852C10.2995 10.9974 9.50305 11.4893 9.31521 12.2839C9.12737 13.0785 9.61926 13.8749 10.4139 14.0628C11.2085 14.2506 12.0049 13.7587 12.1928 12.9641Z" fill="#FFBEAA" />
                      <path d="M22.2874 13.6626C22.8647 13.0852 22.8647 12.1491 22.2874 11.5718C21.71 10.9944 20.7739 10.9944 20.1965 11.5718C19.6192 12.1491 19.6192 13.0852 20.1965 13.6626C20.7739 14.24 21.71 14.24 22.2874 13.6626Z" fill="#FAA68E" />
                      <path d="M16 18.295C13.009 18.295 10.5842 15.8703 10.5842 12.8792V10.2282C10.5842 7.23719 13.009 4.8125 16 4.8125C18.991 4.8125 21.4157 7.23719 21.4157 10.2282V12.8793C21.4157 15.8703 18.991 18.295 16 18.295Z" fill="#FFCDBE" />
                      <path d="M16 4.8125C15.9926 4.8125 15.9852 4.81275 15.9778 4.81275V18.2947C15.9852 18.2947 15.9926 18.2949 16 18.2949C18.991 18.2949 21.4157 15.8703 21.4157 12.8793V10.2282C21.4158 7.23719 18.991 4.8125 16 4.8125Z" fill="#FFBEAA" />
                      <path d="M21.7811 10.2835H10.2188V8.33681C10.2188 5.70006 12.3563 3.5625 14.9931 3.5625H17.0068C19.6436 3.5625 21.7811 5.70006 21.7811 8.33681V10.2835Z" fill="#FFDC4A" />
                      <path d="M21.7812 8.33681C21.7812 5.70006 19.6437 3.5625 17.0069 3.5625H15.9778V10.2835H21.7812V8.33681Z" fill="#FDBA12" />
                      <path d="M21.3808 11.6035C17.943 10.4549 14.225 10.4549 10.7872 11.6035C10.127 11.8241 9.44604 11.3249 9.44604 10.6289V10.6069C9.44603 10.3994 9.50895 10.1967 9.6265 10.0258C9.74404 9.85478 9.91069 9.72347 10.1044 9.64917C13.9544 8.17423 18.2136 8.17423 22.0636 9.64917C22.2573 9.72347 22.424 9.85478 22.5415 10.0258C22.6591 10.1967 22.722 10.3994 22.722 10.6069V10.6289C22.722 11.3249 22.041 11.8241 21.3808 11.6035Z" fill="white" />
                      <path d="M22.0637 9.64833C20.1215 8.90445 18.0575 8.52969 15.9778 8.54333V10.7423C17.8139 10.7302 19.6394 11.0209 21.3809 11.6026C22.0411 11.8233 22.7221 11.3241 22.7221 10.628V10.606C22.7221 10.3985 22.6592 10.1959 22.5416 10.0249C22.4241 9.85394 22.2575 9.72263 22.0637 9.64833Z" fill="#E9EDF5" />
                      <path d="M11.8579 28.0161H9.53662V19.5012L11.8579 19.1211V28.0161Z" fill="#87C7FF" />
                      <path d="M20.1421 28.0161H22.4633V19.5012L20.1421 19.1211V28.0161Z" fill="#4A98F7" />
                      <path d="M8.91406 30.3494C11.0499 31.4061 13.4556 32.0001 16 32.0001C18.5444 32.0001 20.9501 31.4061 23.0859 30.3494L22.4633 22.8672H9.53669L8.91406 30.3494Z" fill="#80DBFF" />
                      <path d="M23.086 30.3494L22.4633 22.8672H15.9778V31.9998C15.9852 31.9998 15.9926 32.0001 16 32.0001C18.5444 32.0001 20.9501 31.4061 23.086 30.3494Z" fill="#1CBBF9" />
                      <path d="M16.0001 29.1163C18.4966 29.1163 20.5205 28.0497 20.5205 26.7339C20.5205 25.4182 18.4966 24.3516 16.0001 24.3516C13.5036 24.3516 11.4797 25.4182 11.4797 26.7339C11.4797 28.0497 13.5036 29.1163 16.0001 29.1163Z" fill="#1CBBF9" />
                      <path d="M16 24.3516C15.9926 24.3516 15.9852 24.3518 15.9778 24.3518V29.1159C15.9852 29.1159 15.9926 29.1162 16 29.1162C18.4965 29.1162 20.5204 28.0496 20.5204 26.7338C20.5204 25.4182 18.4965 24.3516 16 24.3516Z" fill="#4A7AFF" />
                      <path d="M18.1916 27.8372C19.2307 27.3066 19.6429 26.0342 19.1123 24.9952C18.5817 23.9561 17.3093 23.5439 16.2703 24.0745C15.2312 24.605 14.819 25.8775 15.3496 26.9165C15.8801 27.9556 17.1526 28.3678 18.1916 27.8372Z" fill="#FFDC4A" />
                      <path d="M18.7178 24.4703C17.971 23.7234 16.8042 23.6528 15.9778 24.258V27.6699C16.8042 28.2752 17.971 28.2045 18.7178 27.4577C19.5428 26.6328 19.5427 25.2952 18.7178 24.4703Z" fill="#FDBA12" />
                      <path d="M12.6636 30.5241L15.2971 25.0391L18.1486 27.8906L12.6636 30.5241Z" fill="#B46E5A" />
                      <path d="M15.9778 25.7188V28.9318L18.1486 27.8896L15.9778 25.7188Z" fill="#915041" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1814_3914">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>{t("CUSTOMER")}</span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/product-management"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClick}
              >
                <div className="left-menu">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_1814_3952)">
                      <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#FA70AE" />
                      <path d="M31.9999 16.002C32.0005 14.9245 31.8927 13.8497 31.6782 12.7938L24.8531 5.96875L7.14673 26.0353L12.7917 31.6802C13.8476 31.8948 14.9224 32.0026 15.9999 32.002C24.8365 32.002 31.9999 24.8386 31.9999 16.002Z" fill="#E2348B" />
                      <path d="M7.14673 5.96875H24.8531V26.0353H7.14673V5.96875Z" fill="white" />
                      <path d="M15.9778 5.96875H24.8532V26.0353H15.9778V5.96875Z" fill="#E9EDF5" />
                      <path d="M11.8467 20.9084C11.5014 20.9084 11.2214 20.6285 11.2214 20.2832V17.2034C11.2214 16.8581 11.5014 16.5781 11.8467 16.5781C12.192 16.5781 12.4719 16.8581 12.4719 17.2034V20.2832C12.4719 20.6285 12.192 20.9084 11.8467 20.9084Z" fill="#FFDC4A" />
                      <path d="M14.3638 18.2257H9.32959L9.74278 12.2907C9.78984 11.6143 10.3522 11.0898 11.0301 11.0898H12.6632C13.3412 11.0898 13.9035 11.6144 13.9505 12.2907L14.3638 18.2257Z" fill="#C38778" />
                      <path d="M17.8057 8.71484H22.998V10.0587H17.8057V8.71484ZM17.8057 11.3603H22.998V12.7042H17.8057V11.3603ZM17.8057 14.0058H22.998V15.3497H17.8057V14.0058ZM17.8057 16.6513H22.998V17.9952H17.8057V16.6513ZM17.8057 19.2967H22.998V20.6406H17.8057V19.2967ZM17.8057 21.9422H22.998V23.2861H17.8057V21.9422Z" fill="#CDD2E1" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1814_3952">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>{t("PRODUCTS")}</span>
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/notification"
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                onClick={handleClick}
              >
                <div className="left-menu">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_1814_3972)">
                      <path d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z" fill="#FA70AE" />
                      <path d="M15.1769 6.74475L14.0237 8.292L11.109 21.1837L8.31226 23.9805L16.3258 31.9941C23.5356 31.8499 29.5748 26.9374 31.4196 20.2804L17.8109 6.67188L15.1769 6.74475Z" fill="#E2348B" />
                      <path d="M16.6196 10.2882L16.6404 10.2628L16.6001 10.2314L16.0001 8.48294C15.5635 8.48294 15.1374 8.52794 14.7262 8.61331C14.7218 8.56999 14.7196 8.52648 14.7196 8.48294C14.7196 7.77569 15.2929 7.20237 16.0002 7.20237L16.6404 6.63263L16.0001 5.92188C14.5857 5.92188 13.4391 7.0685 13.4391 8.48294C13.4391 8.66275 13.4578 8.83819 13.4931 9.00756C11.2956 9.973 9.76074 12.1682 9.76074 14.7223V20.9617L12.4712 22.5761L16.0001 21.9836L19.0905 17.4883L16.6196 10.2882Z" fill="#FFCC00" />
                      <path d="M22.2394 20.9617V14.7223C22.2394 12.1682 20.7046 9.973 18.5071 9.00756C18.543 8.835 18.5611 8.6592 18.5611 8.48294C18.5611 7.0685 17.4144 5.92188 16 5.92188V7.20244C16.7072 7.20244 17.2806 7.77575 17.2806 8.483C17.2806 8.527 17.2783 8.5705 17.274 8.61337C16.8549 8.52653 16.428 8.48285 16.0001 8.483V21.9837L18.8613 22.6981L22.2394 20.9617Z" fill="#FF9E22" />
                      <path d="M16 21.9844L17.0228 24.03L16 26.0756C14.8702 26.0756 13.9543 25.1597 13.9543 24.03V21.9844H16Z" fill="#FFCC00" />
                      <path d="M16 21.9844V26.0756C17.1298 26.0756 18.0456 25.1597 18.0456 24.03V21.9844H16Z" fill="#FF9E22" />
                      <path d="M9.76063 20.9648L8.31226 22.4132V23.9838H16L17.4371 20.9648H9.76063Z" fill="#FFDD55" />
                      <path d="M16 20.9648V23.9838H23.6877V22.4132L22.2394 20.9648H16Z" fill="#FFCC00" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1814_3972">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>{t("NOTIFICATION")}</span>
                </div>
              </NavLink>
            </li>
          </>
        </ul>
      </div>
    </>
  );
};

SidebarContent.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(SidebarContent);