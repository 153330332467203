import React from "react";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";
//i18n
import { useTranslation } from "react-i18next";

// images
import lock from "../../../assets/images/lock_reset.svg";

import HookForm from '../../../components/hookForm/hookForm';
import TextField from "../../../components/textField/textField";
import CustomButton from '../../../components/customButton/customButton';
import Logo from '../../../components/logo/logo';
import CardTopHeader from '../../../components/cardTopHeader/cardTopHeader';
import { resetData } from "./resetPasswordInterface";

/**
 * Represents the props for the PasswordResetComponent.
 * @interface PasswordResetComponentProps
 * @property {() => void} onClickCancel - Callback function to handle cancel button click.
 * @property {() => void} t_show2 - Callback function to show additional information.
 * @property {() => void} t_show - Callback function to show information.
 * @property {boolean} showconfirm - Flag indicating whether to show the confirmation.
 * @property {boolean} show - Flag indicating whether to show the component.
 */
interface PasswordResetComponentProps {
    onClickCancel: () => void;
    resetForm: any;
    t_show2: () => void;
    t_show: () => void;
    multiErrorFields: any;
    showconfirm: boolean;
    show: boolean;
    onFormSubmit: (value: resetData) => void;
    busy: boolean;
}

/**
 * A React functional component that renders a password reset form.
 * @param {PasswordResetComponentProps} props - The component props.
 * @returns The rendered password reset form.
 */
const PasswordResetComponent: React.FC<PasswordResetComponentProps> = (props) => {
    const { t } = useTranslation();
    const { onClickCancel, resetForm, t_show2, t_show, multiErrorFields, showconfirm, show, onFormSubmit, busy } = props;
    // document.title = "Reset Password";

    return (
        <React.Fragment>
            <div className="auth-pages resetPasswordPage">
                <Container>
                    <Row className="justify-content-center">
                        <Logo />
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden forgotStyle">
                                <CardTopHeader
                                    labelTitle={t('RESET_PASSWORD')}
                                    textShow={false}
                                    rightImage={lock}
                                    labelText={''}
                                />
                                <CardBody className="Card_Body">
                                    <div className="p-2">
                                        <HookForm
                                            defaultForm={{}}
                                            onSubmit={(e) => onFormSubmit(e)}>
                                            {(formMethod) => {
                                                return (
                                                    <div className={formMethod?.formState.errors ? 'invalid' : 'sdfgsd'}>
                                                        <div className="mb-4">
                                                            <div className='d-flex'>
                                                                <Label>{t('NEW_PASSWORD')}</Label>
                                                                <Label className="requiredStyle">*</Label>
                                                            </div>
                                                            <div className="input-group auth-pass-inputgroup">
                                                                <TextField
                                                                    formMethod={formMethod}
                                                                    rules={resetForm.newpassword.validate}
                                                                    multiErrorFields={multiErrorFields}
                                                                    errors={formMethod?.formState.errors}
                                                                    name={resetForm.newpassword.name}
                                                                    type={!show ? 'text' : 'password'}
                                                                    placeholder={t('ENTER_NEW_PASSWORD')}
                                                                    defaultValue=""
                                                                    value={undefined}
                                                                    onChange={(value) => {
                                                                        formMethod?.watch('password') &&
                                                                            formMethod.trigger('password');
                                                                    }}
                                                                />
                                                                <button tabIndex={-1} onClick={t_show} className="btn btn-light " type="button" id="password-addon">
                                                                    <i className={!show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i> </button>
                                                            </div>
                                                        </div>
                                                        <div className="mb-4">
                                                            <div className='d-flex'>
                                                                <Label>{t('CONFIRM_PASSWORD')}</Label>
                                                                <Label className="requiredStyle">*</Label>
                                                            </div>
                                                            <div className="input-group auth-pass-inputgroup">
                                                                <TextField
                                                                    formMethod={formMethod}
                                                                    rules={{
                                                                        required: {
                                                                            value: true,
                                                                        },
                                                                        validate: {
                                                                            matchPassword: (value: string) => (value &&
                                                                                value === formMethod?.watch('newpassword')) || t('BOTH_PASSWORD_MUST_BE_MATCH')
                                                                        }
                                                                    }}
                                                                    name={resetForm.password.name}
                                                                    errors={formMethod?.formState.errors}
                                                                    type={!showconfirm ? 'text' : 'password'}
                                                                    placeholder={t('CONFIRM_PASSWORD')}
                                                                    onChange={(e) => {
                                                                        formMethod?.watch('newpassword') &&
                                                                            formMethod.trigger('newpassword');
                                                                    }}
                                                                    value={undefined}
                                                                />
                                                                <button tabIndex={-1} onClick={t_show2} className="btn btn-light " type="button" id="password-addon">
                                                                    <i className={!showconfirm ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i> </button>
                                                            </div>
                                                        </div>
                                                        <div className="d-grid">
                                                            <CustomButton
                                                                className='custom-btn padding8-16 font14 bgDarkGreen txtColor'
                                                                type="submit" title='Update'
                                                                disabled={!formMethod?.formState.isValid}
                                                                loading={busy} />
                                                            <CustomButton
                                                                className='cancel-btn-style'
                                                                onClick={onClickCancel}
                                                                type="button"
                                                                title='Cancel' />
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </HookForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="copy-right-text-style text-center">
                                <p>{t('COPYRIGHTS_BY_SIGN_IN')}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default PasswordResetComponent;
