import React from "react";
import { useTranslation } from "react-i18next";
// import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2'
import { Controller } from "react-hook-form";
// import 'react-phone-number-input/style.css'
import 'react-phone-input-2/lib/style.css'

import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import CustomModal from '../../../components/customModal/customModel';
import CustomButton from "../../../components/customButton/customButton";

import Constant from "../../../util/constant";
import { DriverData } from "../../../interface/driverInterface";

interface AddEditDriverProps {
  showAddEditDriverModal: boolean;
  closeAddEditModal: () => void;
  placeholder: string;
  driverAddEditFunction: (form: any) => void;
  handleSubmitDriver: (e: string) => void,
  numberValue: string,
  setNumberValue: string,
  phoneNumbers: string,
  phoneTouched: boolean,
  setPhoneTouched: any,
  setPhoneNumbers: any,
  setphoneNumberError: any,
  showTypeModal: any,
  driverValue: DriverData,
  isValidPhoneNumber: any,
  addAndEditLoader: boolean
}

/**
 * A functional component that renders a modal for adding or editing a driver.
 * @param {AddEditDriverProps} props - The props for the component.
 * @returns The rendered component.
 */
const AddEditDrivers: React.FC<AddEditDriverProps> = (props) => {
  const { t } = useTranslation();
  const { showAddEditDriverModal, closeAddEditModal, driverAddEditFunction, handleSubmitDriver, phoneNumbers, phoneTouched,
    setPhoneTouched, setPhoneNumbers, setphoneNumberError, showTypeModal, driverValue, addAndEditLoader } = props;

  const addEditForm = {
    driverName: {
      name: "driverName",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: t("PLEASE_ENTER_VALID_NAME"),
        },
        maxLength: {
          value: 50,
          message: t("ALLOWED_MAX_50_CHARACTERS"),
        },
      },
    },
    phone: {
      name: "phone",
      validate: {
        required: {
          value: true,
        },
      },
    },
  };
  const checkPhoneInput = (value: any) => {
    if (/^\+?[0-9]{12,15}$/.test(value)) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <CustomModal
      className={"addEditDriverModal"}
      modalTitle={showTypeModal?.modal === 'add' ? t("ADD_DRIVER") : t("EDIT_DRIVER")}
      showModal={showAddEditDriverModal}
      closeModelIcon={() => closeAddEditModal()}
      onHide={() => closeAddEditModal()}>
      <HookForm
        defaultForm={{
          driverName: showTypeModal?.modal === 'add' ? "" : driverValue?.fullname,
          phone: showTypeModal?.modal === 'add' ? "" : driverValue?.phonenumber
        }}
        init={(form) => {
          driverAddEditFunction(form);
        }}
        onSubmit={(e) => {
          handleSubmitDriver(e);
        }}
      >
        {(formMethod) => {
          return (
            <>
              <div className="addEditWrapper">
                <div className="d-flex">
                  <p className="form-label">{t('DRIVER_NAME')}</p>
                  <p className="requiredStyle">*</p>
                </div>
                <div className="TextFiledWrapper wrapper-input">
                  <TextField
                    formMethod={formMethod}
                    rules={addEditForm.driverName.validate}
                    name={addEditForm.driverName.name}
                    errors={formMethod?.formState.errors}
                    autoFocus={true}
                    required={false}
                    type="text"
                    placeholder={t('ENTER_DRIVER’S_NAME')}
                    value={undefined}
                    multiErrorFields={undefined}
                  />
                </div>
              </div>
              <div className="addEditWrapper">
                <div className="d-flex">
                  <p className="form-label">{t('DRIVER_PHONE_NUMBER')}</p>
                  <p className="requiredStyle">*</p>
                </div>
                <div className="TextFiledWrapper wrapper-input">
                  <Controller
                    name={addEditForm.phone.name}
                    rules={{
                      required: {
                        value: true,
                        message: t('PHONE_NUMBER_IS_REQUIRED'),
                      },
                    }}
                    control={formMethod.control}
                    render={({ field: { onChange, value } }) => (
                      <>
                        <PhoneInput
                          placeholder={t('ENTER_PHONE_NUMBER')}
                          country={'gb'}
                          countryCodeEditable={false}
                          disableDropdown={true}
                          value={showTypeModal?.modal === 'add' ? phoneNumbers : driverValue?.phonenumber}
                          isValid={(value: any, country: any) => {
                            if (!phoneTouched) {
                              return true;
                            } else if (/^([0-9]{12,15})$/.test(value)) {
                              return true;
                            } else {
                              return false;
                            }
                          }}
                          onChange={(e: any) => {
                            const formattedValue = e?.toString().startsWith('+') ? e.toString() : '+' + e.toString();
                            onChange(formattedValue);
                            setPhoneTouched(true);
                            setPhoneNumbers(formattedValue);
                            if (!phoneTouched) {
                              return true;
                            } else if (/^(\+[0-9]{12,15})$/.test(formattedValue)) {
                              setphoneNumberError("");
                            } else {
                              if (formattedValue === undefined) {
                                setphoneNumberError(t("PLEASE_ENTER_PHONE_NUMBER"));
                                return;
                              } else {
                                setphoneNumberError(t("PLEASE_ENTER_VALID_PHONE_NUMBER"));
                                return;
                              }
                            }
                          }}
                        />

                        {phoneNumbers && (
                          <div className=" errorMsg show">
                            <span>{phoneNumbers ? (checkPhoneInput(phoneNumbers?.toString()) ? undefined : t('PLEASE_ENTER_VALID_PHONE_NUMBER')) : 'Phone number required'}</span>
                          </div>
                        )}
                      </>

                    )}
                  />


                </div>
              </div>
              <div className="Form_buton">

                <div className="mainDiv">
                  <CustomButton
                    className="custom-btn padding8-16 font14 bgPink txtColor"
                    type="submit"
                    title={showTypeModal?.modal === 'add' ? t("ADD_DRIVER") : t("UPDATE_DRIVER")}
                    disabled={
                      showTypeModal?.modal === 'add'
                        ? !formMethod?.formState.isValid || (!phoneNumbers || !checkPhoneInput(phoneNumbers))
                        : !(formMethod?.formState.isValid && phoneNumbers && checkPhoneInput(phoneNumbers && phoneNumbers) && formMethod?.formState.isDirty)
                    }
                    loading={
                      addAndEditLoader
                    }
                  />
                  <CustomButton
                    className="cancel-btn"
                    type="button"
                    onClick={() => closeAddEditModal()} title={t("CANCEL")} />
                </div>
              </div>
            </>
          );
        }}
      </HookForm>
    </CustomModal>
  );
};

export default AddEditDrivers;
