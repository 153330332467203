import React, { useEffect, useRef } from "react";

interface EmptyCellProps {
  clearSearchButton?: () => void;
  emptyDataTitle?: string;
  noDataTitle: string;
  searchValueData?: string;
  noDataDesc?: string;
  noDataFoundText?: string;
  emptyItems?: any[];
  noDataAddButton?: boolean;
  imageShow?: boolean;
  noDataImage?: string;
  noDataAddButtonTitle?: string;
  searchClearTitle?: string;
  emptyDataAddButton?: () => void;
  searchClearButton?: () => void;

}

const EmptyCell: React.FC<EmptyCellProps> = (props) => {

  const {
    emptyDataTitle,
    noDataTitle,
    noDataDesc,
    searchValueData,
    noDataFoundText,
    emptyItems,
    noDataAddButton,
    noDataAddButtonTitle,
    searchClearTitle,
    emptyDataAddButton,
    searchClearButton,
    noDataImage
  } = props;

  const emptyItemsLength = emptyItems?.length;

  const noDataRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const noDataElement = noDataRef.current;
    const nearestTr = noDataElement?.closest("tr");
    if (nearestTr) {
      nearestTr.classList.add("emptyRow");
    }
  }, []);

  return (
    <div ref={noDataRef} className="no-data-found">
      <div className="no-data-Icon">
        {emptyItemsLength === 0 ?
          <div className='cricle-image'>
            <img src={noDataImage} alt='no data' />
          </div>
          :
          <span className="Icon">
            <i className="bx bx-search-alt-2 bx-xs" />
          </span>

        }
        {emptyItemsLength === 0 ?
          <span className="no-data-text">{emptyDataTitle}</span> :
          <span className="no-data-text">{noDataTitle}</span>
        }
        {emptyItemsLength === 0 ?
          <span className="no-desc">{noDataFoundText}</span>
          : <>
            <span className="no-desc search-value-style" dangerouslySetInnerHTML={{ __html: searchValueData! }}>
            </span>
            <span className="no-desc">{noDataDesc}</span>
          </>
        }
      </div>


      <div className={emptyItemsLength === 0 ? "no-data-btns empty-data-btns" : "no-data-btns no-found-btn"}>
        <button className="outline_button" onClick={searchClearButton}>
          {searchClearTitle}
        </button>
        {noDataAddButton && (
          <button className="fill_button" onClick={emptyDataAddButton}>
            {noDataAddButtonTitle}
          </button>
        )}
      </div>
    </div>
  );
};

export default EmptyCell;
