import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistPartial } from 'redux-persist/es/persistReducer';
import toastSlice from "./features/toast/toastSlice";
import authSlice from "./features/auth/authSlice";
import driversSlice from "./features/driverManagement/driversSlice";
import customerSlice from "./features/customerManagement/customerSlice";
import productSlice from "./features/productManagement/productSlice";
import orderManagementSlice from "./features/orderManagement/orderManagementSlice";
import notificationSlice from "./features/notificationManagement/notificationSlice";


// Define the root state with the persistent state
export interface RootState {
  toast: ReturnType<typeof toastSlice>;
  auth: PersistPartial & ReturnType<typeof authSlice>;
  drivers: PersistPartial & ReturnType<typeof driversSlice>;
  customer: PersistPartial & ReturnType<typeof customerSlice>
  product: PersistPartial & ReturnType<typeof productSlice>
  order: PersistPartial & ReturnType<typeof orderManagementSlice>
  notification: PersistPartial & ReturnType<typeof notificationSlice>
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, authSlice);
const persistedReducerDrivers = persistReducer(persistConfig, driversSlice);
const persistedReducerCustomer = persistReducer(persistConfig, customerSlice);
const persistedReducerProduct = persistReducer(persistConfig, productSlice);
const persistedReducerOrder = persistReducer(persistConfig, orderManagementSlice);
const persistedReducerNotification = persistReducer(persistConfig, notificationSlice);

const rootReducer = combineReducers<RootState>({
  toast: toastSlice,
  auth: persistedReducer,
  drivers: persistedReducerDrivers,
  customer: persistedReducerCustomer,
  product: persistedReducerProduct,
  order: persistedReducerOrder,
  notification: persistedReducerNotification
});

export const store = configureStore({
  reducer: rootReducer,
});

export const persistor = persistStore(store);
