import React from "react";
import PropTypes from "prop-types";
import SidebarContent from "./sidebarContent";

interface SidebarProps {
  type?: any;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  return (
    <>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent type={""} /> : <SidebarContent type={""} />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

export default Sidebar;
