import React from "react";
import { CSmartTable } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";

import AddEditProduct from './addEditProduct/addEditProduct';

import CheckBox from '../../components/checkBox/checkBox';
import EmptyCell from "../../components/emptyCell/emptyCell";
import CustomLoadingHTML from "../../components/customLoadingHTML/customLoadingHTML";
import ConfirmationModal from '../../components/confirmationModal/confirmationModal';
import CustomToggleButton from "../../components/customToggleButton/customToggleButton";
import { productData } from "../../interface/productInterface";

interface ProductManagementProps {
  productData: productData[]; // Update with actual data type
  columns: any[]; // Update with actual data type
  placeHolderShimer: boolean;
  onSlideSwitch: (e: any, item: productData) => void; // Update with actual data type
  onTableFilterChange: (item: any) => void;
  emptyDataTitle: string;
  NoDataTitle: string;
  NoDataDesc: string;
  searchClearTitle: string;
  noDataFoundText: string;
  searchValueData: string;
  showStatusModal: boolean;
  onProcced: () => void;
  closeDeactiveModal: () => void;
  noDataAddButtonTitle: string;
  dimensions: any;
  loading: boolean;
  tableContainerRef: any; // Update with actual data type
  itemsPerPage: number,
  defaultNumber: string,
  headerHeight: number,
  pageTitleHeight: number,
  buttonRowHeight: number,
  searchValue: any,
  noDataImage: string,
  searchFilterClear: any,
  itemName: string,
  handleTableBodyScroll: () => void,
  onCheckChange: (e: any, item: productData) => void;
  showProductDeleteModal: boolean,
  openProductDeleteModal: (e: any, item: productData) => void;
  onProductDelete: () => void;
  closeProductDeleteModal: () => void;
  showTypeModal: any,
  showAddEditProductModal: boolean,
  closeAddEditModal: () => void;
  productAddEditFunction: (form: any) => void;
  handleSubmitProduct: any;
  productValue: any,
  addProductModal: () => void;
  onUpdateProduct: (e: Event, item: any) => void;
  profileImage: string;
  // handleUploadThumnail: ( handleUploadThumnailFile: any) => void;
  isUploadingDocumentLink?: boolean;
  // onChangeUploadingDocumentLink: () => void;
  handleDescriptiontextcount: any;
  uploadedDocumentThumnailFile?: Blob[];
  handleUploadThumnailFile: (files: Blob[]) => void;
  uploadThumNailValid: { [key: string]: string };
  triggerRef?: any;
  dimenstionError: boolean
  setDimenstionError: React.Dispatch<React.SetStateAction<boolean>>;
  loaderButtonShow: boolean,
  addAndEditLoader: boolean,
  fullname: string,
  enableBtnLoader: boolean,
  selectedItem:any,
  descriptiontextcount:number  | undefined,
  setLoadingImg1:React.Dispatch<React.SetStateAction<boolean>>,
  loadingImg:boolean;
  setUploadingImg:React.Dispatch<React.SetStateAction<boolean>>,
  uploadingImg:boolean;

  // IMPLEMENT CROP IMAGE
  uploadInputRef: any,
  editFileUpload: any,
  fileUploadChange: any,
  aspectRatio: any,
  image: any,
  crop: any,
  setCrop: any,
  zoom: any,
  setZoom: any,
  croppedImage: any,
  onCropComplete: any,
  showCroppedImage: any,
  onPressCropCancel: any,
  editImage:boolean,
  totalProduct: any
}

/**
 * Constructs the Cloudinary URL using the environment variables.
 * @returns {string} The constructed Cloudinary URL.
 */
const CLOUDINARY_URL =
(process.env.REACT_APP_CLOUDNARY_URL ?? '') +
  (process.env.REACT_APP_CLOUDNARY_NAME ?? '') +
  (process.env.REACT_APP_CLOUDNARY_SUBFLODER ?? '');



  
/**
 * A functional component that represents the Product Management page.
 * @param {ProductManagementProps} props - The props for the component.
 * @returns The rendered Product Management page.
 */
const ProductManagementComponent: React.FC<ProductManagementProps> = (props) => {
  const { t } = useTranslation();
  
  const {
    headerHeight,
    pageTitleHeight,
    buttonRowHeight,
    productData,
    columns,
    onSlideSwitch,
    onTableFilterChange,
    NoDataTitle,
    emptyDataTitle,
    noDataFoundText,
    NoDataDesc,
    noDataAddButtonTitle,
    searchClearTitle,
    showStatusModal,
    onProcced,
    closeDeactiveModal,
    dimensions,
    placeHolderShimer,
    searchFilterClear,
    searchValue,
    noDataImage,
    itemName,
    loading,
    itemsPerPage,
    tableContainerRef,
    handleTableBodyScroll,
    searchValueData,
    onCheckChange,
    showProductDeleteModal,
    openProductDeleteModal,
    onProductDelete,
    closeProductDeleteModal,
    showTypeModal,
    showAddEditProductModal,
    closeAddEditModal,
    productAddEditFunction,
    handleSubmitProduct,
    productValue,
    addProductModal,
    onUpdateProduct,
    triggerRef,
    profileImage,
    // handleUploadThumnail,
    // isUploadingDocumentLink,
    // onChangeUploadingDocumentLink,
    handleUploadThumnailFile,
    uploadedDocumentThumnailFile,
    uploadThumNailValid,
    setDimenstionError,
    dimenstionError,
    loaderButtonShow,
    addAndEditLoader,
    fullname,
    enableBtnLoader,
    selectedItem,
    handleDescriptiontextcount,
    descriptiontextcount,
    setLoadingImg1,
    loadingImg,
    setUploadingImg,
    uploadingImg,

    // IMPLEMENT CROP IMAGE
    uploadInputRef,
    editFileUpload,
    fileUploadChange,
    aspectRatio,
    image,
    crop,
    setCrop,
    zoom,
    setZoom,
    croppedImage,
    onCropComplete,
    showCroppedImage,
    onPressCropCancel,
    editImage,
    totalProduct
  } = props;
  
   
  return (
    <React.Fragment>

      <div className="page-content product-management">
        <div className="heading">{t('PRODUCT_MANAGEMENT')}</div>
        <Card className="mainCard table-responsive">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <div className="addBtn">
                {searchValue?.length > 0 &&
                  <button
                    onClick={searchFilterClear}
                    className="clear-btn-style"
                  >
                    <i className='mdi mdi-window-close'></i>
                  </button>
                }
                <div className='products-number-style'>{totalProduct || ''} {t('PRODUCTS')}</div>
                <button
                  color="primary"
                  className="pink-btn"
                  onClick={() => addProductModal()}
                >
                  {t("ADD_PRODUCT")}
                </button>
              </div>
              <CSmartTable
                cleaner
                clickableRows
                columns={columns}
                columnFilter={false}
                items={productData}
                itemsPerPage={itemsPerPage}
                // onRowClick={(item) => { navigate("/product-management/details", { state: item }); }}
                scopedColumns={{
                  productname: (item: productData) => {
                    const className = item?.status === t("DEATCTIVE") ? "disableTd" : "";
                    return (
                      <td className={className} >
                        <div className="td-wrapper product-style">
                          
                          <div className='product-image-style'>
                          {placeHolderShimer && <div className="Shimer"></div>}
                            <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${process.env.REACT_APP_AWS_IMAGE_PATH}/` + item?.image} alt='' />
                          </div>
                          <div className="productName">
                            {item?.productname || '-'}
                          </div>
                        </div>
                      </td>
                    );
                  },
                  description: (item: productData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="td-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.description || '-'}
                        </div>
                      </td>
                    );
                  },
                  price: (item: productData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="td-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <b>
                            {'£'}{item?.price || '-'}
                          </b>
                        </div>
                      </td>
                    );
                  },
                  isfeatured: (item: productData) => {
                    return (
                      <td
                        className={item.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className='td-wrapper'>
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div>
                            <CheckBox
                              checkboxClass= {item?.isfeatured === true || selectedItem?.length > 3 ? 'form-check-input' : 'form-check-input uncheck'}
                              id='customControlInline'
                              checked={item?.isfeatured}
                              onCheckedChange={(e) => {     
                                if (item?.status === t("ACTIVE")) {                        
                                  onCheckChange(e, item); 
                                }                                                   
                              }} 
                              labelTitle={''} ></CheckBox>
                          </div>
                        </div>

                      </td>
                    );
                  },
                  actions: (item: any) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="action-wrapper">
                          {placeHolderShimer && (
                            <div className="Shimer"></div>
                          )}

                          <CustomToggleButton
                            id=''
                            checked={item?.status === t("ACTIVE") ? true : false}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onCheckChange={(e) => {
                              onSlideSwitch(e, item);
                            }}
                          />
                          <button
                            className="Edit-Buttton"
                            onClick={(e) => {
                              onUpdateProduct(item, e);
                              e.stopPropagation();
                            }}
                          >
                            <i
                              className="mdi mdi-pencil-outline font-size-24"
                              id="deletetooltip"
                            ></i>
                          </button>
                          <button
                            className="Delete-Buttton"
                            onClick={(e) => {
                              openProductDeleteModal(e, item)
                              e.stopPropagation();
                            }}
                          >
                            <i
                              className="mdi mdi-delete-outline font-size-24"
                              id="deletetooltip"
                            ></i>
                          </button>
                        </div>
                      </td>
                    );
                  },
                }}
                tableFilter={false}
                sorterValue={{ column: 'productname', state: 'asc' }}
                onTableFilterChange={onTableFilterChange}
                tableProps={{
                  className: placeHolderShimer ? "product-table shimer-row-style" : "product-table",
                  responsive: true,
                  striped: false,
                  hover: false,
                }}
                noItemsLabel={
                  !placeHolderShimer &&
                  <EmptyCell
                    emptyItems={productData}
                    noDataTitle={NoDataTitle}
                    noDataAddButton={true}
                    noDataDesc={NoDataDesc}
                    emptyDataTitle={emptyDataTitle}
                    noDataFoundText={noDataFoundText}
                    noDataImage={noDataImage}
                    noDataAddButtonTitle={noDataAddButtonTitle}
                    searchClearTitle={searchClearTitle}
                    searchClearButton={searchFilterClear}
                    searchValueData={searchValueData}
                    emptyDataAddButton={() => addProductModal()}
                  />
                }
                tableBodyProps={{
                  className: "align-middle table-body-scroll",
                  style: {
                    maxHeight: dimensions.height - headerHeight - pageTitleHeight - buttonRowHeight - 110, overflow: 'auto'
                  },
                  onScroll: handleTableBodyScroll,
                }}
                ref={tableContainerRef}
              />
              {loading && <CustomLoadingHTML />}
            </div>
          </Card.Body>
        </Card>

        {/* Disable Model Message Model */}
        <ConfirmationModal
          className={"deactivate"}
          isModelIcon={true}
          onHide={closeDeactiveModal}
          iconColor={"mdi mdi-toggle-switch-off-outline "}
          iconCricle={""}
          modalTitle={t("DEACTIVATE_PRODUCT")}
          modalDescription={t("ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_THIS_PRODUCT")}
          isUnderline={false}
          showModal={showStatusModal}
          leftBtnTitle={t("CONFIRM")}
          isFirstButton={true}
          leftBtnClass={"confirm-btn"}
          onProcced={onProcced}
          rightBtnClass="cancel-btn"
          isSecondButton={true}
          rightBtnTitle={t("CANCEL")}
          rightButtonClick={closeDeactiveModal}
          messagebold={`"${itemName}"`}
          isBold={true}
          leftdisabled={false}
          rightdisabled={false}
          modalContent={true}
          loaderButtonShow={enableBtnLoader} />

        {/* Delete Message Model */}
        <ConfirmationModal
          className={"delete-modal"}
          iconCricle={"icon-cricle-delete"}
          modalTitle={t('DELETE_PRODUCT')}
          modalDescription={t('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PRODUCT')}
          isUnderline={false}
          showModal={showProductDeleteModal}
          leftBtnTitle={t("YES_DELETE")}
          isModelIcon={true}
          iconColor={"mdi mdi-delete-outline"}
          leftBtnClass="delete-btn"
          isFirstButton={true}
          isSecondButton={true}
          rightBtnClass="cancel-btn"
          rightBtnTitle="Cancel"
          onProcced={onProductDelete}
          rightButtonClick={closeProductDeleteModal}
          isBold={true}
          messagebold={`"${fullname}"`}
          leftdisabled={false}
          rightdisabled={false}
          onHide={closeProductDeleteModal}
          modalContent={true}
          loaderButtonShow={loaderButtonShow}
        />

        <AddEditProduct
          placeholder={t('GENRE_PLACEHOLDER')}
          showTypeModal={showTypeModal}
          showAddEditProductModal={showAddEditProductModal}
          closeAddEditModal={closeAddEditModal}
          productAddEditFunction={productAddEditFunction}
          handleSubmitProduct={handleSubmitProduct}
          productValue={productValue}

          triggerRef={triggerRef}
          profileImage={profileImage}
          // handleUploadThumnail={handleUploadThumnail}
          // isUploadingDocumentLink={isUploadingDocumentLink}
          // onChangeUploadingDocumentLink={onChangeUploadingDocumentLink}
          handleUploadThumnailFile={handleUploadThumnailFile}
          handleClearUploadedThumnailFile={function (): void {
            throw new Error('Function not implemented.');
          }}
          uploadThumNailValid={uploadThumNailValid}
          uploadedDocumentThumnailFile={uploadedDocumentThumnailFile}
          setDimenstionError={setDimenstionError}
          dimenstionError={dimenstionError}
          addAndEditLoader={addAndEditLoader}
          handleDescriptiontextcount={handleDescriptiontextcount}
          descriptiontextcount={descriptiontextcount}
          setLoadingImg1={setLoadingImg1}
          loadingImg={loadingImg}
          setUploadingImg={setUploadingImg}
          uploadingImg={uploadingImg}

          // IMPLEMENT CROP IMAGE
          uploadInputRef={uploadInputRef}
          editFileUpload={editFileUpload}
          fileUploadChange={fileUploadChange}
          aspectRatio={aspectRatio}
          image={image}
          crop={crop}
          setCrop={setCrop}
          zoom={zoom}
          setZoom={setZoom}
          croppedImage={croppedImage}
          onCropComplete={onCropComplete}
          showCroppedImage={showCroppedImage}
          onPressCropCancel={onPressCropCancel}
          editImage={editImage}
        />
      </div>
    </React.Fragment>
  );
};

export default ProductManagementComponent;

