import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ProfileMenu from "../topbarDropdown/profileMenu";
import ConfirmationModal from '../confirmationModal/confirmationModal';
import useWindowDimension from "../hooks/useWindowDimension";
import io, { Socket } from "socket.io-client";
// import popUp from "/popUp.mp3"
import { updateNotificationData } from "../../store/features/notificationManagement/notificationSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../interface/driverInterface";

interface HeaderProps {
  t: any;
  toggleMenuCallback: () => void;
  showRightSidebar: any;
  toggleRightSidebar: () => void;
}

function Header(props: HeaderProps) {
  const [logoutModal, setLogoutModal] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  // const audio = new Audio(popUp);

  useEffect(() => {
    setSocket(io("https://scooply-api-staging-527f48c190be.herokuapp.com/"));
    // setSocket(io("http://192.168.1.213:4040/"));

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("customerOnboard", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("deleteCustomer", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("driverNoShowOnWeb", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("driverOnboard", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("orderDeliveredOnWeb", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("outOfStockOnWeb", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("startTripNotifytoWeb", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("endTriptoWeb", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
    if (socket) {
      socket.on("customerNoShowOnWeb", (data) => {
        console.log("user==", data);
        dispatch(updateNotificationData(data));
        // audio.play();
      });
    }
  }, [socket]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const toggleMenu = () => {
    props.toggleMenuCallback();
  };

  const handleLogout = () => {
    setLogoutModal(true);
    // localStorage.clear();
    // navigate("/");
  };

  const logoutFunction = () => {
    setLogoutModal(false);
    localStorage.clear();
    navigate("/");
  };

  const closeLogoutModel = () => {
    setLogoutModal(false);
  };
  const dimensions = useWindowDimension();
  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            {dimensions.width < 1025 && <button
              type="button"
              onClick={toggleMenu}
              className="btn btn-sm font-size-16 header-item burger-menu"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>}
          </div>
          <div className="d-flex ">
            <ProfileMenu handleLogout={handleLogout} />
          </div>
        </div>
      </header>

      <ConfirmationModal
        className={"delete-modal logout"}
        iconCricle={"icon-cricle-delete"}
        modalTitle={t("LOGOUT")}
        modalDescription={t("ARE_YOU_SURE_YOU_WANT_TO_LOGOUT_FROM_THE_APP")}
        isUnderline={false}
        showModal={logoutModal}
        leftBtnTitle={t("CONFIRM")}
        isModelIcon={true}
        iconColor={"mdi mdi-exit-to-app "}
        leftBtnClass={"delete-btn"}
        isFirstButton={true}
        isSecondButton={true}
        rightBtnClass="cancel-btn"
        rightBtnTitle={t("CANCEL")}
        onProcced={logoutFunction}
        rightButtonClick={closeLogoutModel}
        isBold={false}
        leftdisabled={false}
        rightdisabled={false}
        onHide={closeLogoutModel}
        loaderButtonShow={false}
        modalContent={true} messagebold={''} />
    </>
  );
}

export default Header;
