import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { customerData, enableCustomerErrorMessage, enableCustomerParams, enableCustomerRes } from "../../../interface/customerInterface";
import notificationService from "../services/notificationService";
import moment from "moment";

export const getNotifications = createAsyncThunk("admin/notificationList", async (token: string | null) => {
    const res = await notificationService.getNotifications(token)
    return res?.data;
});

export const readNotifications = createAsyncThunk("admin/readNotifications", async (notificationID: string) => {
    const res = await notificationService.readNotifications(notificationID)
    return res?.data;
});

export const readAllNotification = createAsyncThunk("admin/readAllNotification", async (token: string | null) => {
    const res = await notificationService.readAllNotification(token)
    return res?.data;
});

interface AuthState {
    notificationData: any
    isLoading: boolean
}

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notificationData: [],
        isLoading: false

    } as AuthState,
    reducers: {
        updateNotificationData: (state, action) => {
            console.log(`action ----->>>>`, action);
            let newData = action.payload;
            let dateKey = moment(newData?.datetime).format('YYYY-MM-DD');
            console.log(`dateKey ----->>>>`, dateKey);
            const existingDate = state.notificationData.find((entry: any) => entry._id === dateKey);
            console.log(`existingDate ----->>>>`, existingDate);
            if (existingDate) {
                state.notificationData[0].notifications = [newData, ...state.notificationData[0].notifications];
            } else {
                state.notificationData = [{
                    _id: dateKey,
                    notifications: [newData],
                  }, ...state.notificationData]
            }
            // state.notificationData = [newData, ...state.notificationData];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.isLoading = false;
                state.notificationData = action.payload;
            })
            .addCase(getNotifications.rejected, (state) => {
                state.isLoading = false;
            });
        builder
            .addCase(readAllNotification.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(readAllNotification.fulfilled, (state, action) => {
                state.isLoading = false;
                state.notificationData = action.payload;
            })
            .addCase(readAllNotification.rejected, (state) => {
                state.isLoading = false;
            });
    },
});
export const { updateNotificationData } = notificationSlice.actions
export default notificationSlice.reducer;