import React from 'react';
import logo from "../../assets/images/brand-logo.svg";
interface LogoProps {
}

const Logo: React.FC<LogoProps> = (props) => {
    return (
        <div className='logo-div'>
            <img src={logo} alt='logo' />
        </div>
    );
};

export default Logo;
