/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import ChangePasswordComponent from "./changePasswordComponent";

import Constant from "../../util/constant";
import { changePaswwordData } from "./changePasswordInterface";
import { AppDispatch, ChangePassParams } from "../../interface/authInterface";
import { showToast } from "../../store/features/toast/toastSlice";
import { Helmet } from 'react-helmet';
import { changePassword } from "../../store/features/auth/authSlice";
import { useNavigate } from "react-router-dom";

/**
 * Interface representing the structure of a reset form.
 * @interface ResetForm
 * @property {object} newpassword - The new password field.
 * @property {string} newpassword.name - The name of the new password field.
 * @property {object} newpassword.validate - The validation rules for the new password field.
 * @property {object} newpassword.validate.required - The required validation rule for the new password field.
 * @property {string} newpassword.validate.required.message - The error message for the required validation rule.
 * @property {object} newpassword.validate.validate - The additional validation rules for the new password
 */

const ChangePasswordContainer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.RESENDOTP, '')
    const [showPass, setShowOld] = useState<boolean>(true);
    const [show, setShow] = useState<boolean>(true);
    const [oldPassValue, setOldPassValue] = useState('');
    const [showconfirm, setShowConfirm] = useState<boolean>(true);
    const [busy, setBusy] = useState<boolean>(false);

    const multiErrorFields = [
        { length: t('MINIMUM_8_CHARACTERS') },
        { hasSpecialChar: t('MINIMUM_1_SPECIAL_CHARACTER') },
        { hasNumbers: t('MINIMUM_1_NUMERIC_CHARACTER') },
        { hasUppercase: t('MINIMUM_1_UPPERCASE_CHARACTER') },
        { hasLowerCase: t('MINIMUM_1_LOWERCASE_CHARACTER') },
        { samePassword: t("NEW_PASSWORD_CAN_NOT_BE_SAME_AS_OLD_PASSWORD")}
    ];

    const t_show1 = () => {
        setShowOld((prevShowOld) => !prevShowOld);
    };
    const t_show = () => {
        setShow((prevShow) => !prevShow);
    };

    const t_show2 = () => {
        setShowConfirm((prevShowConfirm) => !prevShowConfirm);
    };

    const resetForm = {
        oldpassword: {
            name: "oldpassword",
            validate: {
                required: {
                    value: true,
                },
                validate: {
                    test: (value: string) => setOldPassValue(value)
                   }
            },
        },
        newpassword: {
            name: "newpassword",
            validate: {
                required: {
                    value: true,
                },
                validate: {
                    hasUppercase: (value: string) =>
                        value && value.match(Constant.REGEX.UPPERCASE) !== null,
                    hasLowerCase: (value: string) =>
                        value && value.match(Constant.REGEX.LOWERCASE) !== null,
                    hasNumbers: (value: string) =>
                        value && value.match(Constant.REGEX.NUMBER) !== null,
                    hasSpecialChar: (value: string) =>
                        value && value.match(Constant.REGEX.SPECIALCHARACTERS) !== null,
                    length: (value: string) => (value && value.length >= 8) || "",
                    samePassword: (value:string) => (value &&
                        value !== oldPassValue),
                },
            },
        },
        password: {
            name: "password",
        },
    };
    /**
     * Handles the form submission for resetting the admin password.
     * @returns None
     */


    const onFormSubmit = async (value: ChangePassParams) => {
        console.log('value', value);
        setBusy(true)
        dispatch(changePassword(value)).then((resultAction) => {
            if (changePassword.fulfilled.match(resultAction)) {
                dispatch(
                  showToast({
                    show: false,
                    message: (resultAction as any)?.payload?.message,
                    type: "success",
                  })
                );
                navigate("/driver-management");
                setBusy(false);
              } else if (changePassword.rejected.match(resultAction)) {
                setBusy(false);
                dispatch(
                  showToast({
                    show: false,
                    message: resultAction?.error?.message,
                    type: "error",
                  })
                );
              }
        })
        
        // setBusy(true)
        // dispatch(
        //     showToast({
        //         show: false,
        //         message: 'Password updated successfully!',
        //         type: "success",
        //     })
        // )
    };

    return (<>
        <Helmet>
            <title>Change Password | Scooply Admin Panel</title>
            <meta name="description" content="Update and change your password on this page." />
        </Helmet>
        <ChangePasswordComponent
            onFormSubmit={onFormSubmit}
            resetForm={resetForm}
            t_show1={t_show1}
            t_show2={t_show2}
            t_show={t_show}
            multiErrorFields={multiErrorFields}
            busy={busy}
            showconfirm={showconfirm}
            show={show}
            showPass={showPass}
        />
    </>);
};

export default ChangePasswordContainer;
