/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import LoginComponent from "./loginComponent";
import Constant from "../../../util/constant";
import { showToast } from "../../../store/features/toast/toastSlice";
import { loginAdmin } from "../../../store/features/auth/authSlice";
import { AppDispatch, LoginParams } from "../../../interface/authInterface";
import { ILoginForm } from "./loginInterface";
import { Helmet } from 'react-helmet';
// actions

/**
 * A container component for the login functionality.
 * @returns {JSX.Element} - The rendered login component.
 */
const LoginContainer = () => {
    const { t } = useTranslation();
    const [busy, setBusy] = useState(false);
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const loginForm: ILoginForm = {
        email: {
            name: "email",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.EMAIL,
                    message: t('PLEASE_ENTER_A_VALID_EMAIL_ADDRESS'),
                },
            },
        },
        password: {
            name: "password",
            validate: {
                required: {
                    value: true,
                },
            },
        },
    };

      /**
     * useEffect hook that checks if the "Remember Me" option is enabled in the session storage
     * and updates the state accordingly.
     * @returns None
     */
      useEffect(() => {
        if (window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.REMEMBER_ME) === 'true') {
            setRememberMe(true)
        }
        if (localStorage.getItem('userRole') === 'Admin') {
          console.log('in');
          
          navigate("/driver-management", { replace: true });
      }
        // localStorage.clear()
    }, []);


    /**
     * Handles the form submission event and performs the necessary actions.
     * @returns None
     */

    const onFormSubmit = async (value: LoginParams) => {
        setBusy(true);
        dispatch(loginAdmin(value)).then((resultAction) => {            
            if (loginAdmin.fulfilled.match(resultAction)) {
              dispatch(
                showToast({
                  show: false,
                  message: (resultAction as any)?.payload?.message,
                  type: "success",
                })
              );
              window.localStorage.setItem(
                Constant.LOCALSTORAGEKEYS.ACCESSTOKEN,
                resultAction?.payload?.data?.accessToken
              );
              window.localStorage.setItem(
                Constant.LOCALSTORAGEKEYS.REFRESHTOKEN,
                resultAction?.payload?.data?.refreshToken
              );
              window.localStorage.setItem(
                'authUser',
                resultAction?.payload?.data?.username
              );
              window.localStorage.setItem(
                'userRole',
                'Admin'
              );
              window.sessionStorage.setItem(
                Constant.SESSIONSTORAGEKEYS.REMEMBER_ME,
                String(rememberMe)
              );
              setBusy(false);
              window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME, '');
              navigate("/driver-management", { replace: true });
              if (rememberMe === true) {
                window.sessionStorage.setItem(
                    Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_EMAIL,
                  value?.email
                );
                window.sessionStorage.setItem(
                    Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_PASSWORD,
                  value?.password
                );
              } else {
                window.sessionStorage.setItem(
                    Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_EMAIL,
                  ""
                );
                window.sessionStorage.setItem(
                    Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_PASSWORD,
                  ""
                );
              }
            } else if (loginAdmin.rejected.match(resultAction)) {                
              setBusy(false);
              dispatch(
                showToast({
                  show: false,
                  message: resultAction?.error?.message,
                  type: "error",
                })
              );
            }
          })

  };

  return (<>
    <Helmet>
        <title>Log In | Scooply Admin Panel</title>
        <meta name="description" content="Sign in to access the portal and see your orders, customers, and more on the Scooply platform." />
    </Helmet>

    <LoginComponent
      loginForm={loginForm}
      onFormSubmit={onFormSubmit}
      busy={busy}
      setRememberMe={setRememberMe}
      rememberMe={rememberMe}
    />
  </>
  );
};
export default LoginContainer;
