import React from "react";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import lock from "../../../assets/images/lock.svg";

import HookForm from '../../../components/hookForm/hookForm';
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";
import Logo from '../../../components/logo/logo';
import CardTopHeader from '../../../components/cardTopHeader/cardTopHeader';
import { forgotData, IForgotForm } from "./forgotInterface";

/**
 * Represents the props for the ForgotPasswordComponent.
 * @interface ForgotPasswordComponentProps
 * @property {boolean} busy - Indicates whether the component is currently busy.
 * @property {(e: React.FormEvent) => void} onFormSubmit - Event handler for form submission.
 * @property {object} forgotPassForm - The form object for the forgot password form.
 * @property {string} forgotPassForm.email.name - The name of the email field.
 * @property {function} forgotPassForm.email.validate - The validation function for the email field.
 */
interface ForgotPasswordComponentProps {
    busy: boolean;
    onFormSubmit: (e: forgotData) => void;
    forgotPassForm: IForgotForm;
}

/**
 * A functional component that renders a forgot password form.
 * @param {ForgotPasswordComponentProps} props - The component props.
 * @returns The rendered forgot password form.
 */
const ForgotPasswordComponent: React.FC<ForgotPasswordComponentProps> = (props) => {
    const { t } = useTranslation();
    const { busy, onFormSubmit, forgotPassForm } = props;

    return (
        <React.Fragment>
            <div className="auth-pages">
                <Container>
                    <Row className="justify-content-center ">
                        <Logo />
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden forgotStyle">
                                <CardTopHeader
                                    labelTitle={t('FORGOT_PASSWORD?')}
                                    textShow={false}
                                    rightImage={lock}
                                    labelText={''}
                                />
                                <CardBody className="Card_Body">
                                    <div className="p-3">
                                        <p className='forgotTxtStyle'>{t('PLEASE_ENTER_YOUR_EMAIL_ADDRESS_WELL_SEND_YOU_A_CODE_TO_CONFIRM_ITS_YOU')}</p>
                                        <HookForm
                                            defaultForm={{}}
                                            onSubmit={(e) => onFormSubmit(e)}>
                                            {(formMethod) => {
                                                return (
                                                    <>
                                                        <div className="mb-4">
                                                            <div className='d-flex'>
                                                                <Label>{t('EMAIL_ADDRESS')}</Label>
                                                                <Label className="requiredStyle">*</Label>
                                                            </div>
                                                            <TextField
                                                                formMethod={formMethod}
                                                                rules={forgotPassForm.email.validate}
                                                                name={forgotPassForm.email.name}
                                                                errors={formMethod?.formState.errors}
                                                                autoFocus={true}
                                                                type="text"
                                                                placeholder={t("ENTER_EMAIL")} value={undefined} multiErrorFields={undefined}
                                                            />
                                                        </div>

                                                        <div className="mt-4 d-grid">
                                                            <CustomButton className='custom-btn padding8-16 font14 bgDarkGreen txtColor' type="submit" title={t("SUBMIT")} disabled={!formMethod?.formState.isValid} loading={busy} />
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        </HookForm>
                                    </div>
                                    <div className="mt-1 mb-3 back-txt-style text-center">
                                        <p className='mb-0'>
                                            <i className="mdi mdi-arrow-left me-1" />
                                            <Link to="/login" className="text-primary">
                                                {t("BACK_TO_LOGIN")}
                                            </Link>
                                        </p>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="copy-right-text-style text-center">
                                <p>{t("COPYRIGHTS_BY_SIGN_IN")}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default ForgotPasswordComponent;
