/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { Route, Routes, useNavigate } from "react-router-dom";

import { authProtectedRoutes, publicRoutes } from "./routes";
import VerticalLayout from "./components/verticalLayout";
import ToastComponent from "./components/toastComponent/toastComponent";
import Constant from "../src/util/constant";
import "./app.css";
import 'bootstrap/dist/css/bootstrap.min.css';


function App(props: any) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.ACCESSTOKEN)) {
      navigate('/');
    }
    if (
      localStorage.getItem("authUser") !== null &&
      localStorage.getItem("authUser") === "true"
    ) {
      // You can use React Router's useHistory hook to redirect
      // Example: const history = useHistory();
      // history.push("/driver-management");
    }
  }, []);

  return (
    <React.Fragment>
      <ToastComponent />
      {/* <Loading show={state.LoaderReducer.loader.show} /> */}
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.element} />
        ))}
        {authProtectedRoutes.map((route, idx) => (
          <Route
            key={idx}
            element={<VerticalLayout />}
          >
            <Route path={route.path} element={route.element} />
          </Route>
        ))}
      </Routes>
    </React.Fragment>
  );
}

export default App;
