import React, { FC, ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";

interface TextFieldProps {
  errors: any;
  value?: any;
  placeholder: string;
  multiErrorFields?: any;
  disabled?: boolean;
  type: string;
  textarea?: any;
  name: string;
  autoFocus?: boolean;
  handleFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleBlur?: () => void;
  maxLength?: number;
  minLength?: number;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  rules: Record<string, any>;
  formMethod: any; // Replace 'any' with actual type
  rows?: number;
  defaultValue?: any;
  // rules?: RegisterOptions;
  readOnly?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
  textcount?: any;
  textCountTotal?: number;
  textCountVisible?: boolean;
  onClick?: () => void;
  ref?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    errors,
    placeholder,
    multiErrorFields,
    disabled,
    type,
    textarea,
    name,
    autoFocus,
    handleFocus,
    handleBlur,
    maxLength,
    minLength,
    onKeyDown,
    formMethod,
    rows,
    defaultValue,
    rules,
    readOnly,
    required,
    min,
    max,
    onClick,
    textCountVisible,
    textCountTotal,
    textcount
  } = props;
// console.log('editDescriptiontextcount', textcount);

  
  const hasError = errors[name] !== undefined;

  return (
    <>
      <Controller
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <>
            <Form.Control
              className={`${hasError ? "is-invalid" : ""} ${value &&
                rules.pattern &&
                rules.pattern.value &&
                rules.pattern.value.test(value)
                ? "regex-match-class"
                : ""
                }`}
              name={name}
              placeholder={placeholder}
              autoFocus={autoFocus}
              disabled={disabled}
              autoComplete="off"
              required={required}
              type={type}
              readOnly={readOnly}
              onBlur={handleBlur}
              onKeyDown={onKeyDown}
              maxLength={maxLength}
              minLength={minLength}
              min={min}
              max={max}
              onFocus={(e) => {
                handleFocus && handleFocus(e);
              }}
              defaultValue={defaultValue}
              onChange={(e) => {
                onChange && onChange(e.target.value.trimLeft());
                props.onChange && props.onChange(e);
              }}
              onClick={onClick}
              value={formMethod.watch(name) || value ? value : ""}
              as={textarea}
              rows={rows}
            />
          </>
        )}
        name={name}
        control={formMethod?.control}
        rules={rules}
      />
       {textCountVisible && (
        <span className="badgecount badge bg-success">
          {textcount} / {textCountTotal}
        </span>
      )}
      {multiErrorFields?.length > 0 ? (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ messages }) => {
            if (messages) {
              const isMultipleError = Object.keys(messages).every(
                (errKey) =>
                  multiErrorFields.filter((m: { [x: string]: undefined; }) => m[errKey] !== undefined)
                    .length > 0
              );
              if (isMultipleError) {
                const arr: JSX.Element[] = [];
                for (const fieldError of multiErrorFields) {
                  const key = Object.keys(fieldError)[0];
                  const success = Object.keys(messages).includes(key);
                  arr.push(
                    <div className={success ? "red" : "green"} key={key}>
                      <span key={key}>
                        <i className={success ? "icon-error_outline" : "icon-checkmark"} />
                        {fieldError[key]}
                      </span>
                    </div>
                  );
                }
                return (
                  <div className="errorMsg 2 show passwordcustom">{arr}</div>
                );
              } else {
                return (
                  <div className="errorMsg 1 show">
                    <i className="icon-error_outline" />
                    {errors[name]?.message}
                  </div>
                );
              }
            } else {
              return <div className="errorMsg 3"></div>;
            }
          }}
        />
      ) : hasError ? (
        <div className="errorMsg 4 show">
          <span>
            <i className="icon-error_outline" />
            {errors[name]?.message}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

TextField.defaultProps = {
  autoFocus: false,
  value: "",
  errors: {},
  multiErrorFields: [],
  readOnly: false,
  required: true,
};

export default TextField;
