import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// import images
import ticket from "../../../assets/images/ticket.svg";

import HookForm from "../../../components/hookForm/hookForm";
import CheckBox from "../../../components/checkBox/checkBox";
import TextField from "../../../components/textField/textField";
import CustomButton from "../../../components/customButton/customButton";
import useWindowDimension from '../../../components/hooks/useWindowDimension';

import "../auth.css";
import Logo from '../../../components/logo/logo';
import CardTopHeader from '../../../components/cardTopHeader/cardTopHeader';
import { ILoginForm } from "./loginInterface";
import { LoginParams } from "../../../interface/authInterface";
import Constant from "../../../util/constant";
interface LearningProps {
    loginForm: ILoginForm;
    onFormSubmit: (event: LoginParams) => void;
    busy: boolean,
    rememberMe: boolean;
    setRememberMe: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * A functional component that represents a login form.
 * @param {LearningProps} props - The props passed to the component.
 * @returns The JSX elements that make up the login form.
 */
const LoginComponent: React.FC<LearningProps> = (props) => {

    const { t } = useTranslation();
    const dimensions = useWindowDimension()
    const [showPass, setShowPass] = useState(false);

    const { loginForm, onFormSubmit, busy, setRememberMe, rememberMe } = props;

    return (
        <React.Fragment>
            <div className='auth-pages' style={{ height: dimensions.height }}>
                <Container>
                    <Row className='justify-content-center'>
                        <Col md={8} lg={6} xl={5}>
                            <Logo />
                            <Card className='overflow-hidden'>
                                <CardTopHeader
                                    labelTitle={t("Hello_Admin")}
                                    labelText={t("SIGN_IN_TO_CONTINUE")}
                                    rightImage={ticket}
                                    textShow={true} />
                                <CardBody className='Card_Body'>
                                    <div className='p-3'>
                                        <HookForm
                                            defaultForm={{}}
                                            onSubmit={(e) => onFormSubmit(e)}>
                                            {(formMethod) => {
                                                return (
                                                    <>
                                                        <div className='mb-4'>
                                                            <div className='d-flex'>
                                                                <Label>{t("EMAIL_ADDRESS")}</Label>
                                                                <Label className='requiredStyle'>*</Label>
                                                            </div>
                                                            <TextField
                                                                formMethod={formMethod}
                                                                rules={loginForm.email.validate}
                                                                name={loginForm.email.name}
                                                                errors={formMethod?.formState.errors}
                                                                autoFocus={true}
                                                                type='text'
                                                                placeholder={t("ENTER_EMAIL")}
                                                                defaultValue={window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_EMAIL)}
                                                                value={window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_EMAIL)}
                                                                multiErrorFields={undefined}
                                                            />
                                                        </div>
                                                        <div className='mb-4'>
                                                            <div className='d-flex'>
                                                                <Label>{t("PASSWORD")}</Label>
                                                                <Label className='requiredStyle'>* </Label>
                                                            </div>
                                                            <div className={
                                                                typeof formMethod.formState.errors[loginForm.password.name] === "undefined" &&
                                                                    formMethod.watch(loginForm.password.name) ? "input-group auth-pass-inputgroup validPass" : "input-group auth-pass-inputgroup"
                                                            }
                                                            >
                                                                <TextField
                                                                    formMethod={formMethod}
                                                                    rules={loginForm.password.validate}
                                                                    name={loginForm.password.name}
                                                                    errors={formMethod?.formState.errors}
                                                                    type={showPass ? "text" : "password"}
                                                                    placeholder={t("ENTER_PASSWORD")}
                                                                    defaultValue={window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_PASSWORD)}
                                                                    value={window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.SCOOPLY_REMEMBER_ME_PASSWORD)}

                                                                />
                                                                <button
                                                                    onClick={() => setShowPass(!showPass)}
                                                                    className='btn btn-light '
                                                                    type='button'
                                                                    id='password-addon'
                                                                    tabIndex={-1}                                                                >
                                                                    <i className={showPass ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i>{" "}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className='form-check'>
                                                            <CheckBox
                                                                checkboxClass='form-check-input'
                                                                id='customControlInline'
                                                                labelTitle={t("REMEMBER_ME")}
                                                                checked={rememberMe}
                                                                onCheckedChange={(checked) => setRememberMe(checked)}></CheckBox>
                                                            <div className='forgotLink'>
                                                                <Link to='/forgot-password'></Link>
                                                            </div>
                                                        </div>
                                                        <div className='mt-4 d-grid'>
                                                            <CustomButton
                                                                className='custom-btn padding8-16 font14 bgDarkGreen txtColor'
                                                                title={t("LOGIN")}
                                                                disabled={!formMethod?.formState.isValid}
                                                                loading={busy} children={undefined} icon={undefined} />
                                                        </div>
                                                        <div className='mt-4 text-center'>
                                                            <Link
                                                                to='/forgot-password'
                                                                className='text_green'>
                                                                <i className='mdi mdi-lock-outline me-1' />{" "}
                                                                {t(
                                                                    "FORGOT_YOUR_PASSWORD"
                                                                )}
                                                            </Link>
                                                        </div>
                                                    </>
                                                );
                                            }}
                                        </HookForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className='copy-right-text-style text-center'>
                                <p>{t("COPYRIGHTS_BY_SIGN_IN")}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};
export default LoginComponent;