import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
interface OrderStatusDetailsProps {
  notificationListData: any[]; // Update with actual data type
  read: boolean;
  placeholder: boolean;
  onReadClick: () => void;
  dimensions: any;
  headerHeight: any,
  readNotification: (notificationID: any) => void;
}

const NotificationComponent: React.FC<OrderStatusDetailsProps> = (props) => {
  const { t } = useTranslation();
  const { notificationListData, placeholder, read, onReadClick, dimensions, headerHeight, readNotification } = props;
  function formatDate(dateString: string) {
    const today = moment().format('YYYY-MM-DD');
    const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');
    const inputDate = moment(dateString, 'YYYY-MM-DD').format('YYYY-MM-DD');

    if (inputDate === today) {
        return 'Today';
    } else if (inputDate === yesterday) {
        return 'Yesterday';
    } else {
        return moment(inputDate).format('DD MMM YYYY');
    }
}

  return (
    <React.Fragment>
      <div className="page-content notification-page">
        <div className='page-title-style'>
          <div className="heading">{t('NOTIFICATIONS')}</div>
          <button type='button'
            className={
              read
                ? "btn-read-btn hide"
                : "btn-read-btn show"
            }
            onClick={() => onReadClick()}
          >
            {t("MARK_ALL_AS_READ")}
          </button>
        </div>
        <div className="main_card_body" style={{ height: dimensions.height - headerHeight - 100, overflow: 'auto' }}>
          {notificationListData?.map((item, index) => {
            return (
              <div key={item?.id}>
                <div className='title-style'>
                  {item?._id && formatDate(item?._id)}
                </div>
                {item?.notifications?.map((item: {
                  [x: string]: any; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; discription: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; date: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined; time: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | Iterable<React.ReactNode> | null | undefined;
                }) => {
                  return (
                    <div onClick={() => readNotification(item)} key={item?.id} className={
                      item?.isread
                        ? "list-card-style"
                        : `list-card-style unread-style`
                    }>
                      <div className='contain-left-style'>
                        <div className='notification-title-style'>
                          {placeholder && <div className="shimer"></div>}
                          {item?.title}
                        </div>
                        <div className='notification-txt-style'>
                          {placeholder && <div className="shimer"></div>}
                          {item?.description}
                        </div>
                      </div>
                      <div className='contain-right-style'>
                        <div className='text-style'><i className='mdi mdi-calendar-blank-outline'></i>
                          {placeholder && <div className="shimer"></div>}
                          {item?.datetime && moment(item?.datetime).format('DD MMM YYYY')}
                        </div>
                        <div className='text-style'><i className='mdi mdi-clock-time-five-outline'></i>
                          {placeholder && <div className="shimer"></div>}
                          {item?.datetime && moment(item?.datetime).format('hh:mm A')}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};
export default NotificationComponent;