import { GET_ALL_PRODUCT, CREATE_PRODUCT, UPDATE_PRODUCT, DELETE_PRODUCT, ENABLE_DISBLED_PRODUCT, FEATURED_PRODUCT } from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import { AddProductParams, EnableProductParams, isFeaturedProductParams, updateProductParams } from "../../../interface/productInterface";




const getAllProduct = (queryParams: any) => {
    return instance.get(GET_ALL_PRODUCT, {params: queryParams})
} 

const createProducts = (data: AddProductParams) => {
    return instance.post(CREATE_PRODUCT, {
        productname: data?.productname,
        description: data?.description,
        price: data?.price,
        image: data?.image,
    })
}

const updateProduct = (data:updateProductParams) => {    
    return instance.put(`${UPDATE_PRODUCT}`, {
        productname: data?.productname,
        description: data?.description,
        price: data?.price,
        image: data?.image,
        id: data?.id
    })
}



const deleteProduct = (productId: string) => {
   return instance.put(`${DELETE_PRODUCT}${productId}`)
}


const enableDisbledProduct = (data: EnableProductParams) => {
    return instance.put(ENABLE_DISBLED_PRODUCT, {
        status: data?.status,
        id: data?.id
    })
}

const isFeaturedProduct = (data: isFeaturedProductParams) => {
    return instance.put(FEATURED_PRODUCT, {
        isFeatured: data?.isFeatured,
        id: data?.id
    })
}



const productService = {
    getAllProduct,
    createProducts,
    deleteProduct,
    updateProduct,
    enableDisbledProduct,
    isFeaturedProduct
}


export default productService;
