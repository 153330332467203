/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Helmet } from 'react-helmet';
import ProductManagementComponent from './productManagementComponent';
import useWindowDimension from "../../components/hooks/useWindowDimension";
import AWS from "aws-sdk";
import { showToast } from "../../store/features/toast/toastSlice";

import "./productManagementComponent";
import { AppDispatch } from "../../interface/driverInterface";
import { createProductResponce, deleteProductResponce, enableDisbledProductResponce, idFeaturedProductResponce, productListResponce, setProgramQueryParams, updateProductResponce } from "../../store/features/productManagement/productSlice";
import { productData } from "../../interface/productInterface";
import getCroppedImg from "../../components/cropImage/cropImage";
import { Point } from "react-easy-crop";


// Configure AWS SDK
/**
 * Updates the AWS configuration with the provided region, access key ID, and secret access key.
 * @param {{string}} awsConfig.aws_region - The AWS region to set in the configuration.
 * @param {{string}} process.env.REACT_APP_AWS_ACCESS_KEY_ID - The access key ID to set in the configuration.
 * @param {{string}} process.env.REACT_APP_AWS_SECRET_ACCESS_KEY - The secret access key to set in the configuration.
 * @returns None
 */
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});


const uploadToS3 = async (file: any, folderPath: string) => {
  const s3 = new AWS.S3();
  const fileName = `${folderPath}/${file?.name}`;

  const params: any = {
    Bucket: process.env.REACT_APP_AWS_USER_FILE_S3_BUCKET,
    Key: fileName,
    Body: file,
    ContentType: file?.type,
    ACL: "public-read", // Set the ACL to make the object publicly accessible
  };

  try {
    const data = await s3.upload((params as any)).promise();
    return data.Location; // S3 object URL
  } catch (error) {
    console.error("Error uploading to S3:", error);
    throw error;
  }
};

interface FeaturedProduct {
  _id: string;
  productname: string;
  description: string;
  price: number;
  image: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isfeatured: boolean;
}

/**
 * A container component for managing products.
 * @component
 * @returns The ProductManagementContainer component.
 */
const ProductManagementContainer: React.FC = (props) => {
  const { t } = useTranslation();
  const pageSizeRef = useRef(5000);
  const buttonDisable = useRef(false);
  let triggerRef = useRef(null);
  const dispatch = useDispatch<AppDispatch>();
  const [uploadingImg, setUploadingImg] = useState(false) ;
  const [fullname, setFullName] = useState("");
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const dimensions = useWindowDimension();
  const [addAndEditLoader, setAddAndEditLoader] = useState(false); 
  const productList = useSelector((state) => (state as any)?.product?.productResponce);  
  const totalProduct = useSelector((state: any) => state?.product?.productTotal);
  const programQueryParams = useSelector((state: any) => state?.product?.programQueryParams);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [showProductDeleteModal, setShowProductDeleteModal] = useState<boolean>(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const [defaultNumber,] = useState('');
  const [itemName, setItemName] = useState('');
  const [productId, setProductId] = useState('');  
  const [loading, setLoading] = useState(false);
  const [placeHolder, setPlaceHolder] = useState<boolean>(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [pageTitleHeight, setPageTitleHeight] = useState<number>(0);
  const [buttonRowHeight, setButtonRowHeight] = useState<number>(0);
  const selectedItem = productList?.filter((value: any) => value?.isfeatured )
  const [isFeatured, setIsFeatured] = useState<FeaturedProduct | null>(null);

  const [showAddEditProductModal, setShowAddEditProductModal] = useState<boolean>(false);
  const [showTypeAddEditModal, setShowTypeAddEditModal] = useState<any>({ modal: 'add' });
  const [productValue, setProductValue] = useState('');
  const [enableBtnLoader, setEnableBtnLoader] = useState(false);
  const [, setCreateValues] = useState("");
  // const [descriptiontextcount, setDescriotiontextcount] = useState(0);
  const [editDescriptiontextcount, seteditDescriotiontextcount] = useState('');

  const [loadingImg, setLoadingImg] = useState<boolean>(false);
  const [loadeMore, setLoadMore] = useState<boolean>(true);
  const [descriptiontextcount, setDescriotiontextcount] = useState<any>(0);
 const handleDescriptiontextcount = (e: any) => {
  const value = e.target.value;
  setDescriotiontextcount(value?.length);
};

console.log('loadeMore', loadeMore);

// useEffect(() => {
  // setDescriotiontextcount(showTypeAddEditModal.modal === 'add' ? 0 : editDescriptiontextcount?.length);
// }, [descriptiontextcount]);
  /**
   * Opens the product modal for adding a new product.
   * Sets the show type to 'add' and shows the add/edit product modal.
   * @returns None
   */
  const addProductModal = () => {
    setShowTypeAddEditModal({ modal: 'add' });
    setShowAddEditProductModal(true);
  };
  /**
   * Closes the add/edit modal and resets the profile image and show state.
   * @returns None
   */
  const closeAddEditModal = () => {
    setProfileImage('');
    setImage('')
    setEditImage(false);
    setUploadingImg(false)
    setDescriotiontextcount(0);
    setShowAddEditProductModal(false);
    setCroppedImage('');
    setZoom(1);
  };

  /**
   * Updates the create values state with the provided form data.
   * @param {} form - The form data to update the create values state with.
   * @returns None
   */
  const productAddEditFunction = (form: any) => {
    setCreateValues(form);
  };

  useEffect(() => {
    dispatch(setProgramQueryParams({page: 1, doc: 10}))
    getList({page: 1, doc: 12})
    window.localStorage.setItem('currentTab', '')
  }, []);

  /**
   * Retrieves a list of products asynchronously and handles the response.
   * @returns None
   */
  const getList = async (queryParams: any) => {
    setLoading(true);
   try {
    const responce = await dispatch(productListResponce(queryParams))
    
    if (responce?.payload?.products?.length < 10) {
      setLoadMore(false)
     }
     pageSizeRef.current += responce?.payload?.products?.length;
     setPlaceHolder(false);
     setLoading(false);
   } catch (error) {
    setPlaceHolder(false);
    setLoading(false);
   }


    // dispatch(productListResponce()).then((resultAction) => {
    //   if (productListResponce.fulfilled.match(resultAction)) {
    //     setPlaceHolder(false);
    //   } else if (productListResponce.rejected.match(resultAction)) {
    //     // console.log('resultAction', resultAction);
    //   }
    // })
  }

  /**
   * Handles the submission of a product form.
   * @param {} data - The data from the form submission.
   * @returns None
   */
  const handleSubmitProduct = async (data: any) => {
    setAddAndEditLoader(true)
    const croppedImageNew:any = await getCroppedImg( 
      image,
      croppedAreaPixels
    )
    setCroppedImage(croppedImageNew)

    if (showTypeAddEditModal.modal === 'add') {    
    let url: | any
    if (croppedImageNew) {
      try {
        url = await uploadToS3(croppedImageNew?.createdFile, process.env.REACT_APP_AWS_IMAGE_PATH || 'productimages');
      } catch (error) {
        setAddAndEditLoader(false)
      }
    }
    const lastSlashIndex =  url?.lastIndexOf('/');
    const imageName = url?.substring(lastSlashIndex + 1);
    
        const params = {
          productname: data?.productName,
          description: data?.description,
          price: data?.productPrice,
          image: imageName,
        }
         dispatch(createProductResponce(params)).then((resultAction) => {
          if (createProductResponce.fulfilled.match(resultAction)) {
            dispatch(
              showToast({
                show: false,
                message: (resultAction as any)?.payload?.message,
                type: "success",
              })
            );
            setShowAddEditProductModal(false);
            getList({page: 1, doc: 15})
            setAddAndEditLoader(false)
            setProfileImage('');
            setCroppedImage('');
            setImage('');
            setEditImage(false);
            setUploadingImg(false);
            setZoom(1);
          } else if (createProductResponce.rejected.match(resultAction)) {
            dispatch(
              showToast({
                show: false,
                message: resultAction?.error?.message,
                type: "error",
              })
            );
            setShowAddEditProductModal(false);
            setProfileImage('');
            setCroppedImage('');
            setImage('');
            setUploadingImg(false);
            setEditImage(false);
            setAddAndEditLoader(false);
            setZoom(1);
          }
         });

   } else {    
     
     setAddAndEditLoader(true);
     const croppedImageNew:any = await getCroppedImg( 
      image,
      croppedAreaPixels
    )
    setCroppedImage(croppedImageNew);

     let url: | any
     if (croppedImageNew?.createdFile) {
      try {   
        url = await uploadToS3(croppedImageNew?.createdFile, process.env.REACT_APP_AWS_IMAGE_PATH || 'productimages');
      } catch (error) {
        setAddAndEditLoader(false)
      }   
      }
      const lastSlashIndex =  url?.lastIndexOf('/');
      const imageName = url?.substring(lastSlashIndex + 1);
      // console.log('IMAGE NAME', imageName);
      
      const params = {
        productname: data?.productName,
        description: data?.description,
        price: data?.productPrice,
        image: imageName === undefined ? data?.thumbnailfile : imageName,
        id: productId
      }    
    dispatch(updateProductResponce(params)).then((resultAction) => {
      if (updateProductResponce.fulfilled.match(resultAction)) {        
        dispatch(
          showToast({
            show: false,
            message: (resultAction as any)?.payload?.res?.message,
            type: "success",
          })
        );
        getList({page: 1, doc: 10})
        setShowAddEditProductModal(false);
        setAddAndEditLoader(false)
        setProfileImage('');
        setImage('');
        setEditImage(false);
        setCroppedImage('');
        setZoom(0);
      } else if (updateProductResponce.rejected.match(resultAction)) {
        dispatch(
          showToast({
            show: false,
            message: resultAction?.error?.message,
            type: "error",
          })
        );
        setShowAddEditProductModal(false);
        setProfileImage('');
        setImage('');
        setEditImage(false);
        setCroppedImage('');
        setAddAndEditLoader(false);
        setZoom(0);
      }
     });
   }
  };


  /**
   * Updates the product details and opens the edit modal for the specified item.
   * @param {any} item - The item to be updated.
   * @returns None
   */
  const onUpdateProduct = (item: any) => {
    console.log('itemProduct: ', item);
    setShowTypeAddEditModal({ modal: 'edit' });
    setProductId(item?._id)
    setProductValue(item)
    seteditDescriotiontextcount(item?.description)
    setDescriotiontextcount(item?.description?.length)
    setProfileImage(item?.image)
    setShowAddEditProductModal(true)
    setCroppedImage( `${process.env.REACT_APP_AWS_IMAGE_URL}${process.env.REACT_APP_AWS_IMAGE_PATH}/` + item?.image)
  };

  const closeProductDeleteModal = () => setShowProductDeleteModal(false);
  const closeDeactivateModal = () => setShowStatusModal(false);

  const onTableFilterChange = (filterText: any) => {
    setSearchValue(filterText);
  };

  const searchFilterClear = () => {
    tableContainerRef.current!.querySelectorAll("button")[0].click();
  };

 
  /**
   * Opens the product delete modal for the specified item.
   * @param {Event} e - The event object.
   * @param {productData} item - The item to be deleted.
   * @returns None
   */
  const openProductDeleteModal = (e: Event, item: productData) => {
    setFullName(item?.productname || '-')
    setProductId(item?._id);
    setShowProductDeleteModal(true);
  };

  /**
   * Handles the change event when a checkbox is checked or unchecked.
   * @param {Event} e - The event object.
   * @param {productData} item - The product data associated with the checkbox.
   * @returns None
   */
  const onCheckChange = (e: boolean, item: productData) => {  
    const LogicalItem = selectedItem?.length >= 3 && item?.isfeatured === false ? true : e
    if (!buttonDisable.current) {
      buttonDisable.current = true
    if (LogicalItem === true) {
        const params = {
          isFeatured: true,
          id: item?._id
        }
        setTimeout(() => {
          buttonDisable.current = true
          dispatch(idFeaturedProductResponce(params)).then((resultAction) => {
            setTimeout(() => {
            if (idFeaturedProductResponce.fulfilled.match(resultAction)) {
              dispatch(
                showToast({
                  show: false,
                  message: (resultAction as any)?.payload.res.message,
                  type: "success",
                })
              );
              buttonDisable.current = false
            } else if (idFeaturedProductResponce.rejected.match(resultAction)) {
              buttonDisable.current = false
              dispatch(
                showToast({
                  show: false,
                  message: resultAction?.error?.message,
                  type: "error",
                })
              );
            }
          }, 500);
          })
        }, 500);
    } else {
      const params = {
        isFeatured: false,
        id: item?._id
      }
      setTimeout(() => {
        
        dispatch(idFeaturedProductResponce(params)).then((resultAction) => {
          if (idFeaturedProductResponce.fulfilled.match(resultAction)) {
            buttonDisable.current = false
          } else if (idFeaturedProductResponce.rejected.match(resultAction)) {
            buttonDisable.current = false
          }
        })
      }, 500);
  
    }
  }
  };


  const columns = [
    {
      label: (
        <>
          {t("PRODUCT_DETAILS")}
        </>
      ),
      key: "productname",
      filter: true,
      sorting: false,
    },
    {
      label: (
        <>
          {t("DESCRIPTION")}
        </>
      ),
      key: "description",
      filter: true,
      sorting: false,
    },
    {
      label: (
        <>
          {t("PRODUCT_PRICE")}
        </>
      ),
      key: "price",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("FEATURED_PRODUCT")}
        </>
      ),
      key: "isfeatured",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("ACTIONS")}
        </>
      ),
      key: "actions",
      filter: false,
      sorting: false,
    },
  ];


  /**
   * Handles the check action event.
   * @param {Event} e - The event object.
   * @returns None
   */
  const onCheckAction = (e: Event, item: any) => {   
    setIsFeatured(item); 
    setItemName(item?.productname);
    const status = item?.status;
    if (status === "active") {
      setShowStatusModal(true);
      setProductId(item?._id);
    } else {
      UnableAction(e, item._id);
    }
  };
  /**
   * Handles the deletion of a product.
   * @returns None
   */
  const onProductDelete = async () => {
    setDeleteBtnLoader(true)
    dispatch(deleteProductResponce(productId)).then((resultAction) => {
      if (deleteProductResponce.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            show: false,
            message: (resultAction as any)?.payload.res.message,
            type: "success",
          })
        );      
        setShowProductDeleteModal(false);
        setDeleteBtnLoader(false)
      } else if (deleteProductResponce.rejected.match(resultAction)) {
        setShowProductDeleteModal(false);
        setDeleteBtnLoader(false)
        dispatch(
          showToast({
            show: false,
            message: resultAction?.error?.message,
            type: "error",
          })
        );
      }
    })
  }
  /**
   * Updates the status of a product to "deactive" and displays a success toast message.
   * @returns None
   */
  const onProcced = async () => {
    setEnableBtnLoader(true)

    if (isFeatured && isFeatured.isfeatured) {
      setShowStatusModal(false);
      setEnableBtnLoader(false);
      dispatch(
        showToast({
          show: true,
          message: `The product "${isFeatured.productname}" is a featured product, so you cannot disable it.`,
          type: "error",
        })
      );

      return;
    }
    else {
      const params = {
        status: 'deactive',
        id: productId
      }
      dispatch(enableDisbledProductResponce(params)).then((resultAction) => {
        if (enableDisbledProductResponce.fulfilled.match(resultAction)) {
          dispatch(
            showToast({
              show: false,
              message: (resultAction as any)?.payload.res.message,
              type: "success",
            })
          );
          setShowStatusModal(false);
          setEnableBtnLoader(false);
        }
      })
    }
  };


  /**
   * Handles the action of re-activating a product in the system.
   * @param {Event} e - The event object.
   * @param {productData} id - The ID of the product to be re-activated.
   * @returns None
   */
  const UnableAction = async (e: Event, id: string) => {

    if (!buttonDisable.current) {
      buttonDisable.current = true
      const params = {
        status: 'active',
        id: id
      }
      dispatch(enableDisbledProductResponce(params)).then((resultAction) => {
        if (enableDisbledProductResponce.fulfilled.match(resultAction)) {
          dispatch(
            showToast({
              show: false,
              message: (resultAction as any)?.payload.res.message,
              type: "success",
            })
          );
          buttonDisable.current = false
        } else if (enableDisbledProductResponce.rejected.match(resultAction)) {
          buttonDisable.current = false
          dispatch(
            showToast({
              show: false,
              message: resultAction?.error?.message,
              type: "error",
            })
          );
        }
      })

    }
  };


  /**
   * Function to handle table scrolling and load more items when the user reaches the bottom of the table.
   * @returns None
   */
  const loadMoreItems = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      pageSizeRef.current += 10;
    }, 2000);
  };
  
  // const tableContainer = tableContainerRef.current as HTMLDivElement;
  const handleTableScroll = debounce(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      const isAtBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight;
         
        // if (isAtBottom && productList?.length > pageSizeRef.current) {
        if (totalProduct > productList?.length) {
        // loadMoreItems();
        dispatch(setProgramQueryParams({page: programQueryParams.page + 1, doc: programQueryParams.doc}))
          getList({page: programQueryParams.page + 1, doc: programQueryParams.doc})
      }
    }
  }, 200);

  /**
   * Adds an event listener to the table container element to handle scroll events.
   * The event listener is removed when the component is unmounted.
   * @param {Function} handleTableScroll - The function to handle the table scroll event.
   * @returns None
   */
  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleTableScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleTableScroll);
      }
    };
  }, [handleTableScroll]);
  /**
   * useEffect hook that calculates and sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    const pageTitleHeight = document.getElementsByClassName("heading")[0] as HTMLElement;
    const buttonRowHeight = document.getElementsByClassName("addBtn")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
    if (pageTitleHeight) {
      setPageTitleHeight(pageTitleHeight.offsetHeight);
    }
    if (buttonRowHeight) {
      setButtonRowHeight(buttonRowHeight.offsetHeight);
    }
  }, []);

  // upload file code start
  const [profileImage, setProfileImage] = useState<string>('');

  console.log('profileImage',profileImage);
  
  // const [isUploadingDocumentLink, setIsUploadingDocumentLink] = useState<boolean>(false);
  const [dimenstionError, setDimenstionError] = useState<boolean>(false);

  const [uploadedDocumentThumnailFile, setuploadedDocumentThumnailFile] = useState<Blob[] | undefined>();
  // console.log('uploadedDocumentThumnailFile', uploadedDocumentThumnailFile);
  
  const uploadThumNailValid: any = {
   'image/jpeg': [], 'image/png': [], 'image/jpg': [], 'image/webp': []
  };
  /**
   * Handles the upload of a thumbnail file.
   * @param {function} loaderState - A function to update the loader state.
   * @param {function} uploadFunction - A function to handle the upload of the files.
   * @returns None
   */
  const handleUploadThumnail = (files: any, loaderState: (arg0: boolean) => void, uploadFunction: (arg0: any) => void) => {
    loaderState(true);
    setTimeout(() => {
      // uploadFunction(files);
      loaderState(false);
    }, 1000);
  };

  // const onChangeUploadingDocumentLink = () => {
  //   setIsUploadingDocumentLink(false); // or setProfileImage(undefined);

  // };
  /**
   * Handles the upload of a thumbnail file.
   * @param {Blob[]} files - An array of Blob objects representing the uploaded files.
   * @returns None
   */
  // console.log('LoadingImg',loadingImg);
  
  const handleUploadThumnailFile = (files: Blob[] | undefined) => {
    // console.log('filesss',files);
    
    if (files && files.length > 0) {
      setuploadedDocumentThumnailFile(files);
      const reader = new FileReader();
      reader.addEventListener("load", (event: any) => {
        setLoadingImg(false);
        return setProfileImage(event?.target?.result);
      });
      reader.readAsDataURL(files[0]);
    }
    else{
      setLoadingImg(false);
    }
  };

  // IMPLEMENT CROP IMAGE BY KAUSHIK
  const uploadInputRef = useRef<HTMLInputElement>(null);

  const editFileUpload = () => {
    if (uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };

  const fileUploadChange = (e: any) => {
    setLoadingImg(false);
    setEditImage(false)
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
      
    };
    reader.readAsDataURL(files[0]);
  };

  const aspectRatio = 1 / 1;
  const [image, setImage] = useState('');

  
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)
  const [croppedImage, setCroppedImage] = useState<any>(null)
  const [editImage, setEditImage] = useState<any>(false)

  console.log('editImage',editImage);
  
  


  const onCropComplete = (croppedArea:any, croppedAreaPixels:any) => {
    setCroppedAreaPixels(croppedAreaPixels)
  };

  const onPressCropCancel = () => {
    setImage('')
    setCroppedImage('')
    setEditImage(true);
    setZoom(1);
  };

  const showCroppedImage = async (e:any) => {
    setLoadingImg(true);
    setEditImage(false);
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels
      )
      setCroppedImage(croppedImage)
      setImage('')
      setLoadingImg(false);
      setZoom(0);
    } catch (e) {
      console.error(e)
      setLoadingImg(false);
      setZoom(0);
    }
  }

  // const blobToBase64 = async (croppedImage:any) => {
  //   console.log('croppedImage: ', croppedImage);
  //   const response = await fetch(croppedImage);
  //   console.log('response: ', response);
  //   const blob = await response.blob();
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       resolve(reader.result);
  //     };
  //     reader.onerror = reject;
  //     reader.readAsDataURL(blob);
  //   });
  // };
  
  // // Usage
  // blobToBase64(croppedImage)
  // .then((base64Image) => {
  //   console.log('Base64 encoded image:', base64Image);
  // })
  // .catch((error) => {
  //   console.error('Error converting blob to Base64:', error);
  // });

  return (
    <>
    <Helmet>
      <title>Products | Scooply Admin Panel</title>
      <meta name="description" content="Manage product details, add new products, and lots more." />
    </Helmet>
    <ProductManagementComponent
        onSlideSwitch={onCheckAction}
        columns={columns}
        onProcced={onProcced}
        productData={productList}
        showStatusModal={showStatusModal}
        noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1700123419/Scooply/web/project-image/product.svg'}
        NoDataTitle={t("NO_RESULT_FOUND")}
        NoDataDesc={t("PLEASE_TRY_AGAIN")}
        emptyDataTitle={t("NO_PRODUCT_ADDED")}
        noDataFoundText={t('PLEASE_CLICK_ON_BELOW_BUTTON_TO_ADD_PRODUCT')}
        searchValueData={`Your search "${searchValue}" did not match any customer.`}
        noDataAddButtonTitle={t("ADD_PRODUCT")}
        searchClearTitle={t("CLEAR_SEARCH")}
        closeDeactiveModal={closeDeactivateModal}
        onTableFilterChange={onTableFilterChange}
        placeHolderShimer={placeHolder}
        dimensions={dimensions}
        defaultNumber={defaultNumber}
        headerHeight={headerHeight}
        pageTitleHeight={pageTitleHeight}
        buttonRowHeight={buttonRowHeight}
        searchValue={searchValue}
        itemName={itemName}
        searchFilterClear={searchFilterClear}
        loading={loading}
        tableContainerRef={tableContainerRef}
        itemsPerPage={pageSizeRef.current}
        handleTableBodyScroll={handleTableScroll}
        onCheckChange={onCheckChange}
        showProductDeleteModal={showProductDeleteModal}
        openProductDeleteModal={(e, item) => {
          openProductDeleteModal(e, item);
        } }
        closeProductDeleteModal={closeProductDeleteModal}
        onProductDelete={onProductDelete}

        addProductModal={addProductModal}
        showTypeModal={showTypeAddEditModal}
        showAddEditProductModal={showAddEditProductModal}
        closeAddEditModal={closeAddEditModal}
        productAddEditFunction={productAddEditFunction}
        handleSubmitProduct={handleSubmitProduct}
        productValue={productValue}
        onUpdateProduct={onUpdateProduct}

        triggerRef={triggerRef}
        profileImage={profileImage}
        dimenstionError={dimenstionError}
        uploadThumNailValid={uploadThumNailValid}
        setDimenstionError={setDimenstionError}
        // handleUploadThumnail={handleUploadThumnail}
        // isUploadingDocumentLink={isUploadingDocumentLink}
        handleUploadThumnailFile={handleUploadThumnailFile}
        // onChangeUploadingDocumentLink={onChangeUploadingDocumentLink}
        uploadedDocumentThumnailFile={uploadedDocumentThumnailFile}
        loaderButtonShow={deleteBtnLoader}
        addAndEditLoader={addAndEditLoader}
        fullname={fullname}
        enableBtnLoader={enableBtnLoader}
        selectedItem={selectedItem}
        descriptiontextcount={descriptiontextcount}
        handleDescriptiontextcount={handleDescriptiontextcount}
        setLoadingImg1={setLoadingImg}
        loadingImg={loadingImg} 
        setUploadingImg={setUploadingImg}
        uploadingImg={uploadingImg}

         // IMPLEMENT CROP IMAGE
        uploadInputRef={uploadInputRef}
        editFileUpload={editFileUpload}
        fileUploadChange={fileUploadChange}
        aspectRatio={aspectRatio}
        image={image}
        // setImage={setImage}
        crop={crop}
        setCrop={setCrop}
        zoom={zoom}
        setZoom={setZoom}
        // croppedAreaPixels={croppedAreaPixels}
        // setCroppedAreaPixels={setCroppedAreaPixels}
        croppedImage={croppedImage}
        // setCroppedImage={setCroppedImage}
        onCropComplete={onCropComplete}
        showCroppedImage={showCroppedImage}
        onPressCropCancel={onPressCropCancel}
        editImage={editImage}
        totalProduct={totalProduct}
        />
   </>);
};

export default ProductManagementContainer;


