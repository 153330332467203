import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface ProfileMenuProps {
  handleLogout: () => void;
}

const getUserName = () => {
  const storedData = localStorage.getItem("authUser");
  return storedData;
  // if (storedData !== null) {
  //   const obj = JSON.parse(storedData);
  //   return obj;
  // }
  // return null; // Return null or handle the case of no data in a way that fits your use case.
};


const ProfileMenu: React.FC<ProfileMenuProps> = (props) => {
  const { handleLogout } = props;

  const { t } = useTranslation();
  const [menu, setMenu] = useState(false);
  const [name, setName] = useState<string>("");

  useEffect(() => {
    const userData = getUserName();
    if (userData) {
      setName(userData);
    }
  }, []);

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block profile-dropdown-style">
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="bx bx-cog setting" />
          {/* <i className="bx bx-cog bx-spin setting" /> */}
          <span className="d-xl-inline-block profile-name-style">{name}</span>
          <i className="mdi mdi-chevron-down d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag={Link} to="/editProfile">
            <i className="mdi mdi-account-circle-outline font-size-24 align-middle" />
            {t("Edit Profile")}
          </DropdownItem> */}
          <DropdownItem tag={Link} to="/change-password">
            <i className="mdi mdi-key-outline font-size-24 align-middle" />
            <span>{t("Change Password")}</span>
          </DropdownItem>
          <DropdownItem tag="a" onClick={handleLogout}>
            <i className="mdi mdi-exit-to-app font-size-24 align-middle text-danger" />
            <span>{t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default ProfileMenu;
