import React, { ChangeEvent, MouseEvent } from "react";

interface CustomToggleButtonProps {
  onCheckChange?: (checked: boolean) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  checked: boolean;
  id: string;
}

const CustomToggleButton: React.FC<CustomToggleButtonProps> = (props) => {
  const { onCheckChange, onClick, checked, id } = props;

  const onToggleButtonChecked = (e: ChangeEvent<HTMLInputElement>) => {
    return onCheckChange && onCheckChange(e.target.checked);
  };

  return (
    <>
      <div className="form-check form-switch form-switch-md">
        <input
          type="checkbox"
          className="form-check-input"
          id={id}
          checked={checked}
          onClick={onClick}
          onChange={onToggleButtonChecked}
        />
      </div>
    </>
  );
};

export default CustomToggleButton;
