import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Constant from '../../../util/constant';
import ForgotPasswordComponent from "./forgotPasswordComponent";
import { showToast } from "../../../store/features/toast/toastSlice";
import { useTranslation } from 'react-i18next';
import { forgotAdmin } from "../../../store/features/auth/authSlice";
import { AppDispatch } from "../../../interface/authInterface";
import { forgotData, IForgotForm } from "./forgotInterface";
import { Helmet } from 'react-helmet';


/**
 * A container component for the Forgot Password feature.
 * @returns The rendered ForgotPasswordComponent.
 */
const ForgotPasswordContainer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [busy, setBusy] = useState(false);
    window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.RESENDOTP, '')
   window.sessionStorage.setItem(Constant.LOCALSTORAGEKEYS.RESET_PASS, '');

   useEffect(() => {
    localStorage.clear()
}, []);

    const forgotPassForm:IForgotForm = {
        email: {
            name: "email",
            validate: {
                required: {
                    value: true,
                },
                pattern: {
                    value: Constant.REGEX.EMAIL,
                    message: t('PLEASE_ENTER_A_VALID_EMAIL_ADDRESS'),
                },
            },
        },
    };
    /**
     * Handles the form submission for the forgot password feature.
     * @param {forgotData} value - The form data submitted by the user.
     * @returns None
     */
    const onFormSubmit = async (value: forgotData) => {
        setBusy(true);
        dispatch(forgotAdmin(value)).then((resultAction) => {
            if(forgotAdmin.fulfilled.match(resultAction)) {
              const timeandDate = new Date ()
              const expireTime = new Date(timeandDate.getTime() + 2 * 60 * 1000)
              window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.EXPIRE_TIME, (expireTime as any));
              window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, resultAction?.payload?.data?.otp);
              window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.RESET_TOKEN, resultAction?.payload?.data?.resetToken);
              dispatch(
                showToast({
                  show: false,
                  message: (resultAction as any)?.payload?.message,
                  type: "success",
                })
              );
              setBusy(false);
              navigate("/otp-verification");
            } else if (forgotAdmin.rejected.match(resultAction)) {
              setBusy(false);
              dispatch(showToast({
                show:false,
                message:  resultAction?.error?.message,
                type: "error",
              }),
            );
            }
           })
    };
   return (<>
    <Helmet>
      <title>Forgot Password | Scooply Admin Panel</title>
      <meta name="description" content="Forgotten your password? Not to worry - update it on this page." />
    </Helmet>
    <ForgotPasswordComponent
      busy={busy}
      onFormSubmit={onFormSubmit}
      forgotPassForm={forgotPassForm}
    />
  </>);
};

export default ForgotPasswordContainer;
