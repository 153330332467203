/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Select from "react-select";
import { debounce } from 'lodash';
import moment from 'moment';

import './orderManagementComponent';
import Constant from '../../util/constant';
import OrderManagementComponent from './orderManagementComponent';
import { showToast } from '../../store/features/toast/toastSlice';
import useWindowDimension from '../../components/hooks/useWindowDimension';
import { getAllOrders, refundPayment } from "../../store/features/orderManagement/orderManagementSlice";
import { AppDispatch } from "../../interface/driverInterface";
import Calender from "../../components/calender/calender";


const OrderManagementContainer: React.FC = (props) => {
  const { state } = useLocation();
  
  var defaultFilterDataRes = state?.customerDataFilter;  
  let backButtonShow = state?.backButtonShow
  const defaultTabKey: any = window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CURRENTAB) === '' || window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CURRENTAB) === null ? Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW : window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CURRENTAB)
  const { t } = useTranslation();
  const orderListRes = useSelector((state: any) => state?.order?.ordersListResponce?.data)
  const dispatch = useDispatch<AppDispatch>();
  const pageSizeRef = useRef(15);
  const TripID = window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.TRIP_ID);
  const DriverName = window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME)
  const PendingOrderFilterDate = window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.PENDING_ORDER_DATETIME)
  const CustomerName = window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME);
  const DateOrder = window.localStorage.getItem('orderDate');
  const tableContainerRef1: React.RefObject<HTMLDivElement> = useRef(null);
  const tableContainerRef2: React.RefObject<HTMLDivElement> = useRef(null);
  const tableContainerRef3: React.RefObject<HTMLDivElement> = useRef(null);
  const tableContainerRef4: React.RefObject<HTMLDivElement> = useRef(null);
  const dimensions = useWindowDimension();
  const noShowCount = useSelector((state: any) => state?.order?.ordersListResponce?.noShow);
  const pastOrderscount = useSelector((state: any) => state?.order?.ordersListResponce?.pastOrders);
  const pendingOrdersCount = useSelector((state: any) => state?.order?.ordersListResponce?.pendingOrders);
  const paymentFailedCount  = useSelector((state: any) => state?.order?.ordersListResponce?.paymentFailed);
  const [orderListResponce, setOrderListResponce] = useState<any | null>();
  const [searchValue, setSearchValue] = useState<any | null>(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.SEARCHVALUE));
  const [currentTab, setCurrentTab] = useState<any | string>('');
  const [defaultFilterData, setDefaultFilterData] = useState<any | string>(defaultFilterDataRes);
  const [loading, setLoading] = useState(false);
  const [tripCheckClear, setTripCheckClear] = useState(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.TRIP_ID) !== '' ? true : false);
  const [driverValueClear, setDriverValueClear] = useState(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME) !== '' ? true : false);
  const [customerValueClear, setCustomerValueClear] = useState(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME) !== '' ? true : false);
  const [placeHolder, setPlaceHolder] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [pageTitleHeight, setPageTitleHeight] = useState<number>(0);
  const [tabTitleHeight, setTabTitleHeight] = useState<number>(0);
  const [buttonRowHeight, setButtonRowHeight] = useState<number>(0);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [refundBtnLoader, setRefundBtnLoader] = useState<boolean>(false);
  const closeStatusModal = () => setShowStatusModal(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [customerCount, setCustomerCount] = useState<number>();
  const [tripCount, setTripCount] = useState<number>();
  const [customerData, setCustomerData] = useState<any | string>('');
  const [filterData, setFilterData] = useState<number>();
  const [tabChangeBool, setTabChangeBool] = useState<boolean>(false);

  const [driverCount, setDriverCount] = useState<number>();
  const [filteredEventData, setFilteredEventData] = useState<number>();

  const onTableFilterChange = (filterText: any) => {      
    setSearchValue(filterText);
  };

  useEffect(() => {
    handleTabChangeClick(defaultTabKey)
  }, []);



  useEffect(() => {
    if (Array.isArray(orderListRes)) {
      const customerCountFilter: any = orderListRes?.filter(item =>
        (item?.customerDetails?.fullname ===  CustomerName)
        );
        setCustomerCount(customerCountFilter?.length)
        const tripCountFilter: any = orderListRes?.filter(item =>
          (item?.tripDetails?.tripid ===  TripID)
          );
          setTripCount(tripCountFilter?.length)
    //     const driverCountFilter: any = orderListRes?.filter(item =>
    //       (item?.driverDetails?.fullname ===  DriverName)
    //       );
    //       setDriverCount(driverCountFilter?.length)
    }
  }, [orderListRes]);


  useEffect(() => {
 // for the search Count   
    if (Array.isArray(orderListRes)) {
      const lowercaseSearchValue = searchValue?.toLowerCase();
  
      const filteredData: any = orderListRes?.filter(item =>
        (item?.customerDetails?.fullname?.toLowerCase()?.includes(lowercaseSearchValue)) 
        ||
        item?.tripDetails?.tripid?.toString()?.includes(lowercaseSearchValue) ||
        item?.collectioncode?.toString()?.includes(lowercaseSearchValue) ||
        item?.orderamount?.toString()?.includes(lowercaseSearchValue) ||
        item?.driverDetails?.fullname?.toLowerCase()?.includes(lowercaseSearchValue)
        );        
  
      setFilteredEventData(filteredData?.length);
    }
  }, [searchValue, orderListRes]);
  
  
  const searchFilterClear = () => {
    tableContainerRef1.current!.querySelectorAll("button")[0].click();
    tableContainerRef2.current!.querySelectorAll("button")[0].click();
    tableContainerRef3.current!.querySelectorAll("button")[0].click();
    tableContainerRef4.current!.querySelectorAll("button")[0].click();
    setSearchValue('')
    localStorage.removeItem('orderDate');
    setStartDate(null);
    localStorage.setItem('customerName', '')
    setDefaultFilterData(undefined)
  };

  useEffect(() => {
    // if (orderListRes && orderListRes?.length > 0) {
    // if (orderListRes?.length > 0) {
      setOrderListResponce(orderListRes?.map((item: any) => {
        return {
          ...item, 
          customerName: item?.customerDetails?.fullname || "",
          tripId: item?.tripDetails?.tripid || "",
          driverName: item?.driverDetails?.fullname || ""
        };
      }));
    // }
    // window.localStorage.setItem('orderDate', '');
  }, [orderListRes]);

  useEffect(() => {
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.SEARCHVALUE, searchValue)
 
  }, [searchValue]);

  const handleTabChangeClick = async (data: string) => {
    setPlaceHolder(true)
    setFilterData(undefined);
    setTabChangeBool(false)
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.CURRENTAB, data)
    setCurrentTab(data)
    // setStartDate('')
    // window.localStorage.setItem('orderDate', '');
      dispatch(getAllOrders(data)).then((resultAction: any) => {
        if (getAllOrders.fulfilled.match(resultAction)) {
            // setFilterData(resultAction?.payload?.data?.length);
            setPlaceHolder(false);
            // setPlaceHolder(true);
            setTabChangeBool(true)
        } else if (getAllOrders.rejected.match(resultAction)) {
          setPlaceHolder(false);
        }
      });
  }


  const columns = (val?: any) => {
    return [
      {
        label: (
          <>
            {t("TRIP_ID")}
          </>
        ),
        key: "tripId",
        filter: (e: string[], onChange: any) => {
          const tripidList = e.map((itm: string) => ({ label: itm, value: itm }));
                  
          const finalarr = Array.from(new Set(tripidList.map((item) => item.label))).map((label) => {
            return tripidList.find((item) => item.label === label);
          });
        
          const handleChange = (selectedOptions: any) => {
            const tripCountFilter: any = orderListRes?.filter((item: { tripDetails: { tripid: string | null; }; }) =>
            (item?.tripDetails?.tripid ===  selectedOptions?.label)
            );
            setTripCount(tripCountFilter?.length)
            window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.TRIP_ID,  selectedOptions?.label === undefined ? '' : selectedOptions?.label) 
            
            // const selectedValues = Array.isArray(selectedOptions)
            //   ? selectedOptions.map((option: any) => option.label)
            //   : [];
              
            onChange((item: any) => {    
            const list = selectedOptions?.label
            
              // const tripClear: any = {
              //     label: list,
              //     value: list
              // }
              setTripCheckClear(list)
                        
              if (!list) {
                return true; 
              }
        
              // return list.includes(item);
              return list == item;
            });
          };
        
          return (
            <div className={"customeSelectWrapper"}>
              <Select
                closeMenuOnSelect={false}
                placeholder={t("SELECT")}
                onChange={handleChange}
                options={(finalarr as any)}
                hideSelectedOptions={false}
                isClearable={tripCheckClear}
                className="react-select-container multiSelecter"
                classNamePrefix="react-select"
                isSearchable={false}
                    defaultValue={window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.TRIP_ID) !== '' ? {
                  label: window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.TRIP_ID),
                  value: window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.TRIP_ID),
                } : undefined}
                
              />
            </div>
          );
        },
        sorting: false,
      },
      {
        label: (
          <>
            {t("DRIVER_NAME")}
          </>
        ),
        key: "driverName",
        filter: (e: string[], onChange: any) => {
          const drivernameList = e.map((itm: string) => ({ label: itm, value: itm }));
        
          const finalarr = Array.from(new Set(drivernameList.map((item) => item.label))).map((label) => {
            return drivernameList.find((item) => item.label === label);
          });
        
        const handleChange = (selectedOptions: any) => {
          window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME,  selectedOptions?.label === undefined ? '' : selectedOptions?.label)
          const selectedValues = Array.isArray(selectedOptions)
          ? selectedOptions?.map((option: any) => option?.label)
          : [selectedOptions?.label];
        
          onChange((items: any) => {   
            // const driverCountFilter: any = orderListRes?.filter((item: { driverDetails: { fullname: string | null; }; }) =>
            // (item?.driverDetails?.fullname ===  selectedOptions?.label)
            // );   
            // setDriverCount(driverCountFilter?.length)
            setDriverValueClear(items)  
            
            if (!selectedValues ||  selectedValues[0] === undefined) {
              return true; 
            }
        
            return selectedValues.includes(items);
          });
        };

        
          return (
            <div className={"customeSelectWrapper"}>
              <Select
                closeMenuOnSelect={false}
                placeholder={t("SELECT")}
                onChange={handleChange}
                isClearable={driverValueClear}
                options={(finalarr as any)}
                hideSelectedOptions={false}
                className="react-select-container multiSelecter"
                classNamePrefix="react-select"
                isSearchable={false}
            
                defaultValue={window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME) !== '' ? {
                  label: window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME),
                  value: window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME),
                } : undefined}
                
              />
            </div>
          );
        },
        
        sorting: false,
      },
      {
        label: (
          <>
            {t("CUSTOMER_NAME")}
          </>
        ),
        key: "customerName",
        filter: (e: string[], onChange: any) => {
          
          const customernameList = e.map((itm: string) => ({ label: itm, value: itm }));
          

          const finalarr = Array.from(new Set(customernameList.map((item) => item.label))).map((label) => {
            return customernameList.find((item) => item.label === label);
          });
        
          const handleChange = (selectedOptions: any) => {
            const customerCountFilter: any = orderListRes?.filter((item: { customerDetails: { fullname: string | null; }; }) =>
            (item?.customerDetails?.fullname ===  selectedOptions?.label)
            );
            setCustomerCount(customerCountFilter?.length)
            window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME,  selectedOptions?.label === undefined ? '' : selectedOptions?.label )
            // const selectedValues = selectedOptions?.map((option: any) => option.value);
            const selectedValues = Array.isArray(selectedOptions)
          ? selectedOptions?.map((option: any) => option?.label)
          : [selectedOptions?.label];
        
            onChange((items: any) => {     
              setCustomerValueClear(items)
              if (!selectedValues ||  selectedValues[0] === undefined) {
                return true; 
              }
        
              return selectedValues.includes(items); 
            });
          };
        
          return (
            <div className={"customeSelectWrapper"}>
              <Select
                closeMenuOnSelect={false}
                placeholder={t("SELECT")}
                onChange={handleChange}
                options={(finalarr as any)}
                hideSelectedOptions={false}
                isClearable={customerValueClear}
                className="react-select-container multiSelecter"
                classNamePrefix="react-select"
                isSearchable={false}
                defaultValue={window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME) !== '' ? {
                  label: window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME),
                  value: window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME),
                } : undefined}
                // isMulti
              />
            </div>
          );
        },
        sorting: false,
      },
      {
        label: (
          <>
            {t("ORDER_DATE_TIME")}
          </>
        ),
        key: "orderdatetime",
        filter: (itemExpiryDates: any, onChange: any) => {
          const handleDateFilterChange = (selectedDate: Date | null) => {
            if (selectedDate === null) {
              setStartDate(null);
              window.localStorage.removeItem('orderDate');
              onChange(() => true);
            } else {
              setStartDate(selectedDate);
              window.localStorage.setItem('orderDate', moment(selectedDate).toISOString());
              onChange((e: any) => {
                const listDate: any = new Date(e);
                console.log('itemExpiryDates', moment(listDate).format('YYYY-MM-DDTHH:mm'), listDate, selectedDate);
                return moment(listDate).isSame(moment(selectedDate), 'day');
              });
            }
          };
          return (
            <div 
            className="calenderFilterWrapper"
            >
              <div 
              // className="order-date-time"
              >
              <Calender
                  // showTimeSelectOnly={true}
                  // showTimeSelect={true}
                  isClearable={true}
                  selected={window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.PENDING_ORDER_DATETIME) ? window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.PENDING_ORDER_DATETIME) : startDate}
                  dateFormat="d MMM, yyyy"
                  placeholderText="Select"
                  // minTime={checkTimeOfSelectedRegion(timeZone, selectedDate)}
                  // maxTime={new Date(new Date().setHours(23, 59))}
                  // timeIntervals={1}
                  onChange={(e: any) => {
                    console.log('event', e);
                    handleDateFilterChange(e);
                  }}
                />
                <i className="mdi mdi-calendar-blank-outline" />
                {/* <input
                  className="selectFielterDate"
                  type="datetime-local"
                  placeholder="fd"
                  value={startDate}
                  onChange={handleDateFilterChange}
                />

                {startDate !== '' ||  (PendingOrderFilterDate !== '' && PendingOrderFilterDate !== 'null' && PendingOrderFilterDate !== null) ? (
                  <span className="date-placeholder date-placeholder-value">
                  {PendingOrderFilterDate ? moment(PendingOrderFilterDate).format('DD MMM YYYY h:mm A') : moment(startDate).format('DD MMM YYYY h:mm A')}
                </span>
                ) : (
                  <span className="date-placeholder">{t('SELECT')}</span>
                )}
                <i className="mdi mdi-calendar-blank-outline" /> */}
              </div>
            </div>
          );
        },
        
        
        sorting: false,
      },
      {
        label: (
          <>
            {t("COLLECTION_CODE")}
          </>
        ),
        key: "collectioncode",
        filter: false,
        sorting: false,
      },
      {
        label: (
          <>
            {t("ORDER_AMOUNT")}
          </>
        ),
        key: "orderamount",
        filter: false,
        sorting: false,
      },
      {
        label: (
          <>
            {val !== Constant.ORDER_STATUS.FAILED ? t("ACTIONS") : t("STATUS")}
            {/* {t("ACTIONS")} */}
          </>
        ),
        key: "orderstatus",
        filter: false,
        sorting: false,
      },
    ];
  }

  const loadMoreItems = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      pageSizeRef.current += 10;
    }, 2000);
  };
  // const tableContainer = tableContainerRef.current as HTMLDivElement;
  const handleTableScroll = debounce(() => {
    const tableContainer = tableContainerRef1.current;
    if (tableContainer) {
      const isAtBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight;
      if (isAtBottom && orderListRes?.length > pageSizeRef.current) {
        loadMoreItems();
      }
    }
  }, 200);

  useEffect(() => {
    const tableContainer = tableContainerRef1.current; // to be change
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleTableScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleTableScroll);
      }
    };
  }, [handleTableScroll]);

  useEffect(() => {
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    const pageTitleHeight = document.getElementsByClassName("heading")[0] as HTMLElement;
    const tabTitleHeight = document.getElementsByClassName("nav-pills")[0] as HTMLElement;
    const buttonRowHeight = document.getElementsByClassName("addBtn")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
    if (pageTitleHeight) {
      setPageTitleHeight(pageTitleHeight.offsetHeight);
    }
    if (tabTitleHeight) {
      setTabTitleHeight(tabTitleHeight.offsetHeight);
    }
    if (buttonRowHeight) {
      setButtonRowHeight(buttonRowHeight.offsetHeight);
    }
  }, [dimensions]);

  // upload file code start
  const [dimenstionError, setDimenstionError] = useState<boolean>(false);
  const openRefundModal = (e: any, item: any) => {
    setCustomerData(item)
    setShowStatusModal(true);    
  };
  const onRefundConfirm = async () => {
    setRefundBtnLoader(true)
    const params = {
      cusId: customerData?.customerDetails?.customerid,
      paymentId: customerData?.paymentid
    }
   
    dispatch(refundPayment(params)).then((resultAction) => {
        if (refundPayment.fulfilled.match(resultAction)) {
          dispatch(
            showToast({
              show: false,
              message: (resultAction as any)?.payload?.res?.message,
              type: "success",
            })
          );
          setShowStatusModal(false);
          setRefundBtnLoader(false);
          handleTabChangeClick(defaultTabKey)
        } else if (refundPayment.rejected.match(resultAction)) {
          setShowStatusModal(false);
          setRefundBtnLoader(false);
          dispatch(
            showToast({
              show: false,
              message: resultAction?.error?.message,
              type: "error",
            })
          );
        }
    })

   
    // dispatch(
    //   showToast({
    //     show: false,
    //     message: t("Amount £ 14.00 Refunded to Ralph Edwards is initiated."),
    //     type: "success",
    //   })
    // );

  }
  
  const onFilteredItemsChange = (item: any) => {
    console.log(item, '0000');
    console.log('placeHolder ---> ', placeHolder)
    // if(!placeHolder){
    //   setFilterData(item.length);
    // }
    setFilterData(item.length);
  }
 
  return (<>
    <Helmet>
      <title>Orders | Scooply Admin Panel</title>
      <meta name="description" content="See order history, upcoming and live orders, and more via the Scooply admin panel." />
    </Helmet>
    <OrderManagementComponent
      filterData={filterData}
      onFilteredItemsChange={onFilteredItemsChange}
      columns={columns}
      backButtonShow={backButtonShow}
      orderListData={orderListResponce}
      onTableFilterChange={onTableFilterChange}
      placeHolderShimer={placeHolder}
      dimensions={dimensions}
      headerHeight={headerHeight}
      pageTitleHeight={pageTitleHeight}
      tabTitleHeight={tabTitleHeight}
      buttonRowHeight={buttonRowHeight}
      searchValue={searchValue}
      searchFilterClearData={searchFilterClear}
      loading={loading}
      tableContainerRef1={tableContainerRef1}
      tableContainerRef2={tableContainerRef2}
      tableContainerRef3={tableContainerRef3}
      tableContainerRef4={tableContainerRef4}
      itemsPerPage={pageSizeRef.current}
      handleTableBodyScroll={handleTableScroll}
      dimenstionError={dimenstionError}
      setDimenstionError={setDimenstionError}
      showStatusModal={showStatusModal}
      onProcced={onRefundConfirm}
      closeStatusModal={closeStatusModal}
      openRefundModal={openRefundModal}
      handleClick={handleTabChangeClick}
      noShowCount={noShowCount}
      pastOrderscount={pastOrderscount}
      pendingOrdersCount={pendingOrdersCount}
      paymentFailedCount={paymentFailedCount}
      defaultFilterData={defaultFilterData}
      currentTab={currentTab}
      defaultTabKey={defaultTabKey}
      TripID={TripID}
      CustomerName={CustomerName}
      DateOrder={DateOrder}
      DriverName={DriverName}
      customerCount={customerCount}
      tripCount={tripCount}
      driverCount={driverCount}
      refundBtnLoader={refundBtnLoader}
      customerData={customerData}
      filteredEventData={filteredEventData}
      tabChangeBool={tabChangeBool}
    />
  </>);
};
export default OrderManagementContainer;