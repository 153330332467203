import instance from "../../../helpers/axios";
import { GET_DRIVER, CREATE_DRIVER, UPDATE_DRIVER, DELETE_DRIVER, ENABLE_DISBLED_DRIVER, CREATE_TRIP, GET_TRIP_LIST } from "../../../api/endPoint";
import { AddDriverParams, CreateTripParams, EnableDriverParams, UpdateDriverParams } from "../../../interface/driverInterface";


/**
 * Retrieves the driver information from the server.
 * @returns A Promise that resolves to the driver information.
 */
const getDriver = (queryParams: any) => {
    return instance.get(GET_DRIVER, { params: queryParams })
}

/**
 * Creates a new driver using the provided data.
 * @param {AddDriverParams} data - The parameters for creating a new driver.
 * @returns A Promise that resolves to the response from the server.
 */
const createDriver = (data: AddDriverParams) => {
   return instance.post(CREATE_DRIVER, {
     driverName: data?.driverName,
     phoneNumber: data?.phone
    }); 
};

/**
 * Updates the driver information using the provided data.
 * @param {UpdateDriverParams} data - The data object containing the updated driver information.
 * @returns A promise that resolves to the updated driver object.
 */
const updateDriver = (data: UpdateDriverParams) => {
    return instance.put(UPDATE_DRIVER, {
        id: data?._id,
        phoneNumber: data?.phone,
        driverName: data?.driverName
    });
}

/**
 * Deletes a driver with the specified driverId.
 * @param {string} driverId - The ID of the driver to delete.
 * @returns A promise that resolves to the result of the delete request.
 */
const deleteDriver = (driverId: string) => {
    return instance.put(`${DELETE_DRIVER}${driverId}`)
}

/**
 * Enables or disables a driver based on the provided data.
 * @param {EnableDriverParams} data - The data object containing the driver status and ID.
 * @returns A promise that resolves with the result of the PUT request.
 */
const enableDisbledDriver = (data: EnableDriverParams) => {
    return instance.put(ENABLE_DISBLED_DRIVER , {
        status: data?.status,
        id: data?._id,
    })
}


/**
 * Creates a new trip using the provided data.
 * @param {CreateTripParams} data - The parameters for creating the trip.
 * @returns A Promise that resolves to the response from the server.
 */
const createTrip = (data: CreateTripParams) => {
    return instance.post(CREATE_TRIP, {
        driverid: data?.driverid,
        tripstartdate: data?.tripstartdate,
        tripstarttime: data?.tripstarttime,
        triparea: data?.triparea,
        servingradius: data?.servingradius,
        lat: data?.lat,
        long: data?.long,
        tripid: data?.tripid,
        timezone: data?.timezone
    })
}

/**
 * Retrieves the trip list for a specific driver.
 * @param {string} driverId - The ID of the driver.
 * @returns A Promise that resolves to the trip list.
 */
const getTrip = (driverId: string) => {
    return instance.get(`${GET_TRIP_LIST}${driverId}`)
}


const driverService = {
    getDriver,
    createDriver,
    updateDriver,
    deleteDriver,
    enableDisbledDriver,
    createTrip,
    getTrip
}

export default driverService