import React, { useEffect, useState } from "react";

import './notificationComponent';
import NotificationComponent from './notificationComponent';

import * as notificationItem from "../../api/notification.json";
import useWindowDimension from '../../components/hooks/useWindowDimension';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../interface/driverInterface";
import { getNotifications, readAllNotification, readNotifications } from "../../store/features/notificationManagement/notificationSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getOrderDetail } from "../../store/features/orderManagement/orderManagementSlice";

const NotificationContainer: React.FC = (props) => {
  const navigate = useNavigate();
  const dimensions = useWindowDimension();
  const [read, setRead] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const notificationData = useSelector((state: any) => state.notification.notificationData);
  const orderDetailData = useSelector((state: any) => state.order.orderDetailResponse);
  const [placeholder, setPlaceholder] = useState(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const notificationItemData = notificationItem;
  const [notificationListData, setNotificationListData] = useState<any[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setPlaceholder(false);
    }, 2000);
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
  }, [setNotificationListData]);


  useEffect(() => {
    const authToken = window.localStorage.getItem('accessToken');
    dispatch(getNotifications(authToken)).then((resultAction) => {
      if (getNotifications.fulfilled.match(resultAction)) {
        console.log(`resultAction ----->>>>`, resultAction);
      } else if (getNotifications.rejected.match(resultAction)) {
        console.log(`resultAction ----->>>>`, resultAction);
      }
    })
    
  }, [])
  console.log(`notificationData ----->>>>`, notificationData);
  useEffect(() => {
    setNotificationListData(notificationData)
  }, [notificationData])

  const onReadClick = () => {
    setRead(true);
    const authToken = window.localStorage.getItem('accessToken');
    dispatch(readAllNotification(authToken)).then((resultAction) => {
      if (readAllNotification.fulfilled.match(resultAction)) {
        console.log(`resultAction ----->>>>`, resultAction);
      } else if (readAllNotification.rejected.match(resultAction)) {
        console.log(`resultAction ----->>>>`, resultAction);
      }
    })
  };

  const readNotification = async (notification: any) => {
    dispatch(readNotifications(notification._id))
    if (notification?.navigate?.title === 'PAST_ORDER' || notification?.navigate?.title === 'NOSHOW_ORDER') {
      dispatch(getOrderDetail(notification.navigate.id));
      navigate("/order-management/details", { state: orderDetailData })
    }
    if (notification?.navigate?.title === 'Trip' || notification?.navigate?.title === 'Driver') {
      navigate('/driver-management');
    }
    if (notification?.navigate?.title === 'Customer') {
      navigate('/customer-management');
    }
    if (notification?.navigate?.title === 'Product') {
      navigate('/product-management');
    }
  }

  return (<>
    <Helmet>
      <title>Notifications | Scooply Admin Panel</title>
      <meta name="description" content="See all your Scooply notifications in one place." />
    </Helmet>
    <NotificationComponent
      read={read}
      placeholder={placeholder}
      onReadClick={onReadClick}
      dimensions={dimensions}
      headerHeight={headerHeight}
      notificationListData={notificationListData}
      readNotification={readNotification}
    />
  </>);
};
export default NotificationContainer;