import React, { FC } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../customButton/customButton";

interface ConfirmationModalProps {
  iconColor: string;
  modalTitle: string;
  isFirstButton: boolean;
  isSecondButton: boolean;
  isUnderline: boolean;
  isBold: boolean;
  isModelIcon: boolean;
  leftdisabled: boolean;
  rightdisabled: boolean;
  rightButtonClick: () => void;
  onProcced: () => void;
  onHide: () => void;
  modalContent: boolean;
  loaderButtonShow: boolean;
  className?: string;
  showModal: boolean;
  iconCricle?: string;
  modalDescription?: string;
  messageLink?: string;
  messagebold: string;
  leftBtnTitle?: string;
  leftBtnIcon?: string;
  leftBtnClass?: string;
  rightBtnTitle?: string;
  rightBtnIcon?: string;
  rightBtnClass?: string;
  children?: React.ReactNode;
}

const ConfirmationModal: FC<ConfirmationModalProps> = (props) => {
  const {
    iconColor,
    modalTitle,
    modalDescription,
    isFirstButton,
    isSecondButton,
    isUnderline,
    isBold,
    isModelIcon,
    leftdisabled,
    rightdisabled,
    rightButtonClick,
    onProcced,
    onHide,
    modalContent,
    loaderButtonShow,
    iconCricle,
    className,
    messageLink,
    messagebold,
    leftBtnTitle,
    leftBtnIcon,
    leftBtnClass,
    rightBtnTitle,
    rightBtnIcon,
    rightBtnClass
  } = props;

  return (
    <Modal
      className={"confirm-modal " + className}
      show={props.showModal}
      onHide={onHide}
      centered
    >
      <Modal.Body>
        {isModelIcon && (
          <div className={"icon-cricle-style " + iconCricle}>
            <i className={"icon " + iconColor} />
          </div>
        )}
        <div className="title-icon-wrapper">
          <div className="modal-title">
            <h4>{modalTitle}</h4>
          </div>
        </div>

        {modalContent && (
          <p className="modal-content">
            {modalDescription}{" "}
            {isUnderline && <u>{messageLink}</u>}{" "}
            {isBold && <b dangerouslySetInnerHTML={{ __html: messagebold }} />}?
          </p>
        )}
        {props?.children}
      </Modal.Body>
      <div className="main-btn-div">
        {isFirstButton && (
          <CustomButton
            type="submit"
            title={leftBtnTitle}
            disabled={leftdisabled}
            icon={leftBtnIcon}
            className={leftBtnClass}
            onClick={onProcced}
            loading={loaderButtonShow}
          />
        )}
        {isSecondButton && (
          <CustomButton
            type="button"
            title={rightBtnTitle}
            icon={rightBtnIcon}
            disabled={rightdisabled}
            className={rightBtnClass}
            onClick={rightButtonClick}
          />
        )}
      </div>
    </Modal>
  );
};

ConfirmationModal.defaultProps = {
  modalContent: true,
};

export default ConfirmationModal;