import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
interface LogoProps {
}

const BackButton: React.FC<LogoProps> = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => navigate(-1);
    return (
        <div className="back-row-style">
            <button className="back-button-style" onClick={handleClick}>
                <i className='bx bx-arrow-back'></i> {t("BACK")}
            </button>
        </div>
    );
};
export default BackButton;
