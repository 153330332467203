/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { CSmartTable } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import * as orderItemList from '../../../api/orderStatusList.json'
import EmptyCell from "../../../components/emptyCell/emptyCell";
import ConfirmationModal from '../../confirmationModal/confirmationModal';
import CustomLoadingHTML from "../../../components/customLoadingHTML/customLoadingHTML";

import Constant from '../../../util/constant';
import moment from "moment";

interface OrderStatusListProps {
  orderListData: any[]; // Update with actual data type
  placeHolderShimer: boolean;
  onTableFilterChange: (item: any) => void;
  emptyDataTitle: string;
  NoDataTitle: string;
  NoDataDesc: string;
  searchClearTitle: string;
  noDataFoundText: string;
  searchValueData: string;
  dimensions: any;
  loading: boolean;
  tableContainerRef: any; // Update with actual data type
  itemsPerPage: number,
  headerHeight: number,
  pageTitleHeight: number,
  tabTitleHeight: number,
  buttonRowHeight: number,
  searchValue: any,
  noDataImage: string,
  searchFilterClear: any,
  handleTableBodyScroll: () => void,
  dimenstionError: boolean
  setDimenstionError: React.Dispatch<React.SetStateAction<boolean>>;

  showStatusModal: boolean;
  openRefundModal: (e: any, item: any) => void;
  onProcced: () => void;
  closeStatusModal: () => void;
  status: any,
  columns: any,
  defaultFilterData: any,
  currentTab: string,
  refundBtnLoader: boolean,
  customerData: any
  onFilteredItemsChange: (item: any) => void,
}

/**
 * A functional component that represents the Product Management page.
 * @param {ProductManagementProps} props - The props for the component.
 * @returns The rendered Product Management page.
 */
const OrderStatusListComponent: React.FC<OrderStatusListProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  var orderItemData = orderItemList;
  const {
    headerHeight,
    pageTitleHeight,
    tabTitleHeight,
    buttonRowHeight,
    orderListData,
    onTableFilterChange,
    NoDataTitle,
    emptyDataTitle,
    noDataFoundText,
    NoDataDesc,
    searchClearTitle,
    dimensions,
    placeHolderShimer,
    searchFilterClear,
    searchValue,
    noDataImage,
    loading,
    itemsPerPage,
    tableContainerRef,
    handleTableBodyScroll,
    searchValueData,

    showStatusModal,
    onProcced,
    closeStatusModal,
    openRefundModal,
    status,
    columns,
    defaultFilterData,
    currentTab,
    refundBtnLoader,
    customerData,
    onFilteredItemsChange
  } = props;


  // useEffect(() => {
  //   setTimeout(() => {
  //     // setCustomerName('');
  //   }, 0);
  // }, [customername, orderamount]);
  useEffect(() => {
    console.log(`searchValue11 ----->>>>`, searchValue);
  }, [searchValue])
  return (
    <div className="no-show-list">
      <div className={searchValue?.length > 200 ? "addBtn height" : "addBtn"}>

        {searchValue?.length > 0 &&
          <button
            onClick={searchFilterClear}
            className="clear-btn-style"
          >
            <i className='mdi mdi-window-close'></i>
          </button>
        }
        {searchValue?.length > 200 &&
          <span className='search-error-style'>{t('ALLOWED_MAX_200_CHARACTERS')}</span>
        }
      </div>

      <CSmartTable
        cleaner
        clickableRows
        columns={columns(status)}
        onFilteredItemsChange={(item) => {
          onFilteredItemsChange(item);
        }}
        columnFilter={true}
        columnFilterValue={{
          customerName: (val: any) =>
            //  defaultFilterData !== undefined ? defaultFilterData?.fullname === val : true,  
            window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME) !== '' ? window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME) === val : true,
          driverName: (val: any) =>
            window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME) !== '' ? window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERNAME) === val : true,
          tripId: (val: any) =>
            window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.TRIP_ID) !== '' ? window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.TRIP_ID) === val : true,
          orderdatetime: (val: any) =>
          window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.PENDING_ORDER_DATETIME) ? moment(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.PENDING_ORDER_DATETIME)).isSame(moment(val), 'day') : true,
        }}
        items={orderListData}
        // items={orderListData?.filter((itm: any) => itm?.actions?.includes(status))}
        itemsPerPage={itemsPerPage}
        onRowClick={(item) => { navigate("/order-management/details", { state: item }) }}
        // onRowClick={(item) => { navigate("/order-management/details", { state: { item, tab: currentTab }  }) }}
        scopedColumns={{
          tripId: (item: any) => {
            const className = item?.status === "deactive" ? "disableTd" : "";
            return (
              <td className={className} >
                <div className="td-wrapper product-style">
                  {placeHolderShimer && <div className="Shimer"></div>}
                  <div className="productName">
                    {item?.tripId || '-'}
                  </div>
                </div>
              </td>
            );
          },
          driverName: (item: any) => {
            return (
              <td
                className={item?.status === "deactive" ? "disableTd" : ""}
              >
                <div className="td-wrapper">
                  {placeHolderShimer && <div className="Shimer"></div>}
                  {item?.driverName || '-'}
                </div>
              </td>
            );
          },
          customerName: (item: any) => {
            return (
              <td
                className={item?.status === "deactive" ? "disableTd" : ""}
              >
                <div className="td-wrapper">
                  {placeHolderShimer && <div className="Shimer"></div>}
                  {item?.customerName || '-'}
                </div>
              </td>
            );
          },
          orderdatetime: (item: any) => {
            return (
              <td
                className={item.status === "deactive" ? "disableTd" : ""}
              >
                <div className="td-wrapper">
                  {placeHolderShimer && <div className="Shimer"></div>}
                  {moment(item?.orderdatetime).format('DD MMM YYYY h:mm A') || '-'}
                </div>

              </td>
            );
          },
          collectioncode: (item: any) => {
            return (
              <td
                className={item.status === "deactive" ? "disableTd" : ""}
              >
                <div className="td-wrapper">
                  {placeHolderShimer && <div className="Shimer"></div>}
                  {item?.collectioncode || '-'}
                </div>

              </td>
            );
          },
          orderamount: (item: any) => {
            const orderAmount = typeof item?.orderamount === 'number' ? item?.orderamount : 0;
            const fixedAmount = orderAmount?.toFixed(2);
            return (
              <td
                className={item.status === "deactive" ? "disableTd" : ""}
              >
                <div className="td-wrapper">
                  {placeHolderShimer && <div className="Shimer"></div>}
                  £ {fixedAmount || '-'}
                </div>

              </td>
            );
          },
          orderstatus: (item: any) => {
            return (
              <td
                className={item?.status === "deactive" ? "disableTd" : ""}
              >
                <div className="action-wrapper">
                  {placeHolderShimer && (
                    <div className="Shimer"></div>
                  )}
                  <div
                    onClick={(e) => {
                      openRefundModal(e, item)
                      e.stopPropagation();
                    }} className={
                      item?.isrefunded ? 'refunded-btn status-btn-style' :
                        item?.orderstatus === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW || item?.orderstatus === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW
                          ? 'refund-btn status-btn-style'
                          // : item?.isrefunded === Constant.ORDER_API_STATUS.REFUNDED
                          //   ? 'refunded-btn status-btn-style'
                          : item?.orderstatus === Constant.ORDER_API_STATUS.PAYMENT_FAILED
                            ? 'failed-btn status-btn-style'
                            : ' status-btn-style'
                    }>
                    {item?.isrefunded ? 'Refunded' : item?.orderstatus === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW || item?.orderstatus === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW ? 'Refund' : item?.orderstatus === Constant.ORDER_API_STATUS.PAYMENT_FAILED ? 'Failed' : '-'}

                  </div>

                </div>
              </td>
            );
          },
        }}
        tableFilter={true}
        tableFilterPlaceholder={t("SEARCH_BY_COLLECTION_CODE_CUSTOMER_NAME_OR_TRIP_ID")}
        onTableFilterChange={onTableFilterChange}
        // tableFilterValue={searchValue ? searchValue : ""}
        tableFilterValue={!(window.localStorage.getItem('searchValue') as string) ? "" : (window.localStorage.getItem('searchValue') as string)}
        tableProps={{
          className: placeHolderShimer ? "product-table shimer-row-style" : "product-table",
          responsive: true,
          striped: false,
          hover: false,
        }}
        noItemsLabel={
          !placeHolderShimer && (
            <EmptyCell
              emptyItems={orderListData}
              noDataTitle={NoDataTitle}
              noDataAddButton={false}
              noDataDesc={NoDataDesc}
              emptyDataTitle={emptyDataTitle}
              noDataFoundText={noDataFoundText}
              noDataImage={noDataImage}
              searchClearTitle={searchClearTitle}
              searchClearButton={searchFilterClear}
              searchValueData={searchValueData}
            />
          )
        }
        tableBodyProps={{
          className: "align-middle table-body-scroll",
          style: {
            maxHeight: dimensions.height - headerHeight - pageTitleHeight - tabTitleHeight - buttonRowHeight - 250, overflow: 'auto'
          },
          // ref: tableContainerRef,
          onScroll: handleTableBodyScroll,
        }}
        ref={tableContainerRef}

      />
      {loading && <CustomLoadingHTML />}

      {/* Disable Model Message Model */}
      <ConfirmationModal
        className={"deactivate refund-modal"}
        isModelIcon={true}
        onHide={closeStatusModal}
        iconColor={"mdi mdi-toggle-switch-off-outline "}
        modalTitle={t("REFUND")}
        modalDescription={t("ARE_YOU_SURE_YOU_WANT_TO_REFUND")}
        isUnderline={false}
        showModal={showStatusModal}
        leftBtnTitle={t("YES_REFUND")}
        isFirstButton={true}
        leftBtnClass={"confirm-btn"}
        onProcced={onProcced}
        rightBtnClass="cancel-btn"
        isSecondButton={true}
        rightBtnTitle={t("CANCEL")}
        rightButtonClick={closeStatusModal}
        messagebold={`£ ${customerData?.orderamount} <span style=' font-weight:400'>to</span> "${customerData?.customerDetails?.fullname}"`}
        isBold={true}
        leftdisabled={false}
        rightdisabled={false}
        modalContent={true}
        loaderButtonShow={refundBtnLoader}
      />
    </div>
  );
};

export default OrderStatusListComponent;
