import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Pages404: React.FC = (props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="account-pages nofoundPage">
        <Container>
          <Row className="justify-content-center">
            <Col lg="12" className="text-center d-flex justify-content-center pageNotFoundLogo">
              {/* <img src='https://res.cloudinary.com/https-www-nimblechapps-com/image/upload/v1696225410/Tapx-Web/Project-images/rxsbrlw5eeezqefymvmp.svg' alt="App Logo" className="img-fluid" /> */}
            </Col>
            <br />
            <h1 className="text-center text-notfound"> {t("PAGE_NOT_FOUND")}</h1>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Pages404;
