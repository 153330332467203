import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { AddDriverParams, createDriverRes, CreateTripParams, createTripRes, DeleteDriverRes, DeleteErrorMessage, DriverData, EnableDriverParams, enableDriverRes, EnableErrorMessage, TripData, UpdateDriverParams, UpdateDriverRes } from "../../../interface/driverInterface";
import driverService from "../services/driverService";

/**
 * Async thunk function that fetches the list of drivers from the server.
 * @returns A promise that resolves to the response data containing the list of drivers.
 */
export const driverListResponce = createAsyncThunk("driver/list", async (queryParams: any) => {
    const res = await driverService.getDriver(queryParams);
    return res?.data;
});

export const createDriverResponce =  createAsyncThunk("driver/create", async (data: AddDriverParams) => {
    const res = await driverService.createDriver(data);
    return res;
});

export const updateDriverResponce = createAsyncThunk("driver/update", async (data: UpdateDriverParams) => {
    const res = await driverService.updateDriver(data);
    return {res, params:data};
});

export const enableDisbledDriverResponce = createAsyncThunk("driver/enable", async (data: EnableDriverParams) => {
    const res = await driverService.enableDisbledDriver(data);
    return {res, params:data};
});


export const deleteDriverResponce = createAsyncThunk("driver/delete", async (data: string) => {
    const res = await driverService.deleteDriver(data);    
    return {res, params:data};
});


export const createTripResponce = createAsyncThunk("trip/create", async (data: CreateTripParams) => {
  const res = await driverService.createTrip(data);
  return res;
})

export const tripListResponce = createAsyncThunk("trip/list", async (data: string) => {
  const res = await driverService.getTrip(data);
  return res?.data;
});


interface AuthState {
    driverResponce:  any,
    createDriverRes: AxiosResponse<string, createDriverRes>,
    createDriverError:  createDriverRes,
    updateDriverRes: AxiosResponse<string, UpdateDriverRes>,
    updateDriverError: UpdateDriverRes,
    isLoading: boolean,
    deleteDriverRes: AxiosResponse<string, DeleteDriverRes>
    deleteDriverError: DeleteErrorMessage,
    enableDisbledDriverRes: AxiosResponse<string, enableDriverRes>
    enableDriverError: EnableErrorMessage
    createTripRes: AxiosResponse<string, createTripRes>,
    createTripError:  createTripRes,
    tripResponce: TripData[],
    queryParams: any,
    totalDrivers: any
  }

const driversSlice = createSlice({
    name: "driver",
    initialState: {
      driverResponce: [],
      createDriverRes: {},
      createDriverError: {},
      updateDriverRes: {},
      updateDriverError: {},
      deleteDriverRes: {},
      deleteDriverError: {},
      createTripRes: {},
      createTripError:{},
      tripResponce: [{}],
      queryParams: {page: 1, doc: 10},
      totalDrivers: '',
      isLoading: false
    } as AuthState,
    reducers: {

      setQueryParams: (state, action) => {
        state.queryParams = action.payload;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(driverListResponce.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(driverListResponce.fulfilled, (state, action) => {  
          console.log('action.payload', action.payload);
                  
          state.isLoading = false;
          state.totalDrivers = action.payload.totalDriver
          // state.driverResponce = action.payload;
          if (state.queryParams.page === 1) state.driverResponce = action.payload.driverData
          else state.driverResponce = [...state.driverResponce, ...action.payload.driverData];
        })
        .addCase(driverListResponce.rejected, (state) => {
          state.isLoading = false;
        });

// create

      builder
        .addCase(createDriverResponce.pending, (state) => {
          state.isLoading = true;
         })
        .addCase(createDriverResponce.fulfilled, (state, action) => {
          state.isLoading = false;
          state.createDriverRes = action.payload
        })
        .addCase(createDriverResponce.rejected, (state, action) => {
         state.isLoading = false;
         state.createDriverError = action?.error
        });

 // update

    builder
    .addCase(updateDriverResponce.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(updateDriverResponce.fulfilled, (state, action) => {
      state.isLoading = false;
      state.updateDriverRes = action.payload.res
      state.driverResponce = state.driverResponce?.map((item: any) => {
        if (item?._id === action?.payload?.params?._id) {         
          return { ...item, fullname: action.payload.params.driverName, phonenumber: action.payload.params.phone }     
        }
        return item;
      })
    })
    .addCase(updateDriverResponce.rejected, (state, action) => {
      state.isLoading = false;
      state.updateDriverError = action?.error
    });

// delete
      builder
    .addCase(deleteDriverResponce.pending, (state) => {
        state.isLoading = true;
        })
    .addCase(deleteDriverResponce.fulfilled, (state, action) => {    
        
        state.isLoading = false;
        state.deleteDriverRes = action.payload.res
        state.driverResponce = state.driverResponce?.filter((item: any) => item?._id !== action?.payload?.params)
        })
   .addCase(deleteDriverResponce.rejected, (state, action) => {
        state.isLoading = false;
        state.deleteDriverError = action?.error
});  


// enbale-disbled
        builder
     .addCase(enableDisbledDriverResponce.pending, (state) => {
        state.isLoading = true;
        })
     .addCase(enableDisbledDriverResponce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.enableDisbledDriverRes = action.payload.res
        state.driverResponce = state.driverResponce.map((item: any) => {        
        if (item._id === action.payload?.params?._id) {            
          return { ...item, status: action.payload?.params?.status };
        }
        return item;
      });

    })
    .addCase(enableDisbledDriverResponce.rejected, (state, action) => {
       state.isLoading = false;
       state.enableDriverError = action?.error
      });  


// create Trip

     
builder
.addCase(createTripResponce.pending, (state) => {
  state.isLoading = true;
 })
.addCase(createTripResponce.fulfilled, (state, action) => {
  state.isLoading = false;
  state.createTripRes = action.payload
})
.addCase(createTripResponce.rejected, (state, action) => {
 state.isLoading = false;
 state.createTripError = action?.error
});


// trip List

builder
.addCase(tripListResponce.pending, (state) => {
  state.isLoading = true;
})
.addCase(tripListResponce.fulfilled, (state, action) => {
  
  state.isLoading = false;
  state.tripResponce = action.payload;
})
.addCase(tripListResponce.rejected, (state) => {
  state.isLoading = false;
});

    },
  });

  export const { setQueryParams } = driversSlice.actions

export default driversSlice.reducer;