import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import { debounce } from 'lodash';

import "./orderStatusDetailsComponent";
import OrderStatusDetailsComponent from './orderStatusDetailsComponent';

import * as orderDetailsItem from '../../../api/orderStatusDetails.json';
import useWindowDimension from "../../../components/hooks/useWindowDimension";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../store/features/toast/toastSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../interface/driverInterface";
import { getAllOrders, refundPayment } from "../../../store/features/orderManagement/orderManagementSlice";
import Constant from "../../../util/constant";


const OrderStatusDetailsContainer: React.FC = (props) => {
  const { t } = useTranslation();
  const pageSizeRef = useRef(10);
  const location = useLocation();
  const orderListRes = useSelector((state: any) => state?.order?.ordersListResponce?.data)
  const dispatch = useDispatch<AppDispatch>();
  const orderDetailsResponce = location?.state;
  const producDetails = location?.state?.product;  
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  var orderDetailsItemData = orderDetailsItem;
  const [orderDetailsListData,] = useState<any[]>(orderDetailsItemData.data);
  const dimensions = useWindowDimension();
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [backButtonHeight, setBackButtonHeight] = useState<number>(0);
  const [topCardRowHeight, setTopCardRowHeight] = useState<number>(0);
  const [refundBtnLoader, setRefundBtnLoader] = useState<boolean>(false);


  
  const columns = [
    {
      label: (
        <>
          {t("PRODUCT_NAME")}
        </>
      ),
      key: "productname",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("QUANTITY")}
        </>
      ),
      key: "count",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("AMOUNT")}
        </>
      ),
      key: "total",
      filter: false,
      sorting: false,
    },
  ];

  // const tableContainer = tableContainerRef.current as HTMLDivElement;
  const handleTableScroll = debounce(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      const isAtBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight;
      if (isAtBottom && orderDetailsListData?.length > pageSizeRef.current) {
      }
    }
  }, 200);

  const refundStatus = orderListRes?.filter((item: any) =>  {
    if (item?.collectioncode === orderDetailsResponce?.collectioncode) {
        return item?.isrefunded
    }
    // return item
  })
  

  // const handleTabChangeClick = async () => {    
  //     dispatch(getAllOrders(Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW)).then((resultAction: any) => {
  //       if (getAllOrders.fulfilled.match(resultAction)) { 
  //       } else if (getAllOrders.rejected.match(resultAction)) {
  //       }
  //     })
    
  // }

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleTableScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleTableScroll);
      }
    };
  }, [handleTableScroll]);

  useEffect(() => {
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    const backButtonHeight = document.getElementsByClassName("back-row-style")[0] as HTMLElement;
    const topCardRowHeight = document.getElementsByClassName("top-table-style")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
    if (backButtonHeight) {
      setBackButtonHeight(backButtonHeight.offsetHeight);
    }
    if (topCardRowHeight) {
      setTopCardRowHeight(topCardRowHeight.offsetHeight);
    }
  }, []);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const closeStatusModal = () => setShowStatusModal(false);
  const openRefundModal = () => {
    setShowStatusModal(true);
  };
  const onRefundConfirm = async () => {
    setRefundBtnLoader(true)
    const params = {
      cusId: orderDetailsResponce?.customerDetails?.customerid,
      paymentId: orderDetailsResponce?.paymentid
    }
   
    dispatch(refundPayment(params)).then((resultAction) => {
        if (refundPayment.fulfilled.match(resultAction)) {
          // handleTabChangeClick();
          setTimeout(() => {
            
            dispatch(
              showToast({
                show: false,
                message: (resultAction as any)?.payload.res.message,
                type: "success",
              })
            );
          }, 1000);
          setShowStatusModal(false);
          setRefundBtnLoader(false);
        } else if (refundPayment.rejected.match(resultAction)) {
          setShowStatusModal(false);
          setRefundBtnLoader(false)
          dispatch(
            showToast({
              show: false,
              message: resultAction?.error?.message,
              type: "error",
            })
          );
        }
    })
    
    // setShowStatusModal(false);
    // dispatch(
    //   showToast({
    //     show: false,
    //     message: t("Amount £ 14.00 Refunded to Ralph Edwards is initiated."),
    //     type: "success",
    //   })
    // );

  }


  return (<>
    <Helmet>
      <title>Orders | Scooply Admin Panel</title>
      <meta name="description" content="See order history, upcoming and live orders, and more via the Scooply admin panel." />
    </Helmet>

    <OrderStatusDetailsComponent
      columns={columns}
      orderDetailsListData={producDetails}
      noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1698916951/Scooply/web/project-image/driver.svg'}
      emptyDataTitle={'No transactions to show'}
      noDataFoundText={t('THERE_ARE_NO_ANY_ORDERS_TO_SHOW_YET')}
      dimensions={dimensions}
      headerHeight={headerHeight}
      backButtonHeight={backButtonHeight}
      topCardRowHeight={topCardRowHeight}
      tableContainerRef={tableContainerRef}
      itemsPerPage={pageSizeRef.current}
      handleTableBodyScroll={handleTableScroll}
      orderDetailsResponce={orderDetailsResponce}

      showStatusModal={showStatusModal}
      onProcced={onRefundConfirm}
      closeStatusModal={closeStatusModal}
      openRefundModal={openRefundModal}
      refundBtnLoader={refundBtnLoader}
      refundStatus={refundStatus}
    />
  </>);
};

export default OrderStatusDetailsContainer;

