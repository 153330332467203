/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from "react";
import { CSmartTable } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import { DriverData } from "../../interface/driverInterface";

import AddEditDrivers from './addEditDriver/addEditDrivers';
import EmptyCell from "../../components/emptyCell/emptyCell";
import CustomLoadingHTML from "../../components/customLoadingHTML/customLoadingHTML";
import ConfirmationModal from '../../components/confirmationModal/confirmationModal';
import CustomToggleButton from "../../components/customToggleButton/customToggleButton";
import { useNavigate } from 'react-router-dom';
import Constant from "../../util/constant";
import moment from "moment";

interface DriverManagementProps {
  showAddEditDriverModal: boolean;
  closeAddEditModal: () => void;
  addDriverModal: any;
  driverData: any[]; // Update with actual data type
  columns: any[]; // Update with actual data type
  placeHolderShimer: boolean;
  onUpdateDriver: (e: Event, item: any) => void; // Update with actual data type
  onSlideSwitch: (e: any, item: any) => void; // Update with actual data type
  openDeleteDriverModal: (e: any, item: any) => void; // Update with actual data type
  onTableFilterChange: (item: any) => void;
  NoDataTitle: string;
  NoDataDesc: string;
  onHide: () => void;
  showStatusModal: boolean;
  onProcced: () => void;
  closeDeactiveModal: () => void;
  showDeleteDriverModal: boolean;
  onDeleteDriver: () => void;
  closeDeleteDriverModal: () => void;
  noDataAddButtonTitle: string;
  EmptyDataAddButton: () => void;
  driverAddEditFunction: (form: any) => void;
  dimensions: any;
  loading: boolean;
  tableContainerRef: any; // Update with actual data type
  itemsPerPage: number,
  handleSubmitDriver: any,
  numberValue: any,
  setNumberValue: any,
  defaultNumber: any,
  updatedNumber: any,
  setUpdatedNumber: any,
  phoneNumbers: string,
  phoneTouched: boolean,
  setPhoneTouched: any,
  setPhoneNumbers: any,
  setphoneNumberError: any,
  headerHeight: any,
  pageTitleHeight: any,
  buttonRowHeight: any,
  showTypeModal: any,
  driverValue: any,
  searchValue: any,
  noDataImage: string,
  searchFilterClear: any,
  itemName: any,
  handleTableBodyScroll: () => void,
  emptyDataTitle: string,
  noDataFoundText: string,
  searchValueData: string,
  searchClearTitle: string,
  addAndEditLoader: boolean,
  loaderButtonShow: boolean,
  setFullName: any,
  fullname: any,
  enableBtnLoader: boolean
}



/**
 * A functional component that represents the Driver Management page.
 * @param {DriverManagementProps} props - The props for the component.
 * @returns The rendered Driver Management page.
 */
const DriverManagementComponent: React.FC<DriverManagementProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    headerHeight,
    pageTitleHeight,
    buttonRowHeight,
    showAddEditDriverModal,
    closeAddEditModal,
    addDriverModal,
    driverData,
    columns,
    onUpdateDriver,
    onSlideSwitch,
    openDeleteDriverModal,
    onTableFilterChange,
    NoDataTitle,
    NoDataDesc,
    onHide,
    showStatusModal,
    onProcced,
    closeDeactiveModal,
    showDeleteDriverModal,
    onDeleteDriver,
    closeDeleteDriverModal,
    noDataAddButtonTitle,
    EmptyDataAddButton,
    driverAddEditFunction,
    dimensions,
    handleSubmitDriver,
    numberValue,
    setNumberValue,
    phoneNumbers,
    phoneTouched,
    setPhoneTouched,
    setPhoneNumbers,
    setphoneNumberError,
    showTypeModal,
    driverValue,
    placeHolderShimer,
    searchFilterClear,
    searchValue,
    noDataImage,
    itemName,
    loading,
    itemsPerPage,
    tableContainerRef,
    handleTableBodyScroll,
    emptyDataTitle,
    noDataFoundText,
    searchValueData,
    searchClearTitle,
    addAndEditLoader,
    loaderButtonShow,
    setFullName,
    fullname,
    enableBtnLoader
  } = props;
  
  console.log(`driverData ----->>>>`, driverData);
  const deriverWithScheduledTrip = driverData?.filter((driver) => {
    return driver?.driverStatus === 'Scheduled' || driver?.driverStatus === 'In Progress';
  });
  console.log(`deriverWithScheduledTrip ----->>>>`, deriverWithScheduledTrip);
  
  return (
    <React.Fragment>
      <div className="page-content driver-management">
        <div className="heading">{t('DRIVER_MANAGEMENT')}</div>
        <Card className="mainCard table-responsive">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <div className={searchValue?.length > 200 ? "addBtn height" : "addBtn"}>
                {searchValue?.length > 0 &&
                  <button
                    onClick={searchFilterClear}
                    className="clear-btn-style"
                  >
                    <i className='mdi mdi-window-close'></i>
                  </button>
                }
                {searchValue?.length > 200 &&
                  <span className='search-error-style'>{t('ALLOWED_MAX_200_CHARACTERS')}</span>
                }
                <button
                  color="primary"
                  className="pink-btn"
                  onClick={() => addDriverModal()}
                >
                  {t("ADD_DRIVER")}
                </button>
              </div>

              <CSmartTable
                cleaner
                clickableRows
                columns={columns}
                columnFilter={false}
                items={driverData}
                itemsPerPage={itemsPerPage}
                onRowClick={(item) => {
                  navigate("/driver-management/details", { state: item })
                }}
                scopedColumns={{
                  fullname: (item: DriverData) => {
                    const className = item?.status === t("DEATCTIVE") ? "disableTd" : "";
                    return (
                      <td className={className} >
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div className="driverName">
                            {item?.fullname || '-'}
                          </div>
                          <div className="phone">
                            {item?.phonenumber || '-'}
                          </div>
                        </div>
                      </td>
                    );
                  },
                  driverpin: (item: DriverData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.driverpin}
                        </div>
                      </td>
                    );
                  },
                  address: (item: any) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.address}
                        </div>
                      </td>
                    );
                  },
                  ongoingTripDetail: (item: DriverData) => {
                    console.log('item', item)
                    
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div>
                            {/* {item?.ongoingTripDetail?.length > 0 && item?.ongoingTripDetail?.triparea || '-'} */}
                            {item?.ongoingTripDetail?.triparea || 'No Trip Scheduled yet.'}
                          </div>
                          <div>
                            {/* {item?.ongoingTripDetail?.length > 0 && item?.ongoingTripDetail[0]?.tripstartdate && `Started on ${moment(item?.ongoingTripDetail?.length > 0 && item?.ongoingTripDetail[0]?.tripstartdate).local().format('ddd D MMM YYYY, hh:mm A')}`} */}
                            { item?.ongoingTripDetail?.tripstarttime && `Started on ${moment(item?.ongoingTripDetail?.tripstarttime, 'DD/MM/YYYY, HH:mm:ss').format('ddd DD MMM YYYY, hh:mm A')}`}
                          </div>
                        </div>
                      </td>
                    );
                  },
                  status: (item: DriverData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className={
                          item.driverStatus === t("IN_PROGRESS")
                            ? 'completed tdWrapper driver-wrapper'
                            : item.driverStatus === t("NOTSCHEDULES")
                              ? 'not-scheduled tdWrapper driver-wrapper'
                              : item.driverStatus === t("SCHEDU")
                                ? 'scheduled tdWrapper driver-wrapper'
                                : item.driverStatus === t("COMPLETED")
                                  ? 'completed tdWrapper driver-wrapper' : 'tdWrapper'
                        }>
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.driverStatus}
                        </div>
                      </td>
                    );
                  },

                  schedule: (item: DriverData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        {/* <div className="wrapper">
                          I have a tooltip.
                          <div className="tooltip">I am a tooltip!</div>
                          </div> */}
                        {item?.driverStatus === t("NOTSCHEDULES") && item?.isonboarded
                          ? <div className='driver-wrapper wrapper'>
                            {placeHolderShimer && <div className="Shimer"></div>}
                            {(deriverWithScheduledTrip?.length > 0) && <div className="tooltip">Trip is already scheduled!</div>}
                            <div className={deriverWithScheduledTrip?.length > 0 ? 'tripBtn disabled': 'tripBtn'} onClick={(e) => {
                              if (item?.status === 'active') {
                                navigate("/driver-management/schedule-trip", { state: item });
                                e.stopPropagation();
                              }
                            }} >
                              Schedule
                            </div>
                          </div>
                          : !item?.isonboarded ? 'Onboard Pending' : ''
                        }


                      </td>
                    );
                  },
                  actions: (item: any) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="action-wrapper">
                          {placeHolderShimer && (
                            <div className="Shimer"></div>
                          )}

                          <CustomToggleButton
                            id=''
                            checked={item?.status === t("ACTIVE") ? true : false}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onCheckChange={(e) => {
                              onSlideSwitch(e, item);
                            }}
                          />
                          <button
                            className="Edit-Buttton"
                            onClick={(e) => {
                              onUpdateDriver(item, e);
                              e.stopPropagation();
                            }}
                          >
                            <i
                              className="mdi mdi-pencil-outline font-size-24"
                              id="deletetooltip"
                            ></i>
                          </button>
                          <button
                            className="Delete-Buttton"
                            onClick={(e) => {
                              setFullName(item?.fullname);
                              openDeleteDriverModal(e, item)
                              e.stopPropagation();
                            }}
                          >
                            <i
                              className="mdi mdi-delete-outline font-size-24"
                              id="deletetooltip"
                            ></i>
                          </button>
                        </div>
                      </td>
                    );
                  },
                }}
                tableFilter={true}
                sorterValue={{ column: 'fullname', state: 'asc' }}
                tableFilterValue={(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERSEARCHVALUE) as string) === '' ? '' : (window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERSEARCHVALUE) as string)}
                tableFilterPlaceholder={t("SEARCH_BY_DRIVER_NAME_OR_TRIP_LOCATION")}
                onTableFilterChange={onTableFilterChange}
                tableProps={{
                  className: placeHolderShimer ? "driver-table shimer-row-style" : "driver-table",

                  responsive: true,
                  striped: false,
                  hover: false,
                }}
                noItemsLabel={
                  !placeHolderShimer &&
                  <EmptyCell
                    emptyItems={driverData}
                    noDataTitle={NoDataTitle}
                    emptyDataTitle={emptyDataTitle}
                    noDataFoundText={noDataFoundText}
                    searchValueData={searchValueData}
                    searchClearTitle={searchClearTitle}
                    noDataAddButton={true}
                    noDataAddButtonTitle={noDataAddButtonTitle}
                    noDataDesc={NoDataDesc}
                    emptyDataAddButton={EmptyDataAddButton}
                    searchClearButton={searchFilterClear}
                    noDataImage={noDataImage}
                  />
                }
                tableBodyProps={{
                  className: "align-middle table-body-scroll",
                  style: {
                    maxHeight:
                      dimensions.height
                      - headerHeight
                      - pageTitleHeight
                      - buttonRowHeight
                      - 126,
                    // overflow: 'auto'
                  },
                  onScroll: handleTableBodyScroll,
                }}
                ref={tableContainerRef}
              />
              {loading && <CustomLoadingHTML />}
            </div>
          </Card.Body>
        </Card>

        {/* Disable Model Message Model */}
        <ConfirmationModal
          className={"deactivate"}
          isModelIcon={true}
          onHide={onHide}
          iconColor={"mdi mdi-toggle-switch-off-outline "}
          modalTitle={t("DEACTIVATE_DRIVER")}
          modalDescription={t(
            "ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_THIS_DRIVER"
          )}
          isUnderline={false}
          showModal={showStatusModal}
          leftBtnTitle={t("CONFIRM")}
          isFirstButton={true}
          leftBtnClass={"confirm-btn"}
          onProcced={onProcced}
          rightBtnClass="cancel-btn"
          isSecondButton={true}
          rightBtnTitle={t("CANCEL")}
          rightButtonClick={closeDeactiveModal}
          messagebold={`"${itemName}"`}
          isBold={true}
          leftdisabled={false}
          rightdisabled={false}
          modalContent={true}
          loaderButtonShow={enableBtnLoader} />

        {/* Delete Message Model */}
        <ConfirmationModal
          className={"delete-modal"}
          iconCricle={"icon-cricle-delete"}
          modalTitle={t('DELETE_DRIVER')}
          modalDescription={`Are you sure, you want to delete this driver`}
          isUnderline={false}
          showModal={showDeleteDriverModal}
          leftBtnTitle={t("YES_DELETE")}
          isModelIcon={true}
          iconColor={"mdi mdi-delete-outline"}
          leftBtnClass="delete-btn"
          isFirstButton={true}
          isSecondButton={true}
          rightBtnClass="cancel-btn"
          rightBtnTitle="Cancel"
          onProcced={onDeleteDriver}
          rightButtonClick={closeDeleteDriverModal}
          isBold={true}
          messagebold={`"${fullname}"`}
          leftdisabled={false}
          rightdisabled={false}
          onHide={closeDeleteDriverModal}
          modalContent={true}
          loaderButtonShow={loaderButtonShow} />

        <AddEditDrivers
          setphoneNumberError={setphoneNumberError}
          setPhoneTouched={setPhoneTouched}
          setPhoneNumbers={setPhoneNumbers}
          phoneNumbers={phoneNumbers}
          phoneTouched={phoneTouched}
          placeholder={t('GENRE_PLACEHOLDER')}
          numberValue={numberValue}
          setNumberValue={setNumberValue}
          showTypeModal={showTypeModal}
          showAddEditDriverModal={showAddEditDriverModal}
          closeAddEditModal={closeAddEditModal}
          driverAddEditFunction={driverAddEditFunction}
          handleSubmitDriver={handleSubmitDriver}
          addAndEditLoader={addAndEditLoader}
          driverValue={driverValue} isValidPhoneNumber={undefined} />
      </div>
    </React.Fragment>
  );
};

export default DriverManagementComponent;
