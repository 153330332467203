import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { ChangePassParams, chnagePassErrorMessage, chnagePassUserData, ForgotData, ForgotErrorMessage, ForgotParams, LoginData, LoginErrorMessage, LoginParams, ResetData, ResetErrorMessage, ResetParams } from "../../../interface/authInterface";
import authServices from "../../features/services/authService";

export const loginAdmin = createAsyncThunk("/login", async (data:LoginParams) => {
  const res = await authServices.login(data);
  return res;
});

export const forgotAdmin = createAsyncThunk("/forgot", async (data: ForgotParams) => {
  const res = await authServices.forgot(data);
  return res;
});

export const resetPassword = createAsyncThunk("/reset", async (data: ResetParams) => {
  const res = await authServices.reset(data);
  return res;
});

export const changePassword = createAsyncThunk("/change", async  (data: ChangePassParams) => {
  const res = await authServices.chnagePassword(data);
  return res;
})

// Define the initial state type
interface AuthState {
  loginResponse: AxiosResponse<string, LoginData>;
  loginErrorMessage: LoginErrorMessage; // Change the type to 'string'
  forgotResponse: AxiosResponse<string, ForgotData>;
  forgotErrorMessage: ForgotErrorMessage; // Change the type to 'string'
  resetResponse: AxiosResponse<string, ResetData>;
  resetErrorMessage: ResetErrorMessage; // Change the type to 'string'
  changePassResponce: AxiosResponse<string, chnagePassUserData>;
  changePassErrorMessage: chnagePassErrorMessage;
  isLoading: boolean;
}

/**
 * Creates a Redux slice for the 'auth' state.
 * @param {object} config - The configuration object for the slice.
 * @param {string} config.name - The name of the slice.
 * @param {object} config.initialState - The initial state of the slice.
 * @param {boolean} config.initialState.isLoading - The initial value for the 'isLoading' property.
 * @param {string} config.initialState.error - Change the type to 'string'
 */
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loginResponse: {},
    loginErrorMessage: {},
    forgotResponse: {},
    forgotErrorMessage: {},
    resetResponse: {},
    resetErrorMessage: {},
    changePassResponce: {},
    changePassErrorMessage: {},
    isLoading: false,
  } as AuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {})
      .addCase(loginAdmin.fulfilled, (state, action) => {        
        state.loginResponse = action.payload;
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.loginErrorMessage = action.error;
      });
    builder
      .addCase(forgotAdmin.pending, (state) => {})
      .addCase(forgotAdmin.fulfilled, (state, action) => {
        state.forgotResponse = action.payload;
      })
      .addCase(forgotAdmin.rejected, (state, action) => {
        state.forgotErrorMessage = action.error;
      });
    builder
      .addCase(resetPassword.pending, (state) => {})
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetResponse = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetErrorMessage = action.error;
      });
      builder
      .addCase(changePassword.pending, (state) => {})
      .addCase(changePassword.fulfilled, (state, action) => {
        state.changePassResponce = action.payload;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.changePassErrorMessage = action.error;
      });
  },
});

export default authSlice.reducer;
