import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import PasswordResetComponent from "./passwordResetComponent";
import Constant from "../../../util/constant";
import { showToast } from "../../../store/features/toast/toastSlice";
import { useTranslation } from 'react-i18next';
import { resetPassword } from "../../../store/features/auth/authSlice";
import { AppDispatch } from "../../../interface/authInterface";
import { resetData } from "./resetPasswordInterface";
import { Helmet } from 'react-helmet';

/**
 * Interface representing the structure of a reset form.
 * @interface ResetForm
 * @property {object} newpassword - The new password field.
 * @property {string} newpassword.name - The name of the new password field.
 * @property {object} newpassword.validate - The validation rules for the new password field.
 * @property {object} newpassword.validate.required - The required validation rule for the new password field.
 * @property {string} newpassword.validate.required.message - The error message for the required validation rule.
 * @property {object} newpassword.validate.validate - The additional validation rules for the new password
 */

const PasswordResetContainer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.RESENDOTP, '')
    const [show, setShow] = useState<boolean>(true);
    const [showconfirm, setShowConfirm] = useState<boolean>(true);
    const [busy, setBusy] = useState<boolean>(false);
    const resetPass = window.sessionStorage.getItem(Constant.LOCALSTORAGEKEYS.RESET_PASS);

    /**
     * useEffect hook that is triggered when the value of resetPass changes.
     * If resetPass is equal to 'done', it will navigate to the "/login" page.
     * It will also clear the localStorage.
     * @param {string} resetPass - The value that triggers the effect.
     * @param {function} navigate - The function used to navigate to a different page.
     * @returns None
     */
    useEffect(() => {
        if (resetPass === 'done') {
            navigate("/login");
        }
        localStorage.clear()
    }, [resetPass, navigate]);

    /**
     * An array of objects representing different error fields for a password validation.
     * Each object has a key representing a specific validation rule and a corresponding error message.
     * @type {Array<Object>}
     */
    const multiErrorFields = [
        { length: t('MINIMUM_8_CHARACTERS') },
        { hasSpecialChar: t('MINIMUM_1_SPECIAL_CHARACTER') },
        { hasNumbers: t('MINIMUM_1_NUMERIC_CHARACTER') },
        { hasUppercase: t('MINIMUM_1_UPPERCASE_CHARACTER') },
        { hasLowerCase: t('MINIMUM_1_LOWERCASE_CHARACTER') },
    ];



    const t_show = () => {
        setShow((prevShow) => !prevShow);
    };

    const t_show2 = () => {
        setShowConfirm((prevShowConfirm) => !prevShowConfirm);
    };


    const resetForm = {
        newpassword: {
            name: "newpassword",
            validate: {
                required: {
                    value: true,
                },
                validate: {
                    hasUppercase: (value: string) =>
                        value && value.match(Constant.REGEX.UPPERCASE) !== null,
                    hasLowerCase: (value: string) =>
                        value && value.match(Constant.REGEX.LOWERCASE) !== null,
                    hasNumbers: (value: string) =>
                        value && value.match(Constant.REGEX.NUMBER) !== null,
                    hasSpecialChar: (value: string) =>
                        value && value.match(Constant.REGEX.SPECIALCHARACTERS) !== null,
                    length: (value: string) => (value && value.length >= 8) || "",
                },
            },
        },
        password: {
            name: "password",
        },
    };
    /**
     * Handles the form submission for resetting the admin password.
     * @returns None
     */
    const onFormSubmit = async (value: resetData) => {
        setBusy(true);
        const resetToken = window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.RESET_TOKEN)
        const params = {
            resetToken: resetToken,
            password: value?.password
        }

        dispatch(resetPassword(params)).then((resultAction) => {
            if (resetPassword.fulfilled.match(resultAction)) {
                setBusy(false);
                dispatch(
                    showToast({
                        show: false,
                        message: (resultAction as any)?.payload?.message,
                        type: "success",
                    })
                );
                window.sessionStorage.setItem(Constant.LOCALSTORAGEKEYS.RESET_PASS, 'done')
                navigate("/login");
            } else if (resetPassword.rejected.match(resultAction)) {
                setBusy(false);
                dispatch(
                    showToast({
                        show: false,
                        message: resultAction?.error?.message,
                        type: "error",
                    })
                );
            }
        })
    };

    /**
     * Executes the specified code after a delay of 3000 milliseconds (3 seconds).
     * The code navigates to the "/login" page.
     * @returns None
     */
    const onClickCancel = async () => {
        setTimeout(() => {
            navigate("/login");
        }, 3000);
    };

    return (<>
        <Helmet>
            <title>Reset Password | Scooply Admin Panel</title>
            <meta name="description" content="Scooply Online Ice-Cream delivery platform." />
        </Helmet>

        <PasswordResetComponent
            onFormSubmit={onFormSubmit}
            onClickCancel={onClickCancel}
            resetForm={resetForm}
            t_show2={t_show2}
            t_show={t_show}
            multiErrorFields={multiErrorFields}
            busy={busy}
            showconfirm={showconfirm}
            show={show}
        />
    </>);
};

export default PasswordResetContainer;
