import { GET_ORDERS, GET_ORDER_DETAIL, REFUND_ORDER_PAYMENT } from "../../../api/endPoint";
import instance from "../../../helpers/axios";

interface refundPaymentParams {
    cusId: string,
    paymentId: string
}

const getAllOrders = (data:string) => {
    return instance.get(`${GET_ORDERS}?orderStatus=${data}`)
}

const getOrderDetail = (orderId: string) => {
    return instance.get(`${GET_ORDER_DETAIL}?id=${orderId}`);
}

const refundPayment = (data: refundPaymentParams) => {
    return instance.post(REFUND_ORDER_PAYMENT, {
        cusId: data?.cusId,
        paymentId: data?.paymentId
    } )
}


const orderService = {
    getAllOrders,
    refundPayment,
    getOrderDetail
}

export default orderService