/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Constant from "../../../util/constant";
import { t } from "i18next";

import OtpVerificationComponnet from './otpVerificationComponent';
import { showToast } from "../../../store/features/toast/toastSlice";
import { forgotAdmin } from "../../../store/features/auth/authSlice";
import { AppDispatch } from "../../../interface/driverInterface";
import { IotpInterface } from "./otpinterface";
import { Helmet } from 'react-helmet';

/**
 * A container component for OTP verification.
 * @returns A React functional component.
 */
const OtpVerificationContainer: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [busy, setBusy] = useState(false);
    const email = useSelector((state) => (state as any)?.auth?.forgotResponse?.data?.email);
    const getForgotTime = window.sessionStorage.getItem('expireTime')

    const otpExpireTimestamp = new Date((getForgotTime as any)).getTime();
    const currentTimestamp = Date.now();
    const isOTPExpired = currentTimestamp < otpExpireTimestamp;
    const [digitValue, setDigitValue] = useState('');
    const [timer, setTimer] = useState(30);
    const verifyOtp = window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.OTP)
    const resendOtp = window.sessionStorage.getItem(Constant.SESSIONSTORAGEKEYS.RESENDOTP)

    const otpContainer = document?.querySelector('.otp-container') as HTMLElement | null;
    const lastInput = otpContainer?.querySelector('input[aria-label^="Please enter OTP character 4"]:last-child') as HTMLInputElement | null;

    if (lastInput) {
        if (lastInput.value.trim() !== '') {
            lastInput.blur();
        }
    }

    const onOtpChange = (value: React.SetStateAction<string>) => {
        setDigitValue(value);
    };



    /**
     * Handles the form submission event.
     * If the entered digit value matches the verifyOtp or resendOtp value,
     * a success toast message is shown and the user is navigated to the password reset page.
     * Otherwise, an error toast message is shown.
     * @returns None
     */
    const onFormSubmit = async () => {
        setBusy(true);
        if (digitValue === verifyOtp || digitValue === resendOtp && isOTPExpired) {
            dispatch(showToast({
                show: false,
                message: t("CODE_VERIFIRED"),
                type: "success",
            }),
            );
            setBusy(false);
            window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.RESENDOTP, "");
            navigate("/reset-password");
        } else if (currentTimestamp > otpExpireTimestamp) {
            dispatch(showToast({
                show: false,
                message: t("OTP_EXPIRED"),
                type: "error",
            }),
            );
            setBusy(false)
        } else {
            dispatch(showToast({
                show: false,
                message: t("INCORRECT_CODE"),
                type: "error",
            }),
            );
            setBusy(false)
        }
    };

    /**
     * Handles the click event for the "Resend" button. Sends a request to the server to resend the OTP email.
     * @returns None
     */
    const handleResendClick = async () => {

        dispatch(forgotAdmin(email)).then((resultAction) => {
            if (forgotAdmin.fulfilled.match(resultAction)) {
                setTimer(30);
                const timeandDate = new Date()
                const expireTime = new Date(timeandDate.getTime() + 2 * 60 * 1000)
                window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.EXPIRE_TIME, (expireTime as any));
                window.sessionStorage.setItem(
                    Constant.SESSIONSTORAGEKEYS.RESENDOTP,
                    resultAction?.payload?.data?.otp
                );
                window.sessionStorage.setItem(Constant.SESSIONSTORAGEKEYS.OTP, "");
                dispatch(
                    showToast({
                        show: false,
                        message: t("RESEND_CODE"),
                        type: "warning",
                    })
                );
            } else if (forgotAdmin.rejected.match(resultAction)) {
                setBusy(false);
                dispatch(showToast({
                    show: false,
                    message: resultAction?.error?.message,
                    type: "error",
                }),
                );
            }
        })
    };
    const otpForm: IotpInterface = {
        otp: {
            name: 'otp',
            validate: {
                required: {
                    value: true,
                }
            },
        },
    };

    /**
     * useEffect hook that starts a countdown timer and updates the timer state every second.
     * When the timer reaches 0, the timerID is cleared.
     * @param {number} timer - the initial value of the timer
     * @returns None
     */
    useEffect(() => {
        localStorage.clear()
        if (timer > 0) {
            const timerID = setTimeout(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);

            return () => {
                clearTimeout(timerID);
            };
        }
    }, [timer]);

    return (<>
        <Helmet>
            <title>Otp Verification | Scooply Admin Panel</title>
            <meta name="description" content="Scooply Online Ice-Cream delivery platform." />
        </Helmet>

        <OtpVerificationComponnet
            busy={busy}
            onFormSubmit={onFormSubmit}
            digitValue={digitValue}
            onOtpChange={onOtpChange}
            timer={timer}
            handleResendClick={handleResendClick}
            otpForm={otpForm}
            email={email}
        />
    </>);
};

export default OtpVerificationContainer;