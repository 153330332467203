import { Link } from 'react-router-dom';
import Pages404 from '../pages/404/404';
import LoginContainer from '../pages/auth/login/loginContainer';
import PasswordResetContainer from '../pages/auth/resetPassword/passwordResetContainer';
import ForgotPasswordContainer from '../pages/auth/forgotPassword/forgotPasswordContainer';
import DriverManagementContainer from '../pages/driverManagement/driverManagementContainer';
import OtpVerificationContainer from '../pages/auth/otpVerification/otpVerificationContainer';
import DriverDetailsContainer from '../pages/driverManagement/driverDetails/driverDetailsContainer';
import DriverScheduleTripContainer from '../pages/driverManagement/driverSchedule/driverScheduleTripContainer';
import CustomerManagementContainer from '../pages/customerManagement/customerManagementContainer';
import ProductManagementContainer from '../pages/productManagement/productManagementContainer';
import OrderManagementContainer from '../pages/orderManagement/orderManagementContainer';
import OrderStatusDetailsContainer from '../pages/orderManagement/orderDetails/orderStatusDetailsContainer';
import NotificationContainer from '../pages/notification/notificationContainer';
import ChangePasswordContainer from '../pages/changePassword/changePasswordContainer';

const authProtectedRoutes = [
    { path: "/", exact: true, component: () => <Link to="/driver-management" /> },
    { path: "/driver-management", element: <DriverManagementContainer /> },
    { path: "/driver-management/schedule-trip", element: <DriverScheduleTripContainer /> },
    { path: "/driver-management/details", element: <DriverDetailsContainer /> },
    { path: "/customer-management", element: <CustomerManagementContainer /> },
    { path: "/product-management", element: <ProductManagementContainer /> },
    { path: "/order-management", element: <OrderManagementContainer /> },
    { path: "/order-management/details", element: <OrderStatusDetailsContainer /> },
    { path: "/notification", element: <NotificationContainer /> },
    { path: "/change-password", element: <ChangePasswordContainer /> },
];

const publicRoutes = [
    { path: "/", element: <LoginContainer /> },
    { path: "/login", element: <LoginContainer /> },
    { path: "/forgot-password", element: <ForgotPasswordContainer /> },
    { path: "/otp-verification", element: <OtpVerificationContainer /> },
    { path: "/reset-password", element: <PasswordResetContainer /> },
    { path: "/*", element: <Pages404 /> },

];

export { authProtectedRoutes, publicRoutes };
