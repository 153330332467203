import React, { FC } from "react";
import { Modal } from "react-bootstrap";

interface CustomModalProps {
  modalTitle: string;
  closeModelIcon: () => void;
  onHide: () => void;
  className?: string;
  showModal: boolean;
  children?: React.ReactNode;
}

const CustomModal: FC<CustomModalProps> = (props) => {
  const { showModal, modalTitle, closeModelIcon, onHide, children, className } = props;
  return (
    <Modal
      className={"custom-modal " + className}
      show={showModal}
      onHide={onHide}
      centered
    >
      <Modal.Body>
        <div className="title-icon-wrapper">
          <div className="modal-title">
            <h4>{modalTitle}</h4>
          </div>
          <div className="close-icon" onClick={closeModelIcon}>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
        </div>
        {children}
      </Modal.Body>
    </Modal>
  );
};
export default CustomModal;