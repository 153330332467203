/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, Tab } from 'react-bootstrap';
import Card from "react-bootstrap/Card";

import * as productItem from '../../api/orderStatusList.json';
import BackButton from '../../components/backButton/backButton';
import OrderStatusListComponent from '../../components/ordermanagementTabs/orderStatusList/orderStatusListComponent';
import Constant from "../../util/constant";
import Loader from '../../assets/images/giphy.gif';
interface OrderManagementComponentProps {
  backButtonShow?: boolean,
  orderListData: any[]; // Update with actual data type
  placeHolderShimer: boolean;
  onTableFilterChange: (item: any) => void;
  dimensions: any;
  loading: boolean;
  tableContainerRef1: any; // Update with actual data type
  tableContainerRef2: any; // Update with actual data type
  tableContainerRef3: any; // Update with actual data type
  tableContainerRef4: any; // Update with actual data type
  itemsPerPage: number,
  headerHeight: number,
  pageTitleHeight: number,
  tabTitleHeight: number,
  buttonRowHeight: number,
  searchValue: any,
  searchFilterClearData: () => void,
  handleTableBodyScroll: () => void,
  dimenstionError: boolean
  setDimenstionError: React.Dispatch<React.SetStateAction<boolean>>;

  showStatusModal: boolean;
  openRefundModal: (e: any, item: any) => void;
  onProcced: () => void;
  closeStatusModal: () => void;
  columns: any;
  handleClick: (item: any) => void;
  noShowCount: number,
  pastOrderscount: number,
  pendingOrdersCount: number,
  paymentFailedCount: number,
  defaultFilterData: any,
  currentTab: string,
  defaultTabKey: any,
  TripID: any,
  CustomerName: any,
  DateOrder: any,
  DriverName: any,
  customerCount: number | undefined,
  tripCount: number | undefined,
  driverCount: number | undefined,
  refundBtnLoader: boolean,
  customerData: any,
  filteredEventData: number | undefined,
  onFilteredItemsChange: (item: any) => void;
  filterData: number | undefined,
  tabChangeBool: boolean
}
const OrderManagementComponent: React.FC<OrderManagementComponentProps> = (props) => {
  const { t } = useTranslation();
  const {
    dimenstionError,
    setDimenstionError,
    headerHeight,
    pageTitleHeight,
    tabTitleHeight,
    buttonRowHeight,
    orderListData,
    onTableFilterChange,
    dimensions,
    placeHolderShimer,
    searchValue,
    loading,
    itemsPerPage,
    tableContainerRef1,
    tableContainerRef2,
    tableContainerRef3,
    tableContainerRef4,
    handleTableBodyScroll,
    showStatusModal,
    onProcced,
    closeStatusModal,
    openRefundModal,
    columns,
    handleClick,
    noShowCount,
    pastOrderscount,
    pendingOrdersCount,
    paymentFailedCount,
    defaultFilterData,
    currentTab,
    searchFilterClearData,
    defaultTabKey,
    TripID,
    CustomerName,
    DateOrder,
    DriverName,
    customerCount,
    tripCount,
    refundBtnLoader,
    customerData,
    filteredEventData,
    onFilteredItemsChange,
    filterData,
    tabChangeBool
  } = props;
  let DateFilter = window.localStorage.getItem('orderDate');
  console.log(`orderListData ----->>>>`, orderListData);
  console.log('conditions',placeHolderShimer && filterData !== 0);
  console.log('filterData',filterData);
  
  return (
    <React.Fragment>
      <div className="page-content order-management">
        <div className='page-title-style'>
          {props?.backButtonShow && (
            <BackButton />
          )}
          <div className="heading">{t('ORDER_MANAGEMENT')}</div>
        </div>
        <Card className="mainCard table-responsive">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <div className="list-tab-style">
                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultTabKey === 'NO-SHOW-CUSTOMER' ? "noShow" : defaultTabKey === 'PENDING' ? "pendingOrders" : defaultTabKey === 'DELIVERED' ? "pastOrders" :  defaultTabKey === 'PAYMENT_FAILED' ? "paymentFailed" :  ""}>
                  <Nav variant="pills" className="flex-row mb-3">
                    <Nav.Item>
                      <Nav.Link eventKey="noShow" onClick={() => handleClick(Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW)}> 
                        {t('NO_SHOW')}
                        <span className='count-number-style'>
                          {currentTab === 'NO-SHOW-CUSTOMER' && 
                            (placeHolderShimer ? <div className='btn-loader'><img src={Loader} alt='loader' height={10} width={10} /></div> : filterData !== undefined ? filterData : noShowCount)}
                        </span>
                      </Nav.Link>
                      <Nav.Link eventKey="pendingOrders" onClick={() => handleClick(Constant.ORDER_API_STATUS.PENDING)}>
                        {t('PENDING_ORDERS')}
                        <span className='count-number-style'>
                          {currentTab === 'PENDING' && 
                            (placeHolderShimer ? <div className='btn-loader'><img src={Loader} alt='loader' height={10} width={10} /></div> : filterData !== undefined ? filterData : pendingOrdersCount)}
                        </span>
                      </Nav.Link>
                      <Nav.Link eventKey="pastOrders"  onClick={() => handleClick(Constant.ORDER_API_STATUS.DELIVERED)}>
                        {t('PAST_ORDERS')}
                        <span className='count-number-style'>
                          {currentTab === 'DELIVERED' && 
                            (placeHolderShimer ? <div className='btn-loader'><img src={Loader} alt='loader' height={10} width={10} /></div> : filterData !== undefined ? filterData : pastOrderscount)}
                        </span>
                      </Nav.Link>
                      <Nav.Link eventKey="paymentFailed" onClick={() => handleClick(Constant.ORDER_API_STATUS.PAYMENT_FAILED)}>
                        {t('PAYMENT_FAILED')}
                        <span className='count-number-style'>
                          {currentTab === 'PAYMENT_FAILED' && 
                            (placeHolderShimer ? <div className='btn-loader'><img src={Loader} alt='loader' height={10} width={10} /></div> : filterData !== undefined ? filterData : paymentFailedCount)}
                        </span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="noShow">
                      <OrderStatusListComponent
                        onFilteredItemsChange={onFilteredItemsChange}
                        status={'refund'}
                        columns={columns}
                        orderListData={orderListData}
                        noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1702286339/Scooply/web/project-image/order.svg'}
                        emptyDataTitle={t("NO_ORDERS_TO_SHOW")}
                        noDataFoundText={t('THERE_ARE_NO_ANY_ORDERS_TO_SHOW_YET')}
                        NoDataTitle={t("NO_ORDERS_TO_SHOW")}
                        // searchValueData={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '') ?  `${t('YOUR_FILTER')} "${TripID || CustomerName}" ${t('DID_NOT_MATCH_ANY_NO_SHOW_ORDER')}` : `${t('YOUR_SEARCH')} "${searchValue}" ${t('DID_NOT_MATCH_ANY_NO_SHOW_ORDER')}`}
                        searchValueData={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ?  `Your filters did not match any orders,` : `${t('YOUR_SEARCH')} "${searchValue}" ${t('DID_NOT_MATCH_ANY_NO_SHOW_ORDER')}`}
                        NoDataDesc={t("PLEASE_TRY_AGAIN")}
                        searchClearTitle={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ? t("CLEAR_FILTER")   : t("CLEAR_SEARCH")}
                        onTableFilterChange={onTableFilterChange}
                        placeHolderShimer={placeHolderShimer}
                        dimensions={dimensions}
                        headerHeight={headerHeight}
                        pageTitleHeight={pageTitleHeight}
                        tabTitleHeight={tabTitleHeight}
                        buttonRowHeight={buttonRowHeight}
                        searchValue={searchValue}
                        searchFilterClear={searchFilterClearData}
                        loading={loading}
                        tableContainerRef={tableContainerRef1}
                        itemsPerPage={itemsPerPage}
                        handleTableBodyScroll={handleTableBodyScroll}
                        dimenstionError={dimenstionError}
                        setDimenstionError={setDimenstionError}
                        showStatusModal={showStatusModal}
                        onProcced={onProcced}
                        closeStatusModal={closeStatusModal}
                        defaultFilterData={defaultFilterData}
                        openRefundModal={openRefundModal} 
                        currentTab={currentTab}
                        refundBtnLoader={refundBtnLoader}
                        customerData={customerData}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="pendingOrders">
                      <OrderStatusListComponent
                        onFilteredItemsChange={onFilteredItemsChange}
                        status={'pending'}
                        columns={columns}
                        orderListData={orderListData}
                        noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1702286339/Scooply/web/project-image/order.svg'}
                        emptyDataTitle={t("NO_ORDERS_TO_SHOW")}
                        noDataFoundText={t('THERE_ARE_NO_ANY_ORDERS_TO_SHOW_YET')}
                        NoDataTitle={t("NO_ORDERS_TO_SHOW")}
                        searchValueData={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ?  `Your filters did not match any orders,` : `${t('YOUR_SEARCH')} "${searchValue}" ${t('DID_NOT_MATCH_ANY_NO_SHOW_ORDER')}`}
                        NoDataDesc={t("PLEASE_TRY_AGAIN")}
                        searchClearTitle={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ? t("CLEAR_FILTER")   : t("CLEAR_SEARCH")}
                        onTableFilterChange={onTableFilterChange}
                        placeHolderShimer={placeHolderShimer}
                        dimensions={dimensions}
                        headerHeight={headerHeight}
                        pageTitleHeight={pageTitleHeight}
                        tabTitleHeight={tabTitleHeight}
                        buttonRowHeight={buttonRowHeight}
                        searchValue={searchValue}
                        searchFilterClear={searchFilterClearData}
                        loading={loading}
                        tableContainerRef={tableContainerRef2}
                        itemsPerPage={itemsPerPage}
                        handleTableBodyScroll={handleTableBodyScroll}
                        dimenstionError={dimenstionError}
                        setDimenstionError={setDimenstionError}
                        showStatusModal={showStatusModal}
                        onProcced={onProcced}
                        defaultFilterData={defaultFilterData}
                        closeStatusModal={closeStatusModal}
                        openRefundModal={openRefundModal}
                        currentTab={currentTab}
                        refundBtnLoader={refundBtnLoader}
                        customerData={customerData}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="pastOrders">
                      <OrderStatusListComponent
                        onFilteredItemsChange={onFilteredItemsChange}
                        status={'past'}
                        columns={columns}
                        orderListData={orderListData}
                        noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1702286339/Scooply/web/project-image/order.svg'}
                        emptyDataTitle={t("NO_ORDERS_TO_SHOW")}
                        noDataFoundText={t('THERE_ARE_NO_ANY_ORDERS_TO_SHOW_YET')}
                        NoDataTitle={t("NO_ORDERS_TO_SHOW")}
                        searchValueData={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ?  `Your filters did not match any orders,` : `${t('YOUR_SEARCH')} "${searchValue}" ${t('DID_NOT_MATCH_ANY_NO_SHOW_ORDER')}`}
                        // searchValueData={`${t('YOUR_SEARCH')} "${searchValue === null ? defaultFilterData?.fullname : searchValue}" ${t('DID_NOT_MATCH_ANY_NO_SHOW_ORDER')}`}
                        NoDataDesc={t("PLEASE_TRY_AGAIN")}
                        searchClearTitle={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ? t("CLEAR_FILTER")   : t("CLEAR_SEARCH")}
                        // searchClearTitle={t("CLEAR_SEARCH")}
                        onTableFilterChange={onTableFilterChange}
                        placeHolderShimer={placeHolderShimer}
                        dimensions={dimensions}
                        headerHeight={headerHeight}
                        pageTitleHeight={pageTitleHeight}
                        tabTitleHeight={tabTitleHeight}
                        buttonRowHeight={buttonRowHeight}
                        searchValue={searchValue}
                        searchFilterClear={searchFilterClearData}
                        loading={loading}
                        tableContainerRef={tableContainerRef3}
                        itemsPerPage={itemsPerPage}
                        handleTableBodyScroll={handleTableBodyScroll}
                        dimenstionError={dimenstionError}
                        setDimenstionError={setDimenstionError}
                        showStatusModal={showStatusModal}
                        onProcced={onProcced}
                        closeStatusModal={closeStatusModal}
                        openRefundModal={openRefundModal}
                        defaultFilterData={defaultFilterData}
                        currentTab={currentTab}
                        refundBtnLoader={refundBtnLoader}
                        customerData={customerData}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="paymentFailed">
                      <OrderStatusListComponent
                        onFilteredItemsChange={onFilteredItemsChange}
                        status={'failed'}
                        columns={columns}
                        orderListData={orderListData}
                        noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1702286339/Scooply/web/project-image/order.svg'}
                        emptyDataTitle={t("NO_ORDERS_TO_SHOW")}
                        noDataFoundText={t('THERE_ARE_NO_ANY_ORDERS_TO_SHOW_YET')}
                        NoDataTitle={t("NO_ORDERS_TO_SHOW")}
                        searchValueData={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ?  `Your filters did not match any orders,` : `${t('YOUR_SEARCH')} "${searchValue}" ${t('DID_NOT_MATCH_ANY_NO_SHOW_ORDER')}`}
                        NoDataDesc={t("PLEASE_TRY_AGAIN")}
                        searchClearTitle={(searchValue === null || searchValue === '') && (TripID !== '' || CustomerName !== '' || DateFilter !== '') ? t("CLEAR_FILTER")   : t("CLEAR_SEARCH")}
                        onTableFilterChange={onTableFilterChange}
                        placeHolderShimer={placeHolderShimer}
                        dimensions={dimensions}
                        headerHeight={headerHeight}
                        pageTitleHeight={pageTitleHeight}
                        tabTitleHeight={tabTitleHeight}
                        buttonRowHeight={buttonRowHeight}
                        searchValue={searchValue}
                        searchFilterClear={searchFilterClearData}
                        loading={loading}
                        tableContainerRef={tableContainerRef4}
                        itemsPerPage={itemsPerPage}
                        handleTableBodyScroll={handleTableBodyScroll}
                        dimenstionError={dimenstionError}
                        setDimenstionError={setDimenstionError}
                        showStatusModal={showStatusModal}
                        onProcced={onProcced}
                        closeStatusModal={closeStatusModal}
                        openRefundModal={openRefundModal}
                        defaultFilterData={defaultFilterData}
                        currentTab={currentTab}
                        refundBtnLoader={refundBtnLoader}
                        customerData={customerData}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default OrderManagementComponent;