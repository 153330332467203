import React, { ReactNode, KeyboardEvent, MouseEvent } from "react";
import Loader from '../../assets/images/giphy.gif';

interface CustomButtonProps {
  title: any;
  children?: ReactNode;
  disabled?: any;
  type?: "button" | "submit" | "reset";
  loading?: boolean;
  className?: string;
  icon?: ReactNode;
  id?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLButtonElement>) => void;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { title, children, disabled, type, loading, className, icon, id, onKeyDown, onClick } = props;
  return (
    <button
      id={id}
      className={
        disabled
          ? "custom-btn button-disable btn btn-primary "
          : (loading
            ? "custom-btn btn-loading btn btn-primary btn-block " + className
            : "custom-btn " + className)
      }
     
      type={type}
      onKeyDown={onKeyDown}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ?  <>{title} <div className='btn-loader'><img src={Loader} alt='loader' height={20} width={20} /></div></> : title}<>{icon}</>
      {children}
    </button>
  );
};
CustomButton.defaultProps = {
  loading: false,
};

export default CustomButton;
