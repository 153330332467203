import React from "react";
import { Controller } from 'react-hook-form';
import { useTranslation } from "react-i18next";

import HookForm from "../../../components/hookForm/hookForm";
import TextField from "../../../components/textField/textField";
import FileUpload from '../../../components/fileUpload/fileUpload';
import CustomModal from '../../../components/customModal/customModel';
import CustomButton from "../../../components/customButton/customButton";

import Constant from "../../../util/constant";
import { productvalue } from "../../../interface/productInterface";

import Loader from '../../../assets/images/img-loader.gif';
import Cropper from "react-easy-crop";

interface AddEditProductProps {
  showAddEditProductModal: boolean;
  closeAddEditModal: () => void;
  placeholder: string;
  productAddEditFunction: (form: any) => void;
  handleSubmitProduct: (e: string) => void;
  showTypeModal: any;
  productValue: productvalue;
  uploadThumNailValid: { [key: string]: string };
  // isUploadingDocumentLink?: boolean;
  // onChangeUploadingDocumentLink: () => void;
  handleDescriptiontextcount: any;
  uploadedDocumentThumnailFile?: any;
  // handleUploadThumnail: ( handleUploadThumnailFile: any) => void;
  handleUploadThumnailFile: (files: Blob[]) => void;
  handleClearUploadedThumnailFile: () => void;
  triggerRef?: any;
  profileImage: string;
  dimenstionError?: boolean;
  setDimenstionError: React.Dispatch<React.SetStateAction<boolean>>;
  addAndEditLoader: boolean,
  descriptiontextcount: any,
  setLoadingImg1:React.Dispatch<React.SetStateAction<boolean>>,
  loadingImg:boolean,
  setUploadingImg:React.Dispatch<React.SetStateAction<boolean>>,
  uploadingImg:boolean;

    // IMPLEMENT CROP IMAGE
    uploadInputRef: any,
    editFileUpload: any,
    fileUploadChange: any,
    aspectRatio: any,
    image: any,
    crop: any,
    setCrop: any,
    zoom: any,
    setZoom: any,
    croppedImage: any,
    onCropComplete: any,
    showCroppedImage: any,
    onPressCropCancel: any,
    editImage: boolean,
}

/**
 * Constructs the Cloudinary URL using the environment variables.
 * @returns {string} The constructed Cloudinary URL.
 */
const CLOUDINARY_URL =
  (process.env.REACT_APP_CLOUDNARY_URL ?? '') +
  (process.env.REACT_APP_CLOUDNARY_NAME ?? '') +
  (process.env.REACT_APP_CLOUDNARY_SUBFLODER ?? '');

/**
 * A functional component that renders a modal for adding or editing a driver.
 * @param {AddEditProductProps} props - The props for the component.
 * @returns The rendered component.
 */
const AddEditProduct: React.FC<AddEditProductProps> = (props) => {
  const { t } = useTranslation();
  const {
    productValue,
    showTypeModal,
    showAddEditProductModal,
    closeAddEditModal,
    handleSubmitProduct,
    productAddEditFunction,
    profileImage,
    dimenstionError,
    uploadThumNailValid,
    // handleUploadThumnail,
    handleUploadThumnailFile,
    // onChangeUploadingDocumentLink,
    triggerRef,
    setDimenstionError,
    addAndEditLoader,
    handleDescriptiontextcount,
    descriptiontextcount,
    setLoadingImg1,
    loadingImg,
    uploadedDocumentThumnailFile,
    setUploadingImg,
    uploadingImg,

    // IMPLEMENT CROP IMAGE
    uploadInputRef,
    editFileUpload,
    fileUploadChange,
    aspectRatio,
    image,
    crop,
    setCrop,
    zoom,
    setZoom,
    croppedImage,
    onCropComplete,
    showCroppedImage,
    onPressCropCancel,
    editImage
  } = props;
  

  const addEditForm = {
    thumbnailfile: {
      name: "thumbnailfile",
      validate: {
        required: {
          value: false,
        },
      },
    },
    productName: {
      name: "productName",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.NAME,
          message: t("NAME_CAN_ONLY_HAVE_LATTERS"),
        },
        maxLength: {
          value: 50,
          message: t("ALLOWED_MAX_50_CHARACTERS"),
        },
      },
    },
    description: {
      name: "description",
      validate: {
        required: {
          value: true,
        },
        // pattern: {
        //   value: Constant.REGEX.ALPHANUMERIC,
        //   message: t("PLEASE_ENTER_VALID_NAME"),
        // },
        // maxLength: {
        //   value: 250,
        //   message: t("ALLOWED_MAX_250_CHARACTERS"),
        // },
      },
    },
    productPrice: {
      name: "productPrice",
      validate: {
        required: {
          value: true,
        },
        pattern: {
          value: Constant.REGEX.AMOUNT,
          message: t("PLEASE_ENTER_A_VALID_PRODUCT_PRICE"),
        },
        maxLength: {
          value: 7,
          message: t("ALLOWED_MAX_7_CHARACTERS"),
        },
      },
    },
  };
// console.log('profileImage',profileImage );
// console.log('loadingImg',loadingImg );
// console.log('opoop',loadingImg === true && (profileImage === '' || null || undefined), loadingImg === true , profileImage === '' )

  
  return (
    <CustomModal
      className={"add-edit-customer-modal"}
      modalTitle={showTypeModal?.modal === 'add' ? t("ADD_PRODUCT") : t("EDIT_PRODUCT")}
      showModal={showAddEditProductModal}
      closeModelIcon={() => { closeAddEditModal(); setDimenstionError(false);}}
      onHide={() => closeAddEditModal()}>
      <HookForm
        defaultForm={{
          productName: showTypeModal?.modal === 'add' ? "" : productValue?.productname,
          description: showTypeModal?.modal === 'add' ? "" : productValue?.description,
          productPrice: showTypeModal?.modal === 'add' ? "" : productValue?.price?.toString(),
          thumbnailfile: showTypeModal?.modal === 'add' ? "" : productValue?.image
        }}
        init={(form) => {
          productAddEditFunction(form);
        }}
        onSubmit={(e) => {
          handleSubmitProduct(e);
        }}
      >
        {(formMethod) => {
          
          const productPriceValue = formMethod?.watch(addEditForm.productPrice.name);
          return (
            <>
              <div className="wrapper-input img-upload">
                { loadingImg ? <>
                                  <div className='btn-loader'><img src={Loader} alt='loader' height={10} width={10} /></div>
                                </> :
                  image ?
                    <div style={{minWidth: 150, height: 150, position: 'relative'}}>
                      <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspectRatio}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />  
                      <button type="button" className="close-icon" data-bs-dismiss="modal" onClick={onPressCropCancel} aria-label="Close"></button>
                    </div>
                  :
                  <div className='left-image-part'>
                    <Controller
                      name={addEditForm.thumbnailfile.name}
                      rules={{
                        required: {
                          value: true,
                          message:'',
                        },
                      }}
                      control={formMethod.control}
                      render={({
                        field
                      }) => (
                        <>
                          <div className="fileUpload">
                            <input type="file" ref={uploadInputRef} className="upload" 
                            onChange={(e) => {
                              setLoadingImg1(true);
                              
                              console.log('e.target.files',e.target.files);
                              
                              const selectedFile = e.target.files; // Null check added here
                              if (selectedFile) {
                                field.onChange(selectedFile);
                                fileUploadChange(e); // Assuming this function handles other file upload tasks
                              }
                            }}
                            accept="image/jpeg,image/jpg, image/png" />
                            {croppedImage ? 
                              <>
                                <img src={croppedImage?.fileUrl  || croppedImage } />
                                <div className="camera-cricle-style" onClick={editFileUpload}>
                                  <i className="fa fa-image"></i>
                                </div>
                              </>
                              :
                              <div className="dropZoneWrapper">
                                <i className='bx bx-image'></i>
                              </div>
                            }
                          </div>
                        </>
                      )}
                    />
                  </div>
                }
                <div className={dimenstionError ? 'right-contain-style error-msg' : 'right-contain-style'}>
                  <div className='title-upload'>{t('UPLOAD_IMAGE_IN_AN_IMAGE_RATIO_OF_1_1')}<br />
                    <span className='text-image-size'>{t('MAX_IMAGE_SIZE_SHOULD_BE_10MB')}</span>
                  </div>
                  {image &&
                    <div className="d-flex justify-content-center">
                      {/* <CustomButton
                        className="custom-btn p-0.5 font14 bgPink txtColor me-1"
                        onClick={showCroppedImage}
                        type="button"
                        title={t('CROP_IMAGE')}
                      /> */}
                      {/* <CustomButton
                        className="custom-btn p-0.5 font14 bgPink txtColor ms-1"
                        onClick={onPressCropCancel}
                        type="button"
                        title={t('CANCEL')}
                      /> */}
                    </div>
                  }
                </div>
              </div>
              {image &&
                <div className="controls">
                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.000001}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(e.target.value)
                    }}
                    className="zoom-range"
                  />
                </div> 
              }
              {/* <div className="wrapper-input img-upload">
                <div className='left-image-part'>
                  <Controller
                    name={addEditForm.thumbnailfile.name}
                    rules={{
                      required: {
                        value: false,
                        message: t('THUMBNAIL_FILE_IS_REQUIRED'),
                      },
                    }}
                    control={formMethod.control}
                    render={({
                      field: { onChange, value },
                    }) => (
                      <>
                        <FileUpload
                          onDrop={async (acceptedFiles) => {
                            setLoadingImg1(true);
                            setUploadingImg(true);
                            const validFileTypes = Object.keys(uploadThumNailValid);
                            const validFiles = acceptedFiles.filter((file) => validFileTypes.includes( file.type ) );
                            // Custom validation for image dimensions (512x512)
                            
                            if (validFiles)  {
                              // setLoadingImg1(false);
                              handleUploadThumnailFile(validFiles)
                              setDimenstionError(false);
                            } else {
                              // Handle validation error here
                              setDimenstionError(true);
                            }
                            // setLoadingImg1(true);
                            onChange(acceptedFiles);
                          }}
                          // maxSize={10 * 1024 * 1024} // 10 MB
                          accept={uploadThumNailValid}
                          triggerOpen={triggerRef}
                        >
                          <>
                          </>
                            {(profileImage === '' && uploadingImg === true) || loadingImg   ? 
                            <>
                              <div className='btn-loader'><img src={Loader} alt='loader' height={10} width={10} /></div>
                            </>
                            : (
                              profileImage
                                ? <img
                                src={profileImage?.length > 70 ? profileImage : `${process.env.REACT_APP_AWS_IMAGE_URL}${process.env.REACT_APP_AWS_IMAGE_PATH}/` + profileImage}
                                alt="placeholder"
                                ></img>
                                : <div className="dropZoneWrapper">
                                  <i className='bx bx-image'></i>
                                </div>
                            )
                          }

                          <div className="camera-cricle-style">
                            <i className="fas fa-camera"></i>
                          </div>
                        </FileUpload>
                      </>
                    )}
                  />
                </div>

                <div className={dimenstionError ? 'right-contain-style error-msg' : 'right-contain-style'}>

                  <div className='title-upload'>{t('UPLOAD_IMAGE_IN_AN_IMAGE_RATIO_OF_1_1')}
                  </div>
                    <span className='text-image-size'>{t('MAX_IMAGE_SIZE_SHOULD_BE_10MB')}</span>
                </div>
              </div> */}

              <div className="addEditWrapper">
                <div className="d-flex">
                  <p className="form-label">{t('PRODUCT_NAME')}</p>
                  <p className="requiredStyle">*</p>
                </div>
                <div className="TextFiledWrapper wrapper-input">
                  <TextField
                    formMethod={formMethod}
                    rules={addEditForm.productName.validate}
                    name={addEditForm.productName.name}
                    errors={formMethod?.formState.errors}
                    autoFocus={true}
                    required={false}
                    type="text"
                    placeholder={t('ENTER_PRODUCT_NAME')}
                    value={undefined}
                    multiErrorFields={undefined}
                    maxLength={50}
                  />
                </div>
              </div>

              <div className="addEditWrapper">
                <div className="d-flex">
                  <p className="form-label">{t('DESCRIPTION')}</p>
                  <p className="requiredStyle">*</p>
                </div>
                <div className="TextFiledWrapper wrapper-input">
                  <TextField
                    formMethod={formMethod}
                    rules={addEditForm.description.validate}
                    name={addEditForm.description.name}
                    errors={formMethod?.formState.errors}
                    autoFocus={false}
                    required={false}
                    type="text"
                    placeholder={t('ENTER_DESCRIPTION')}
                    value={undefined}
                    multiErrorFields={undefined}
                    textarea={'textarea'}
                    maxLength={100}
                    textCountVisible={true}
                    textcount={descriptiontextcount}
                    onChange={handleDescriptiontextcount}
                    textCountTotal={100}
                  />
                </div>
              </div>

              <div className="addEditWrapper">
                <div className="d-flex">
                  <p className="form-label">{t('PRODUCT_PRICE')}</p>
                  <p className="requiredStyle">*</p>
                </div>
                <div className={productPriceValue !== '' ? "TextFiledWrapper wrapper-input amount-input-style" : "TextFiledWrapper wrapper-input"}>
                  {productPriceValue !== '' && <div className='amount-style'>£</div>}
                  <TextField
                    formMethod={formMethod}
                    rules={addEditForm.productPrice.validate}
                    name={addEditForm.productPrice.name}
                    errors={formMethod?.formState.errors}
                    autoFocus={false}
                    required={false}
                    type="tel"
                    placeholder={t('ENTER_PRODUCT_PRICE')}
                    maxLength={7}
                  />
                </div>
              </div>

              <div className="Form_buton">
                <div className="mainDiv">
                  <CustomButton
                    className="custom-btn padding8-16 font14 bgPink txtColor"
                    type="submit"
                    title={showTypeModal?.modal === 'add' ? t("ADD") : t("UPDATE")}
                    disabled={
                      showTypeModal?.modal === 'add' 
                        ? !formMethod?.formState.isValid  || editImage
                        // : !formMethod?.formState.isValid ||  !formMethod?.formState.isDirty || (!editImage && !croppedImage.toString().startsWith('http://localhost'))
                        : !formMethod?.formState.isValid ||  !formMethod?.formState.isDirty || editImage  
                    }
                    loading={addAndEditLoader}
                  />
                  <CustomButton
                    className="cancel-btn"
                    type="button"
                    onClick={() => { closeAddEditModal(); setDimenstionError(false); }} title={t("CANCEL")} />
                </div>
              </div>
            </>
          );
        }}
      </HookForm>
    </CustomModal>
  );
};

export default AddEditProduct;
