import React from 'react';
import { Col } from 'reactstrap';
interface CardTopHeaderProps {
    labelTitle: string;
    labelText: string,
    rightImage: string,
    textShow: boolean
}

const CardTopHeader: React.FC<CardTopHeaderProps> = (props) => {
    const { labelTitle, labelText, rightImage, textShow } = props;
    return (
        <div className='bg-primary top-contain-row'>
            <Col className='col-7'>
                <h5 className='text-primary'>{labelTitle}</h5>
                {textShow && <p className='text-primary'>{labelText}</p>}

            </Col>
            <Col className='col-5 d-flex justify-content-end'>
                <img src={rightImage} alt='' className='img-fluid' />
            </Col>
        </div>
    );
};

export default CardTopHeader;
