import React from "react";
import { CSmartTable } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";

import EmptyCell from "../../../components/emptyCell/emptyCell";
import BackButton from '../../../components/backButton/backButton';
import CustomLoadingHTML from "../../../components/customLoadingHTML/customLoadingHTML";
import { CreateTripParams, DriverData } from "../../../interface/driverInterface";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
interface DriverManagementProps {
  driverData: DriverData[]; // Update with actual data type
  columns: any[]; // Update with actual data type
  placeHolderShimer: boolean;
  NoDataTitle: string;
  NoDataDesc: string;
  NoDataFound: string;
  NoDataAddButtonTitle?: string;
  EmptyDataAddButton: () => void;
  dimensions: any;
  loading: boolean;
  tableContainerRef: any;
  itemsPerPage: number,
  headerHeight: any,
  backButtonHeight: any,
  pageTitleHeight: any,
  driverDetailsRowHeight: any,
  noDataImage?: string,
  handleTableBodyScroll: any,
  driverDetail: any,
  emptyDataTitle?: string
  noDataFoundText?: string,
  backButtonShow?: boolean
}

/**
 * A functional component that renders the details of a driver.
 * @param {DriverManagementProps} props - The props passed to the component.
 * @returns The rendered component.
 */
const DriverDetailsComponent: React.FC<DriverManagementProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    headerHeight,
    backButtonHeight,
    pageTitleHeight,
    driverDetailsRowHeight,
    driverData,
    columns,
    NoDataTitle,
    NoDataDesc,
    NoDataAddButtonTitle,
    EmptyDataAddButton,
    dimensions,
    placeHolderShimer,
    noDataImage,
    loading,
    itemsPerPage,
    tableContainerRef,
    handleTableBodyScroll,
    driverDetail,
    emptyDataTitle,
    noDataFoundText
  } = props;


  return (
    <React.Fragment>
      <div className="page-content driver-details">
        <BackButton />
        <Card className="mainCard table-responsive mb-4 top-table-style">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <div className="heading">{t('DRIVER_DETAILS')}</div>
              <div className='driver-details-row'>
                <div className='column-box-style'>
                  <div className='label-title-style'><i className='bx bx-bus'></i>{t("DRIVER")}</div>
                  <div className='label-text-style'>{driverDetail?.fullname}</div>
                </div>
                <div className='column-box-style'>
                  <div className='label-title-style'><i className='mdi mdi-phone-outline'></i>{t("PHONE_NUMBER")}</div>
                  <div className='label-text-style'>{driverDetail?.phonenumber}</div>
                </div>
                <div className='column-box-style'>
                  <div className='label-title-style'><i className='mdi mdi-numeric'></i>{t("DRIVER_CODE")}</div>
                  <div className='label-text-style'>{driverDetail?.driverpin}</div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
        <Card className="mainCard table-responsive">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <div className="heading">{t("DRIVER_TRIPS")} <span className="th-count">{driverData?.length}</span></div>
              <CSmartTable
                // ref={smarttableref}
                cleaner
                clickableRows
                columns={columns}
                columnFilter={false}
                items={driverData}
                itemsPerPage={itemsPerPage}
                scopedColumns={{
                  tripid: (item: CreateTripParams) => {
                    const className = item.status === t("DEATCTIVE") ? "disableTd" : "";
                    return (
                      <td className={className}>
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div className="driverName">
                            {item?.tripid || '-'}
                          </div>
                        </div>
                      </td>
                    );
                  },
                  tripstarttime: (item: CreateTripParams) => {
                    return (
                      <td
                        className={item.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {moment(item?.tripstarttime, 'DD/MM/YYYY, HH:mm:ss').format('DD MMM YYYY hh:mm A') || '-'}
                        </div>
                      </td>
                    );
                  },
                  tripendtime: (item: CreateTripParams) => {
                    return (
                      <td
                        className={item.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div>
                            {item?.tripenddate ? moment(item?.tripenddate).format('DD MMM YYYY hh:mm A') : '-'}
                          </div>
                        </div>
                      </td>
                    );
                  },

                  triparea: (item: CreateTripParams) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="tripStatus driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.triparea || '-'}
                        </div>
                      </td>
                    );
                  },

                  status: (item: CreateTripParams) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className={
                          item.status === t('INPROGRESS')
                            ? 'completed tdWrapper driver-wrapper'
                            : item.status === t('NOTSCHEDULES')
                              ? 'not-scheduled tdWrapper driver-wrapper'
                              : item.status === t('SCHEDILED')
                                ? 'scheduled tdWrapper driver-wrapper'
                                : item.status === t('COMPLETED')
                                  ? 'completed tdWrapper driver-wrapper' : 'tdWrapper'
                        }>
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.status === t('COMPLETED') ? 'Completed' : item?.status === t('INPROGRESS') ? 'In Progress' : item?.status === t('SCHEDILED') ? 'Scheduled' : '-'}
                        </div>
                      </td>
                    );
                  },
                  totalorders: (item: CreateTripParams) => {
                    return (
                      <td
                        className={item.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="driver-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.totalorders || '0'}
                        </div>
                      </td>
                    );
                  },
                  actions: (item: any) => {                    
                    return (
                      <td
                        className={item.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="action-wrapper">
                          {placeHolderShimer && (
                            <div className="Shimer"></div>
                          )}
                          <button
                            className="view-buttton-orders"
                            onClick={(e) => {
                              window.localStorage.setItem('TripId', item?.tripid)
                              navigate("/order-management", { state: { tripID: item?.tripid, backButtonShow: true } });
                            }}

                          >
                            {item?.actions || 'View Orders'}
                          </button>
                        </div>
                      </td>
                    );
                  },
                }}
                tableFilter={false}
                tableProps={{
                  className: "driver-table",
                  responsive: true,
                  striped: false,
                  hover: false,
                }}
                noItemsLabel={
                  !placeHolderShimer &&
                  <EmptyCell
                    emptyItems={driverData}
                    noDataTitle={NoDataTitle}
                    emptyDataTitle={emptyDataTitle}
                    noDataFoundText={noDataFoundText}
                    noDataAddButton={false}
                    noDataAddButtonTitle={NoDataAddButtonTitle}
                    noDataDesc={NoDataDesc}
                    emptyDataAddButton={EmptyDataAddButton}
                    noDataImage={noDataImage}
                  />
                }
                tableBodyProps={{
                  className: "align-middle table-body-scroll",
                  style: {
                    maxHeight: dimensions.height - headerHeight - backButtonHeight - pageTitleHeight - driverDetailsRowHeight - 134, overflow: 'auto',
                  },
                  onScroll: handleTableBodyScroll,
                }}
                ref={tableContainerRef}
              />
              {loading && <CustomLoadingHTML />}
            </div>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment >
  );
};
export default DriverDetailsComponent;
