/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { debounce } from 'lodash';

import "./driverDetailsComponent";
import DriverDetailsComponent from './driverDetailsComponent';

import { AppDispatch } from "../../../interface/driverInterface";
import useWindowDimension from "../../../components/hooks/useWindowDimension";
import { tripListResponce } from "../../../store/features/driverManagement/driversSlice";
import { Helmet } from 'react-helmet';




/**
 * A container component that displays the details of a driver, including their trip history.
 * @component
 * @returns {React.FC} - The DriverDetailsContainer component.
 */
const DriverDetailsContainer: React.FC = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const driverDetail = location?.state || {};
  const tripList = useSelector((state) => (state as any)?.drivers?.tripResponce)
  const pageSizeRef = useRef(10);
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const dimensions = useWindowDimension();

  const [loading, setLoading] = useState(false);
  const [placeHolder, setPlaceHolder] = useState<boolean>(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [backButtonHeight, setBackButtonHeight] = useState<number>(0);
  const [pageTitleHeight, setPageTitleHeight] = useState<number>(0);
  const [driverDetailsRowHeight, setDriverDetailsRowHeight] = useState<number>(0);


  useEffect(() => {
    getList()
  }, [])

  /**
   * Retrieves a list of trips asynchronously and updates the state accordingly.
   * @returns None
   */
  const getList = async () => {
    dispatch(tripListResponce(driverDetail?._id)).then((resultAction) => {
      if (tripListResponce.fulfilled.match(resultAction)) {
        setPlaceHolder(false);
      } else if (tripListResponce.rejected.match(resultAction)) {
        console.log('resultAction', resultAction);
      }
    })
  }

  const columns = [
    {
      label: (
        <>
          {t("TRIP_ID")}
        </>
      ),
      key: "tripid",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("START_TIME")}
        </>
      ),
      key: "tripstarttime",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("END_TIME")}
        </>
      ),
      key: "tripendtime",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("AREA")}
        </>
      ),
      key: "triparea",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("TRIP_STATUS")}
        </>
      ),
      key: "status",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("TOTAL_ORDERS")}
        </>
      ),
      key: "totalorders",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("ACTIONS")}
        </>
      ),
      key: "actions",
      filter: false,
      sorting: false,
    },
  ];
  /**
   * Loads more items when triggered, with a delay of 2000 milliseconds.
   * @returns None
   */
  const loadMoreItems = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      pageSizeRef.current += 10;
    }, 2000);
  };
  /**
   * Debounces the handleTableScroll function to prevent it from being called too frequently.
   * When the table container is scrolled, it checks if the scroll position is at the bottom
   * and if there are more items to load. If both conditions are met, it calls the loadMoreItems function.
   * @returns None
   */
  const handleTableScroll = debounce(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      const isAtBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight;
      if (isAtBottom && tripList?.length > pageSizeRef.current) {
        loadMoreItems();
      }
    }
  }, 200);

  /**
   * Adds an event listener to the table container element to handle scroll events.
   * The event listener is added when the component mounts and removed when the component unmounts.
   * @param {Function} handleTableScroll - The function to handle the table scroll event.
   * @returns None
   */
  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleTableScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleTableScroll);
      }
    };
  }, [handleTableScroll]);

  /**
   * useEffect hook that calculates and sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    const backButtonHeight = document.getElementsByClassName("back-row-style")[0] as HTMLElement;
    const pageTitleHeight = document.getElementsByClassName("heading")[0] as HTMLElement;
    const driverDetailsRowHeight = document.getElementsByClassName("top-table-style")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
    if (backButtonHeight) {
      setBackButtonHeight(backButtonHeight.offsetHeight);
    }
    if (pageTitleHeight) {
      setPageTitleHeight(pageTitleHeight.offsetHeight);
    }
    if (driverDetailsRowHeight) {
      setDriverDetailsRowHeight(driverDetailsRowHeight.offsetHeight);
    }
  }, []);


  return (<>
    <Helmet>
      <title>Driver Detail | Scooply Admin Panel</title>
      <meta name="description" content="Scooply Online Ice-Cream delivery platform." />
    </Helmet>

    <DriverDetailsComponent
      columns={columns}
      driverData={tripList}
      noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1698916951/Scooply/web/project-image/driver.svg'}
      emptyDataTitle={t('NO_SCHEDULE_TRIP_TO_SHOW')}
      noDataFoundText={t('THERE_ARE_NO_ANY_SCHEDULE_TRIP_TO_SHOW_YET')}
      NoDataFound={t("NO_DATA_FOUND")}
      NoDataTitle={t("NO_DRIVERS_ARE_ADDED_TO_THE_DATABASE")}
      NoDataDesc={t("PLEASE_CLICK_ON_BELOW_BUTTON_TO_ADD_DRIVER_TO_THE_SYSTEM")}
      NoDataAddButtonTitle={t("ADD_DRIVER")}
      placeHolderShimer={placeHolder}
      dimensions={dimensions}
      headerHeight={headerHeight}
      backButtonHeight={backButtonHeight}
      pageTitleHeight={pageTitleHeight}
      driverDetailsRowHeight={driverDetailsRowHeight}
      loading={loading}
      tableContainerRef={tableContainerRef}
      itemsPerPage={pageSizeRef.current}
      handleTableBodyScroll={handleTableScroll}
      driverDetail={driverDetail}
      EmptyDataAddButton={function (): void {
        console.log('');
      }}
    />
  </>);
};

export default DriverDetailsContainer;

