import React from "react";
import Header from "./header";
import Sidebar from "./sidebar";
import { Outlet } from "react-router-dom";

interface LayoutProps { }

function Layout(props: LayoutProps) {
  const toggleMenuCallback = () => {
    var body = document.body;


    if (window.screen.width <= 1024) {
      body.classList.toggle("sidebar-enable");
      body.classList.toggle("vertical-collpsed");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  };

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback}
          t={undefined}
          showRightSidebar={undefined}
          toggleRightSidebar={function (): void {
            throw new Error("Function not implemented.");
          }} />
        <Sidebar />
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Layout;
