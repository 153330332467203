import { LIST_NOTIFICATIONS, READ_ALL_NOTIFICATIONS, READ_NOTIFICATIONS } from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import { enableCustomerParams } from "../../../interface/customerInterface";

const getNotifications = (token: string | null) => {
    return instance.get(LIST_NOTIFICATIONS, {
        headers: {
            Authorization: token
        }
    })
}
const readNotifications = (notificationID: string) => {
    return instance.get(`${READ_NOTIFICATIONS}?id=${notificationID}`);
}

const readAllNotification = (token: string | null) => {
    return instance.get(READ_ALL_NOTIFICATIONS, {
        headers: {
            Authorization: token
        }
    });
}

const notificationService = {
    getNotifications,
    readNotifications,
    readAllNotification
}

export default notificationService;