import React, { useEffect, useState , forwardRef} from "react";
// import ReactGoogleAutocomplete from 'react-google-autocomplete';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";
import 'rc-slider/assets/index.css';
import Range from 'rc-slider';
import HookForm from '../../../components/hookForm/hookForm';
import TextField from '../../../components/textField/textField';
import BackButton from '../../../components/backButton/backButton';
import CustomButton from '../../../components/customButton/customButton';

import Constant from '../../../util/constant';
import momentTz from 'moment-timezone';
import Calender from "../../../components/calender/calender";
import moment from "moment-timezone";
import { Controller } from "react-hook-form";
import { components } from 'react-select';



interface DriverScheduleTripProps {
  dimensions: any;
  headerHeight: any,
  pageTitleHeight: any,
  buttonRowHeight: any,
  bottomButtonRowHeight: any,
  scheduleForm: any,
  handleSubmitSchedule: (value: any) => void,
  onCancelSchedule: (value: any) => void,
  expirydatetime: any,
  currentformattedDate: any,
  onChangeTripStartDate: (value: any) => void,
  onTimeChange: (value: any) => void,
  rangeValue: any,
  setRangeValue: any
  onChangeAutocomplate: (value: any, formMethod: any, scheduleForm: any) => void,
  locationValue: any,
  latAndLng: any,
  driverDetails: any,
  currentDate: any,
  currentformattedTime: any,
  setTimeError: any,
  timeError: any,
  compareDate: string,
  setCompareTime: any,
  busy: boolean,
  selectedDate: any
  currentTime: string,
  timeZone: string,
  setLocationValue: any,
  clearDateWithTimeZone: any
}

const DriverScheduleTripComponent: React.FC<DriverScheduleTripProps> = (props) => {
  const { t } = useTranslation();



  const {
    dimensions,
    headerHeight,
    buttonRowHeight,
    pageTitleHeight,
    bottomButtonRowHeight,
    scheduleForm,
    handleSubmitSchedule,
    onCancelSchedule,
    onChangeTripStartDate,
    onTimeChange,
    rangeValue,
    setRangeValue,
    onChangeAutocomplate,
    locationValue,
    latAndLng,
    driverDetails,
    currentDate,
    setTimeError,
    setCompareTime,
    busy,
    timeError,
    selectedDate,
    currentTime,
    timeZone,
    setLocationValue,
    clearDateWithTimeZone
  } = props;

  const defaultProps = {
    center: {
      lat:
        latAndLng.lat,
      lng: latAndLng.lng
    },
    zoom: 11
  };
  let minDate: any = (momentTz().tz(timeZone)?.format('YYYY-MM-DD'));
  const [selectedDate1, setSelectedDate1] = useState<Date | null>(null);
  console.log('selectedDate1', selectedDate1);

  useEffect(()=>{
    console.log(`locationValue ----->>>>`, locationValue);
  }, [locationValue])

  const handleDateChange = (date: Date | null) => {
    setSelectedDate1(date);
  };
  useEffect(() => {
  }, [selectedDate1]);
  console.log(`currentDate, typeof currentDate ----->>>>`, currentDate, typeof currentDate, moment().tz(timeZone)?.format('YYYY-MM-DD'), minDate, typeof minDate);
  const checkTimeOfSelectedRegion = (timezone: string, dateString: string) => {
    // const timezone = 'Your Timezone Here';
    // const dateString = '2024-02-23';
    console.log(`timezone, dateString ----->>>>`, timezone, dateString);
    const currentDate = moment().tz(timezone)?.startOf('day');
    const providedDate = moment.tz(dateString, timezone)?.startOf('day');

    // Compare the dates
    if (providedDate.isSame(currentDate)) {
      console.log('The provided date is today.');
      return new Date(new Date().toLocaleString('en-US', { timeZone: timezone ? timezone : 'Europe/London' }));
    } else if (providedDate.isBefore(currentDate)) {
      console.log('The provided date is past.');
      return new Date(0, 0, 0, 23, 59);
    }else {
      console.log('The provided date is in the future.');
      return new Date(0, 0, 0, 0, 0);
    }
  }
  const ExampleCustomInput = forwardRef<HTMLButtonElement, { value: string, onClick: (e:any) => void }>(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value ? (
            <>
              <span className='endDateStyle'>{value}</span>
            </>) : (
            <span className="customDatePlaceholder">
              {t("SELECT")}
            </span>
        )}
    </button>
  ));
  return (
    <React.Fragment>
      <div className="page-content driver-schedule-trip">
        <BackButton />
        <Card className="mainCard table-responsive">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <HookForm
                defaultForm={{}}
                // init={(form) => {
                //   scheduleFunction(form);
                // }}
                onSubmit={(e) => {
                  handleSubmitSchedule(e);
                }}
              >
                {(formMethod) => {

                  const tripStartValue = formMethod?.watch(scheduleForm.tripStartDate.name);
                  const tripTimeValue = formMethod?.watch(scheduleForm.tripStartTime.name);

                  console.log('tripTimeValue>>', tripTimeValue);

                  return (<>
                    <div className="heading">{t("SCHEDULE_TRIP")}</div>
                    <div className='schedule-trip-main'
                      style={{
                        height:
                          dimensions.width > 810 ?
                            dimensions.height
                            - headerHeight
                            - pageTitleHeight
                            - buttonRowHeight
                            - bottomButtonRowHeight
                            - 100
                            : dimensions.height
                            - headerHeight
                            - pageTitleHeight
                            - buttonRowHeight
                            - bottomButtonRowHeight
                            - 100,
                        overflow: 'auto',
                      }}
                    >
                      <div className='schedule-trip-left-part'>
                        <>
                          <div className="addEditWrapper">
                            <div className="d-flex">
                              <p className="form-label">{t('DRIVER_TEXT')}</p>
                              {/* <p className="requiredStyle">*</p> */}
                            </div>
                            <div className='driver-name-style'>{driverDetails?.fullname}</div>
                            <div className="d-flex">
                              <p className="form-label">{t('TRIP_ID')}</p>
                              {/* <p className="requiredStyle">*</p> */}
                            </div>
                            <div className='driver-id-style'>{localStorage.getItem('TripId')}</div>
                            <div className="d-flex">
                              <p className="form-label">{t('TRIP_START_DATE')}</p>
                              <p className="requiredStyle">*</p>
                            </div>
                            <div className={locationValue === '' ? 'disabled' : ''}>
                              <div className={tripStartValue ? "date-wrapper" : 'date-wrapper placehoderStyle'}>
                                <TextField
                                  formMethod={formMethod}
                                  rules={scheduleForm.tripStartDate.validate}
                                  name={scheduleForm.tripStartDate.name}
                                  errors={formMethod?.formState.errors}
                                  autoFocus={true}
                                  required={false}
                                  type='date'
                                  min={minDate}
                                  onChange={(e) => {
                                    console.log(`timeZone11 ----->>>>`, timeZone);
                                    const today = new Date(e?.target?.value);
                                    const selectedDateTime = new Date(today?.toDateString() + ' ' + currentTime)
                                    const currentDateTime = new Date()
                                    if (selectedDateTime < currentDateTime) {
                                      setTimeError('aasddd')
                                    } else {
                                      setTimeError("")
                                    }
                                    if (e.target.value !== "") {
                                      onChangeTripStartDate(e);
                                    }
                                  }}
                                  value={undefined}
                                  placeholder={'dfgdfgd'}
                                  multiErrorFields={undefined} />
                              {(locationValue && !selectedDate) && <span style={{position: 'absolute', right: 0, fontSize: '13px', color: '#f46a6a'}}>Select Date Now</span>}

                                {/* <i className='mdi mdi-calendar-blank-outline'></i> */}
                              </div>
                            </div>
                            <div className="d-flex">
                              <p className="form-label">{t('TRIP_START_TIME')}</p>
                              <p className="requiredStyle">*</p>
                            </div>
                            <div className={locationValue === '' || selectedDate === '' ? 'disabled' : ''}>
                              <div className={tripTimeValue ? "time-wrapper" : 'time-wrapper placehoderStyle'}>
                                {/* <TextField
                                    formMethod={formMethod}
                                    rules={scheduleForm.tripStartTime.validate}
                                    name={scheduleForm.tripStartTime.name}
                                    errors={formMethod?.formState.errors}
                                    autoFocus={true}
                                    required={false}
                                    type="time"
                                    // min={currentformattedTimeˀ}

                                    onChange={(e) => {
                                      setTimeError('error')
                                      onTimeChange(e);
                                      const today = new Date(selectedDate);
                                      const selectedDateTime = new Date(today?.toDateString() + ' ' + e?.target?.value)
                                      const currentTimeWithTimeZone = momentTz(momentTz.tz(timeZone).format('YYYY-MM-DDTHH:mm:ss[Z]'));
                                      const selectedTimeWithTimeZone = momentTz(`${selectedDate}T${e?.target?.value}:00Z`);
                                      setCompareTime(selectedDateTime)
                                      if (selectedTimeWithTimeZone >= currentTimeWithTimeZone) {
                                        setTimeError("")
                                      } else {
                                        setTimeError('aasddd')
                                      }
                                    }}
                                    value={formMethod.getValues(scheduleForm?.tripStartTime?.name)}
                                    multiErrorFields={undefined}
                                    placeholder={'dgdg'}
                                  /> */}


                                <Controller
                                  name={scheduleForm.tripStartTime.name}
                                  // rules={{
                                  //   required: {
                                  //     value: true,
                                  //     message: t('THUMBNAIL_FILE_IS_REQUIRED'),
                                  //   },
                                  // }}
                                  rules={scheduleForm.tripStartTime.validate}
                                  control={formMethod.control}
                                  render={({
                                    field: { onChange },
                                  }) => (
                                    <>
                                      <Calender
                                        showTimeSelectOnly={true}
                                        showTimeSelect={true}
                                        disabled={locationValue === '' || selectedDate === '' ? true : false }
                                        selected={selectedDate1}
                                        dateFormat="h:mm aa"
                                        placeholderText="-- : -- : --"
                                        minTime={checkTimeOfSelectedRegion(timeZone, selectedDate)}
                                        // minTime={new Date(new Date().toLocaleString('en-US', { timeZone: timeZone ? timeZone : 'Europe/London' }))}
                                        maxTime={new Date(new Date().setHours(23, 59))}
                                        timeIntervals={5}
                                        customInput={<ExampleCustomInput 
                                          value={""}
                                          onClick={(e:any)=>handleDateChange(e)} />}
                                        // onChange={(e: { target: { value: Date | null; }; }) => {
                                        //   handleDateChange(e.target.value);
                                        //   const currentTimeWithTimeZone = momentTz(momentTz.tz(timeZone).format('YYYY-MM-DDTHH:mm:ss[Z]'));
                                        //     const selectedTimeWithTimeZone = momentTz(`${selectedDate}T${e?.target?.value}:00Z`);
                                        //     setCompareTime(selectedDateTime)
                                        //     if (selectedTimeWithTimeZone >= currentTimeWithTimeZone) {
                                        //       setTimeError("")
                                        //     } else {
                                        //       setTimeError('aasddd')
                                        //     }
                                        // }}
                                        onChange={(e: any) => {
                                          setTimeError('error')
                                          onTimeChange(e);
                                          onChange(e)
                                          handleDateChange(e);
                                          //  const time = e.toLocaleString()
                                          //  const yyy = moment(time).format('HH:mm')
                                          //  console.log( time, 'timeee');
                                          const today = new Date(selectedDate);
                                          const selectedDateTime = new Date(today?.toDateString() + ' ' + e?.target?.value)
                                          const currentTimeWithTimeZone = momentTz(momentTz.tz(timeZone).format('YYYY-MM-DDTHH:mm:ss[Z]'));
                                          const selectedTimeWithTimeZone = momentTz(`${selectedDate}T${moment(e).format('HH:mm')}:00Z`);
                                          console.log(`currentTimeWithTimeZone, selectedTimeWithTimeZone ----->>>>`, currentTimeWithTimeZone, selectedTimeWithTimeZone, timeZone);

                                          console.log('222', timeZone)
                                          setCompareTime(selectedDateTime)
                                          if (selectedTimeWithTimeZone >= currentTimeWithTimeZone) {
                                            setTimeError("")
                                          } else {
                                            setTimeError('aasddd')
                                          }
                                        }}

                                      />
                                      <i className='mdi mdi-clock-time-four-outline'></i>
                                    </>
                                  )}
                                />
                                {(locationValue && selectedDate && !selectedDate1) && <span style={{position: 'absolute', right: 0, fontSize: '13px', color: '#f46a6a'}}>Select Time Now</span>}
                              </div>

                            </div>
                            {/* {newerror !== "" && <div>hfghfgh</div>} */}

                            <div className="d-flex">
                              <p className="form-label">{t('SERVING_AREA_RADIUS')}</p>
                              <p className="requiredStyle">*</p>
                            </div>
                            <div className='range-value'>{rangeValue} {'miles'}</div>
                            <div className='range-slider-style'>
                              <div className='value-row-style'>
                                <div className='value-count-style'>{'2'}</div>
                                <div className='value-count-style'>{'10'}</div>
                              </div>
                              <Range
                                value={rangeValue}
                                min={2}
                                max={10}
                                step={1}
                                defaultValue={0}
                                allowCross={false}
                                count={0}
                                onChange={(e) => {
                                  setRangeValue(e)
                                }}
                              />
                            </div>
                          </div>
                        </>
                      </div>
                      <div className='schedule-trip-right-part'>
                        <div className="d-flex">
                          <p className="form-label">{t('TRIP_AREA')}</p>
                          <p className="requiredStyle">*</p>
                        </div>
                        <div className='google-dropdown-style'>

                          <GooglePlacesAutocomplete
                            apiKey={Constant.GOOGLEMAPAPI.APIKEY}
                            // const ClearIndicator = (props: any) => {
                            //   // const handleClear = () => {
                            //   //   console.log('Clear button clicked');
                            //   //   props.clearValue();
                            //   // };
                            //   return (
                            //     <components.ClearIndicator {...props}>
                            //       {console.log('props', props)}
                            //       <p
                            //         className="custom-clear-indicator"
                            //         onClick={(e) => {
                            //           console.log('Button clicked');
                            //           console.log(e, 'aaa'); // Log the event object  
                            //         }}
                            //         // title="Clear"
                            //       >
                            //         Clear
                            //       </p>
                            //     </components.ClearIndicator>
                            //   );
                            // };
                            selectProps={{
                              onChange: async (e) => {
                                console.log(`exx ----->>>>`, e);
                                // if (window.localStorage.getItem('timezone') !== '' ) {
                                //   formMethod.setValue(scheduleForm.tripStartDate.name, "");
                                //   formMethod.setValue(scheduleForm.tripStartTime.name, "");
                                // }
                                onChangeAutocomplate(e, formMethod, scheduleForm);
                                setSelectedDate1(null);
                                console.log(`timeZone11 ----->>>>`, timeZone);
                                if (e === null) {
                                  setLocationValue('');
                                  formMethod.setValue(scheduleForm.tripStartDate.name, "");
                                  formMethod.setValue(scheduleForm.tripStartTime.name, "");
                                  setSelectedDate1(null);
                                  clearDateWithTimeZone()
                                }
                              },

                              backspaceRemovesValue: true,
                              controlShouldRenderValue: true,
                              isClearable: true,
                              placeholder: "35 Hartley Drive, Dundee, United Kingdom",
                              components: {
                                ClearIndicator: (props) => (
                                  <div className="custom-clear-indicator-container">
                                    <i onClick={() => {
                                      props.clearValue();
                                      setLocationValue('');
                                      formMethod.setValue(scheduleForm.tripStartDate.name, "");
                                      formMethod.setValue(scheduleForm.tripStartTime.name, "");
                                      setSelectedDate1(null);
                                      clearDateWithTimeZone()
                                      console.log('Clear button clicked');
                                    }} className='mdi mdi-close' />
                                  </div>
                                )
                              }

                            }}
                          />
                          {/* <i className='bx bx-target-lock'></i> */}
                        </div>
                        <div>
                          <div className='map-view-style'>

                            {/* <LoadScript googleMapsApiKey={Constant.GOOGLEMAPAPI.APIKEY}> */}
                            <GoogleMap
                              mapContainerStyle={{ height: '100%', width: '100%' }}
                              center={defaultProps.center}
                              zoom={defaultProps.zoom}
                              options={{
                                fullscreenControl: false,
                                mapTypeControl: false
                              }}

                            >

                              <Marker
                                position={{ lat: latAndLng.lat, lng: latAndLng.lng }}
                                icon={'https://res.cloudinary.com/zudu/image/upload/v1701317052/Scooply/web/project-image/location.png'}
                              />
                            </GoogleMap>
                            {/* </LoadScript> */}

                          </div >
                        </div >

                      </div >
                    </div >
                    <div className="Form_buton">

                      <div className="mainDiv">
                        <CustomButton
                          className="custom-btn padding8-16 font14 bgPink txtColor"
                          type="submit"
                          title={t("SCHEDULE")}
                          loading={busy}
                          // disabled={!formMethod?.formState.isValid || !locationValue || !rangeValue || timeError !== ""}
                          // disabled={!formMethod?.formState.isValid || !locationValue || !rangeValue || timeError !== ""}
                          // disabled={formMethod.getValues(scheduleForm?.tripStartDate?.name) === "" && formMethod.getValues(scheduleForm?.tripStartTime?.name) === "" ? formMethod?.formState.isValid : !formMethod?.formState.isValid || !locationValue || !rangeValue || timeError !== ""}
                          disabled={!formMethod?.formState?.isValid}

                        />
                        <CustomButton
                          className="cancel-btn"
                          type="button"
                          onClick={(e) => onCancelSchedule(e)} title={t("CANCEL")} />
                      </div>
                    </div>
                  </>);
                }}
              </HookForm >
            </div >
          </Card.Body >
        </Card >
      </div >
    </React.Fragment >
  );
};
export default DriverScheduleTripComponent;
