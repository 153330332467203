import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Calender = (props) => {

    const { showYearDropdown,disabled, showMonthDropdown,showIcon, selected,dateFormat, placeholderText, onChange, showTimeInput, timeInputLabel, customInput, readOnly, maxDate, minDate, minTime, maxTime, showTimeSelect, locale, timeFormat, timeIntervals,  selectsRange, inline, isClearable, showTimeSelectOnly } = props;
    return (
        <div className="calender">
            <DatePicker
                disabled={disabled}   
                showIcon={showIcon}
                selected={selected}
                locale={locale}
                dateFormat={dateFormat}
                showTimeSelectOnly={showTimeSelectOnly}
                placeholderText={placeholderText}
                onChange={(e) => onChange(e)}
                showYearDropdown={showYearDropdown}
                showMonthDropdown={showMonthDropdown}
                showTimeInput={showTimeInput}
                timeInputLabel={timeInputLabel}
                readOnly={readOnly}
                customInput={customInput}
                maxDate={maxDate}
                minDate={minDate}
                minTime={minTime}
                maxTime={maxTime}
                showTimeSelect={showTimeSelect}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}
                selectsRange={ selectsRange}
                inline={inline}
                isClearable={isClearable}
                popperPlacement="top-end"
            />
        </div>
    );
};

Calender.defaultProps = {
    id: 1,
    checked: false,
    labelTitle: 'Title',
    showYearDropdown: false,
    showMonthDropdown: false,
    showTimeInput: false,
    dateFormat: 'dd/MM/yyyy',
    placeholderText: 'To Date'
};

export default Calender;
