import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from "react-bootstrap";
import { hideToast } from "../../store/features/toast/toastSlice";

interface ToastType {
  type: "success" | "error" | "warning";
  show: boolean;
  customClass?: string;
  messagebold?: string;
  message?: string;
}

const ToastComponent: React.FC = () => {
  const toast: ToastType = useSelector((state: any) => state.toast); // Replace 'RootState' with your RootState type
  const boldMsgMatch:any = toast?.message?.match(/“([^”]*)”/);

  const boldMsg :any= boldMsgMatch  ? boldMsgMatch[1] : "";

  let newStr: any = toast?.message;

  if (boldMsg) {
    newStr = newStr.replace(boldMsg, boldMsg); // Replace quotation marks with empty strings
    // newStr = newStr.replace(/“([^”]+)”/g, '$1');
  }

  const [showToast, setShowToast] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setShowToast(toast.show);
  }, [toast.show]);

  let toastClass: string | undefined;
  let iconClass: string | undefined = toast.customClass;

  if (toast.type === "success") {
    toastClass = "success-txt";
    iconClass = "mdi-check-all";
  } else if (toast.type === "error") {
    toastClass = "error-txt";
    iconClass = "mdi-block-helper";
  } else if (toast.type === "warning") {
    toastClass = "warning-txt";
    iconClass = "mdi-alert-circle-outline";
  }

  // const messageParts = newStr?.split(boldMsg || "");
  const messageParts = newStr?.split(boldMsg);

  return (
    <Toast
      onClose={() => {
        setShowToast(false);
        setTimeout(() => {
          dispatch(hideToast());
        });
      }}
      className={toastClass}
      show={showToast}
      delay={3000}
      autohide
    >
      <div className="tost-center">

        <div className="Tost_Content">
          <i className={`mdi ${iconClass}`}></i>
          <div className="txtcontain">

          {toast.message && (
          <div
            className="txtcontain"
            dangerouslySetInnerHTML={{ __html: toast.message }}
          />
        )}
          </div >
        </div >
        {/* <i className={`mdi mdi-close`}></i> */}
      </div >
    </Toast >
  );
};

export default ToastComponent;
