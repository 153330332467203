/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DriverManagementComponent from './driverManagementComponent';
import useWindowDimension from "../../components/hooks/useWindowDimension";
import { showToast } from "../../store/features/toast/toastSlice";
import "./driverManagementComponent";
import { createDriverResponce, deleteDriverResponce, driverListResponce, enableDisbledDriverResponce, setQueryParams, updateDriverResponce } from "../../store/features/driverManagement/driversSlice";
import { AppDispatch } from "../../interface/driverInterface";
import { debounce } from 'lodash';
import Constant from "../../util/constant";
import { Helmet } from 'react-helmet';



/**
 * A container component for managing drivers in a React application.
 * @component
 * @returns {React.FC} - The DriverManagementContainer component.
 */
const DriverManagementContainer: React.FC = (props) => {
  const { t } = useTranslation();
  const pageSizeRef = useRef(8000);
  const tableContainerRef: React.RefObject<HTMLDivElement> = useRef(null);
  const smarttableref = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const dimensions = useWindowDimension();
  const buttonDisable = useRef(false);
  const driverList = useSelector((state: any) => state?.drivers?.driverResponce);
  const totalDriver = useSelector((state: any) => state?.drivers?.totalDrivers);
  console.log('totalDriver: ', totalDriver);
  const driverQueryParams = useSelector((state: any) => state?.drivers?.queryParams);
  const [numberValue, setNumberValue] = useState(null)
  const [driverId, setDriverId] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string | any>(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.DRIVERSEARCHVALUE) || '');
  const [showAddEditDriverModal, setShowAddEditDriverModal] = useState<boolean>(false);
  const [showTypeModal, setShowModal] = useState<any>({ modal: 'add' });
  const [showDeleteDriverModal, setShowDeleteDriverModal] = useState<boolean>(false);
  const [updateDriverValues,] = useState<any | null>(null);
  const [showStatusModal, setShowStatusModal] = useState<boolean>(false);
  const [, setCreateValues] = useState("");
  const [driverNameValue, setDriverNameValue] = useState<string>("");
  const [defaultNumber,] = useState('');
  const [updatedNumber, setUpdatedNumber] = useState('');
  const [driverValue, setDriverValue] = useState('');
  const [itemName, setItemName] = useState('');
  const driverLength = driverList?.length;
  const [fullname, setFullName] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);
  const [addAndEditLoader, setAddAndEditLoader] = useState(false);
  const [enableBtnLoader, setEnableBtnLoader] = useState(false);
  const [placeHolder, setPlaceHolder] = useState<boolean>(true);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [pageTitleHeight, setPageTitleHeight] = useState<number>(0);
  const [buttonRowHeight, setButtonRowHeight] = useState<number>(0);
  const [filteredEventData, setFilteredEventData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [loadeMore, setLoadMore] = useState<boolean>(true);
  
  const closeDeleteDriverModal = () => setShowDeleteDriverModal(false);
  const closeDeactivateModal = () => setShowStatusModal(false);  

  
  /**
   * Opens the delete driver modal and sets the driver ID.
   * @param {any} e - The event object.
   * @param {any} item - The driver item to be deleted.
   * @returns None
   */
  const openDeleteDriverModal = (e: any, item: any) => {
    setShowDeleteDriverModal(true);
    setDriverId(item?._id)
  };
  
  /**
   * Updates the search value based on the filter text entered in the table filter.
   * @param {any} filterText - The filter text entered in the table filter.
   * @returns None
   */
  const onTableFilterChange = (filterText: any) => {    
    setSearchValue(filterText);
  };


  /**
   * Clears the search filter by programmatically clicking the clear button.
   * @returns None
   */
  const searchFilterClear = () => {
    tableContainerRef.current!.querySelectorAll("button")[0].click();
  };
  

  useEffect(() => {
    let search
    if (searchValue) {search = searchValue}
    dispatch(setQueryParams({page: 1, doc: 12, search: search}))
    getList({page: 1, doc: 12, search: search})
    localStorage.setItem(Constant.LOCALSTORAGEKEYS.TRIP_ID, '')
    window.localStorage.setItem('currentTab', '')
  }, [searchValue, totalDriver])

  useEffect(() => {
    if (Array.isArray(driverList)) {
      const lowercaseSearchValue = searchValue?.toLowerCase();
  
      const filteredData: any = driverList?.filter(item =>
        (item?.fullname?.toLowerCase()?.includes(lowercaseSearchValue)) 
        ||
        item?.ongoingTripDetail?.triparea?.toLowerCase()?.includes(lowercaseSearchValue)
        );
  
      setFilteredEventData(filteredData);
    }
  }, [searchValue, driverList]);

  useEffect(() => {
    setDriverData(driverList?.map((item: any) => {
        return {
          ...item, 
          address: item?.ongoingTripDetail?.triparea,
        }
      }))
  
  }, [driverList])




  useEffect(() => {
      // if (!window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.ACCESSTOKEN)) {
      //       window.location.href = '/';
      //       }
  }, [])

  /**
   * Retrieves a list of drivers asynchronously and updates the state accordingly.
   * @returns None
   */
  const getList = async (queryParams: any) => {
    setLoading(true);
     try {
       const responce = await  dispatch(driverListResponce(queryParams))   
       console.log('responce', responce);
                    
       if (responce?.payload?.driverData?.length < 10) {
        setLoadMore(false)
       } else {
        setLoadMore(true); // Set to true if more items can be loaded
    }
       pageSizeRef.current += responce?.payload?.driverData?.length;
       setPlaceHolder(false);
       setLoading(false);
     } catch (error) {
      setPlaceHolder(false);
      setLoading(false);
     }
  }

  
  useEffect(() => {
    window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.DRIVERSEARCHVALUE, searchValue)
 
  }, [searchValue]);

  const columns = [
    {
      label: (
        <>
          {t("DRIVER_NAME")} <span className="th-count">{ searchValue === null || searchValue === '' ? totalDriver : filteredEventData?.length}</span>
        </>
      ),
      key: "fullname",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("DRIVER_CODE")}
        </>
      ),
      key: "driverpin",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("address")}
        </>
      ),
      key: "address",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("ONGOING_TRIP_DETAILS")}
        </>
      ),
      key: "ongoingTripDetail",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("TRIP_STATUS")}
        </>
      ),
      key: "status",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("SCHEDULE_TRIP")}
        </>
      ),
      key: "schedule",
      filter: false,
      sorting: false,
    },
    {
      label: (
        <>
          {t("ACTIONS")}
        </>
      ),
      key: "actions",
      filter: false,
      sorting: false,
    },
  ];
  /**
   * Handles the deletion of a driver.
   * @returns None
   */
  const onDeleteDriver = async () => {
    if (!buttonDisable.current) {
      setDeleteBtnLoader(true)
      buttonDisable.current = true
      dispatch(deleteDriverResponce(driverId)).then((resultAction) => {
        if (deleteDriverResponce.fulfilled.match(resultAction)) {
          dispatch(
            showToast({
              show: false,
              message: (resultAction as any)?.payload.res.message,
              type: "success",
            })
          );
          setDeleteBtnLoader(false)
          closeDeleteDriverModal();
          buttonDisable.current = false
        } else if (deleteDriverResponce.rejected.match(resultAction)) {
          setDeleteBtnLoader(false)
          closeDeleteDriverModal();
          buttonDisable.current = false
          dispatch(
            showToast({
              show: false,
              message: resultAction?.error?.message,
              type: "error",
            })
          );
        }
      })

    }

  }


  /**
   * Handles the action when a checkbox is checked.
   * @param {any} e - The event object.
   * @param {any} item - The item associated with the checkbox.
   * @returns None
   */
  const onCheckAction = (e: any, item: any) => {
    setItemName(item?.fullname);
    const status = item?.status;
    if (status === "active") {
      setShowStatusModal(true);
      setDriverId(item?._id);
    } else {
      UnableAction(e, item);

    }
  };

  /**
   * Handles the process of enabling a disabled driver.
   * @returns None
   */
  const onProcced = async () => {
    setEnableBtnLoader(true)
    const params = {
      status: 'deactive',
      _id: driverId
    }
    dispatch(enableDisbledDriverResponce(params)).then((resultAction) => {
      if (enableDisbledDriverResponce.fulfilled.match(resultAction)) {
        dispatch(
          showToast({
            show: false,
            message: (resultAction as any)?.payload.res.message,
            type: "success",
          })
        );
        setShowStatusModal(false);
        setEnableBtnLoader(false);
      } else if (enableDisbledDriverResponce.rejected.match(resultAction)) {
        setShowStatusModal(false);
        setEnableBtnLoader(false);
        dispatch(
          showToast({
            show: false,
            message: resultAction?.error?.message,
            type: "error",
          })
        );
      }
    })
  };

  /**
   * Handles the action of enabling a disabled driver.
   * @param {any} e - The event object.
   * @param {any} item - The item object representing the driver.
   * @returns None
   */
  const UnableAction = async (e: any, item: any) => {

    if (!buttonDisable.current) {
      buttonDisable.current = true
      const params = {
        status: 'active',
        _id: item._id
      }
      dispatch(enableDisbledDriverResponce(params)).then((resultAction) => {
        if (enableDisbledDriverResponce.fulfilled.match(resultAction)) {
          dispatch(
            showToast({
              show: false,
              message: (resultAction as any)?.payload.res.message,
              type: "success",
            })
          );
          closeDeleteDriverModal();
          buttonDisable.current = false
        } else if (enableDisbledDriverResponce.rejected.match(resultAction)) {
          closeDeleteDriverModal();
          buttonDisable.current = false
          dispatch(
            showToast({
              show: false,
              message: resultAction?.error?.message,
              type: "error",
            })
          );
        }
      })

    }
  };

  /**
   * Opens the "Add Driver" modal by setting the state of `showModal` to 'add' and
   * then showing the modal after a delay of 1000 milliseconds.
   * @returns None
   */
  const addDriverModal = () => {
    setShowModal({ modal: 'add' });
    setTimeout(() => {
      setShowAddEditDriverModal(true);
    }, 1000);
  };

  /**
   * Sets the values of the create form using the provided form object.
   * @param {any} form - The form object containing the values to set.
   * @returns None
   */
  const driverAddEditFunction = (form: any) => {
    setCreateValues(form);
  };

  /**
   * Closes the add/edit modal by setting the state variables `setShowAddEditDriverModal` to false
   * and `setPhoneNumbers` to an empty string.
   * @returns None
   */
  const closeAddEditModal = () => {
    setShowAddEditDriverModal(false);
    setPhoneNumbers('')
  };

  /**
   * Loads more items when triggered, with a delay of 2000 milliseconds.
   * @returns None
   */
  const loadMoreItems = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      pageSizeRef.current += 10;
    }, 2000);
  };
  /**
   * Debounces the handleTableScroll function to prevent it from being called too frequently.
   * When the table container is scrolled, it checks if the scroll position is at the bottom
   * and if there are more items to load. If both conditions are met, it calls the loadMoreItems function.
   * @returns None
   */
  const handleTableScroll = debounce(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      const isAtBottom =
        tableContainer.scrollTop + tableContainer.clientHeight >=
        tableContainer.scrollHeight;
        console.log('totalDriver: ', totalDriver, driverList?.length);
      if (totalDriver > driverList?.length) {
        dispatch(setQueryParams({page: driverQueryParams?.page + 1, doc: driverQueryParams.doc}))
        getList({page: driverQueryParams?.page + 1, doc: driverQueryParams.doc})
        // loadMoreItems();
      }
    }
  }, 200);

  /**
   * Adds an event listener to the table container element to handle scroll events.
   * The event listener is added when the component mounts and removed when the component unmounts.
   * @param {Function} handleTableScroll - The function to handle the table scroll event.
   * @returns None
   */
  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleTableScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleTableScroll);
      }
    };
  }, [handleTableScroll]);
  /**
   * useEffect hook that calculates and sets the heights of various elements on the page.
   * @returns None
   */
  useEffect(() => {
    const headerHeight = document.getElementsByClassName("navbar-header")[0] as HTMLElement;
    const pageTitleHeight = document.getElementsByClassName("heading")[0] as HTMLElement;
    const buttonRowHeight = document.getElementsByClassName("addBtn")[0] as HTMLElement;
    if (headerHeight) {
      setHeaderHeight(headerHeight.offsetHeight);
    }
    if (pageTitleHeight) {
      setPageTitleHeight(pageTitleHeight.offsetHeight);
    }
    if (buttonRowHeight) {
      setButtonRowHeight(buttonRowHeight.offsetHeight);
    }
  }, []);

  /**
   * useEffect hook that updates the phoneNumbers state variable when the driverValue changes.
   * If the driverValue has a defined and non-empty phonenumber property, it sets the phoneNumbers
   * state variable to the value of the phonenumber property.
   * @param {any} driverValue - The driverValue object.
   * @returns None
   */
  useEffect(() => {
    if ((driverValue as any)?.phonenumber !== undefined && (driverValue as any)?.phonenumber !== '') {
      setPhoneNumbers((driverValue as any)?.phonenumber)
    }
  }, [driverValue])


  /**
   * Handles the submission of driver data.
   * @param {any} data - The data to submit.
   * @returns None
   */
  const handleSubmitDriver = async (data: any) => {
     const params = {
      driverName: data?.driverName?.trim(),
      phone: data?.phone
     }   
    if (showTypeModal.modal === "add") {
      if (!buttonDisable.current) {
        setAddAndEditLoader(true)
        buttonDisable.current = true

        dispatch(createDriverResponce(params)).then((resultAction) => {
          if (createDriverResponce.fulfilled.match(resultAction)) {
            getList({page: 1, doc: 10})
            dispatch(
              showToast({
                show: false,
                message: (resultAction as any)?.payload?.message,
                type: "success",
              })
            );
            setShowAddEditDriverModal(false);
            setAddAndEditLoader(false)
            setphoneNumberError("");
            setPhoneNumbers('')
            buttonDisable.current = false
          } else if (createDriverResponce.rejected.match(resultAction)) {
            setPhoneNumbers('')
            setphoneNumberError("");
            buttonDisable.current = false
            dispatch(
              showToast({
                show: false,
                message: resultAction?.error?.message,
                type: "error",
              })
            );
            setShowAddEditDriverModal(false);
            setAddAndEditLoader(false)
            setNumberValue(null)
          }
        })
      }
      setNumberValue(null)
    } else {
      if (!buttonDisable.current) {
        setAddAndEditLoader(true)
        buttonDisable.current = true
        const params = {
          _id: driverId,
          phone: data?.phone,
          driverName: data?.driverName
        }
        dispatch(updateDriverResponce(params)).then((resultAction) => {
          if (updateDriverResponce.fulfilled.match(resultAction)) {

            dispatch(
              showToast({
                show: false,
                message: (resultAction as any)?.payload.res.message,
                type: "success",
              })
            );
            setPhoneNumbers('')
            setShowAddEditDriverModal(false);
            setAddAndEditLoader(false)
            buttonDisable.current = false
          } else if (updateDriverResponce.rejected.match(resultAction)) {
            buttonDisable.current = false
            setPhoneNumbers('')
            setAddAndEditLoader(false)
            dispatch(
              showToast({
                show: false,
                message: resultAction?.error?.message,
                type: "error",
              })
            );
            setShowAddEditDriverModal(false);
          }
        });

      }
    }
  };

  /**
   * Updates the driver information and opens the edit modal.
   * @param {any} item - The driver object to update.
   * @returns None
   */
  const onUpdateDriver = (item: any) => {
    setShowModal({ modal: 'edit' });
    setUpdatedNumber(item?.phonenumber);
    setDriverNameValue(item.fullname);
    setDriverValue(item)
    setDriverId(item?._id);
    setShowAddEditDriverModal(true)
  };

  /**
   * Executes a side effect when the dependencies change. This effect updates the driver values
   * with the provided values for driver name and phone number.
   * @param {Function} updateDriverValues - The function to update the driver values.
   * @param {string} driverNameValue - The updated driver name value.
   * @param {string} updatedNumber - The updated phone number value.
   * @returns None
   */
  useEffect(() => {
    if (updateDriverValues !== null) {

      updateDriverValues.setValue("driverName", driverNameValue, {
        shouldValidate: true,
      });
      updateDriverValues.setValue("phone", updatedNumber, {
        shouldValidate: true,
      });
    }

  }, [updateDriverValues, driverNameValue]);

  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [phoneNumberError, setphoneNumberError] = useState("");
  const [phoneTouched, setPhoneTouched] = useState(false);
  return (
  <>
    <Helmet>
      <title>Driver Management | Scooply Admin Panel</title>
      <meta name="description" content="Add new drivers, edit driver details, see ongoing trips and more." />
    </Helmet>
    <DriverManagementComponent
      showTypeModal={showTypeModal}
      handleSubmitDriver={handleSubmitDriver}
      driverAddEditFunction={driverAddEditFunction}
      onUpdateDriver={onUpdateDriver}
      onSlideSwitch={onCheckAction}
      columns={columns}
      showAddEditDriverModal={showAddEditDriverModal}
      addDriverModal={addDriverModal}
      showDeleteDriverModal={showDeleteDriverModal}
      openDeleteDriverModal={(e, item) => {
        openDeleteDriverModal(e, item);
      }}
      closeDeleteDriverModal={closeDeleteDriverModal}
      onDeleteDriver={onDeleteDriver}
      onProcced={onProcced}
      driverData={driverData}
      showStatusModal={showStatusModal}
      noDataImage={'https://res.cloudinary.com/zudu/image/upload/v1698916951/Scooply/web/project-image/driver.svg'}
      emptyDataTitle={t("NO_DRIVERS_ARE_ADDED_TO_THE_DATABASE")}
      noDataFoundText={t('PLEASE_CLICK_ON_BELOW_BUTTON_TO_ADD_DRIVER_TO_THE_SYSTEM')}

      NoDataTitle={t("NO_DRIVERS_TO_SHOW")}
      NoDataDesc={t("PLEASE_TRY_AGAIN")}
      searchValueData={`Your search <strong>“${searchValue}”</strong> did not match any drivers on our database.`}
      noDataAddButtonTitle={t("ADD_DRIVER")}
      searchClearTitle={t("CLEAR_SEARCH")}
      closeDeactiveModal={closeDeactivateModal}
      onTableFilterChange={onTableFilterChange}
      closeAddEditModal={closeAddEditModal}
      EmptyDataAddButton={addDriverModal}
      placeHolderShimer={placeHolder}
      dimensions={dimensions}
      onHide={function (): void {
        // throw new Error("Function not implemented.");
      }}
      numberValue={numberValue}
      setNumberValue={setNumberValue}
      defaultNumber={defaultNumber}
      setUpdatedNumber={setUpdatedNumber}
      updatedNumber={updatedNumber}
      phoneNumbers={phoneNumbers}
      phoneTouched={phoneTouched}
      setPhoneTouched={setPhoneTouched}
      setPhoneNumbers={setPhoneNumbers}
      setphoneNumberError={setphoneNumberError}
      headerHeight={headerHeight}
      pageTitleHeight={pageTitleHeight}
      buttonRowHeight={buttonRowHeight}
      driverValue={driverValue}
      searchValue={searchValue}
      itemName={itemName}
      searchFilterClear={searchFilterClear}
      loading={loading}
      tableContainerRef={tableContainerRef}
      itemsPerPage={pageSizeRef.current}
      handleTableBodyScroll={handleTableScroll}
      addAndEditLoader={addAndEditLoader}
      loaderButtonShow={deleteBtnLoader}
      setFullName={setFullName}
      fullname={fullname}
      enableBtnLoader={enableBtnLoader}
    />
  </>);
};

export default DriverManagementContainer;
