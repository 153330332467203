  // toastSlice.js
  import { createSlice } from '@reduxjs/toolkit';

  const initialState = {
    show: false,
    message: '',
    messagebold: '',
    type: 'success',
  };

  const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
      showToast: (state, action) => {
        state.show = true; // Assuming action.payload is a boolean
        state.message = action.payload.message; // Assuming action.payload has a 'message' property
        state.type = action.payload.type; // Assuming action.payload has a 'type' property
      },
      hideToast: (state) => {
        state.show = false;
        state.message = '';
      },
    },
  });

  export const { showToast, hideToast } = toastSlice.actions;
  export default toastSlice.reducer;
