import { CHANGE_PASSWORD, FORGOT, LOGIN, RESET } from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import { ChangePassParams, ForgotParams, LoginParams, ResetParams } from "../../../interface/authInterface";



/**
 * Logs in a user with the provided login parameters.
 * @param {LoginParams} data - The login parameters containing the user's email and password.
 * @returns A Promise that resolves to the result of the login request.
 */
const login = (data: LoginParams) => {
  return instance.post(LOGIN, {
    email: data?.email,
    password: data?.password,
  });
};

/**
 * Sends a POST request to the server to initiate the password reset process for a user.
 * @param {ForgotParams} data - The data object containing the user's email.
 * @returns A Promise that resolves to the response from the server.
 */
const forgot = (data: ForgotParams) => {
  return instance.post(FORGOT, {
    email: data?.email || data,
  });
};


/**
 * Sends a POST request to the server to reset the user's password.
 * @param {ResetParams} data - The reset parameters including the reset token and the new password.
 * @returns A Promise that resolves to the response from the server.
 */
const reset = (data: ResetParams) => {
  return instance.post(RESET, {
    resetToken: data.resetToken,
    password: data?.password,
  });
}


const chnagePassword = (data: ChangePassParams) => {
  return instance.post(CHANGE_PASSWORD, {
    oldPassword: data?.oldpassword,
    newPassword: data?.newpassword
  })
}


const authServices = {
  login,
  forgot,
  reset,
  chnagePassword
};

export default authServices;
