import React from "react";
import { CSmartTable } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import Card from "react-bootstrap/Card";

import EmptyCell from "../../../components/emptyCell/emptyCell";
import BackButton from '../../../components/backButton/backButton';

import Constant from '../../../util/constant';
import moment from "moment";
import ConfirmationModal from "../../../components/confirmationModal/confirmationModal";

interface OrderStatusDetailsProps {
  orderDetailsListData: any[]; // Update with actual data type
  columns: any[]; // Update with actual data type
  dimensions: any;
  tableContainerRef: any;
  itemsPerPage: number,
  headerHeight: number,
  backButtonHeight: number,
  topCardRowHeight: number,
  noDataImage?: string,
  handleTableBodyScroll: any,
  emptyDataTitle?: string,
  noDataFoundText?: string,
  orderDetailsResponce: any,
  showStatusModal: boolean;
  openRefundModal: () => void;
  onProcced: () => void;
  closeStatusModal: () => void;
  refundBtnLoader: boolean,
  refundStatus: any
}

const OrderStatusDetailsComponent: React.FC<OrderStatusDetailsProps> = (props) => {
  const { t } = useTranslation();
  const {
    headerHeight,
    backButtonHeight,
    topCardRowHeight,
    orderDetailsListData,
    columns,
    dimensions,
    noDataImage,
    itemsPerPage,
    tableContainerRef,
    handleTableBodyScroll,
    emptyDataTitle,
    noDataFoundText,
    orderDetailsResponce,
    showStatusModal,
    onProcced,
    closeStatusModal,
    openRefundModal,
    refundBtnLoader,
    refundStatus
  } = props;

  

  

  return (
    <React.Fragment>
      <div className="page-content orderstatus-details">
        <BackButton />
        <Card className="mainCard table-responsive mb-4 top-table-style">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <div className="heading">{t('BASIC_DETAILS')}</div>
              <div className='basic-details-row'>
                <div className='column-box-style'>
                  <div className='label-title-style'><i className='mdi mdi-calendar-range'></i>{t("ORDER_DATE")}</div>
                  <div className='label-text-style'>{moment(orderDetailsResponce?.orderdatetime).format('DD MMM YYYY h:mm A') || '-'}</div>
                </div>
                <div className='column-box-style'>
                  <div className='label-title-style'><i className='mdi mdi-calendar-range'></i>{t("COLLECTION_CODE")}</div>
                  <div className='label-text-style'>{orderDetailsResponce?.collectioncode}</div>
                </div>
                <div className='column-box-style'>
                  <div className='label-title-style'><i className='bx bx-dollar-circle'></i>{t("TRANSACTION_ID")}</div>
                  <div className='label-text-style'>{orderDetailsResponce?.paymentid}</div>
                </div>
                <div className='column-box-style'>
                  <div className='label-title-style'><i className='bx bx-target-lock'></i>{t("STATUS")}</div>
                  { orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW
                  ? <div className='location-text-style'>{t('REPORTED_BY_CUSTOMER_DRIVER_IS_NOT_SHOWN_AT_LOCATION')}</div> 
                   : orderDetailsResponce?.orderstatus  ===  Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW 
                   ? <div className='location-text-style'>{t('REPORTED_BY_DRIVER_CUSTOMER_IS_NOT_SHOWN_AT_LOCATION')}</div> 
                   :   <></> 
                   }
                  <div className={orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW
                    ? 'no-show status-btn-style'
                    : orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.DELIVERED
                      ? 'delivered status-btn-style'
                      : orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.PENDING
                        ? 'pending status-btn-style'
                        : orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.PAYMENT_FAILED
                          ? 'failed status-btn-style' : 'status-btn-style'
                  }>
                    {orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.DELIVERED
                     ? Constant.ORDER_STATUS.DELIVERED
                     : orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.PENDING
                     ? Constant.ORDER_STATUS.PENDING
                     :  orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW
                     ? Constant.ORDER_STATUS.NO_SHOW 
                     :   orderDetailsResponce?.orderstatus === Constant.ORDER_API_STATUS.PAYMENT_FAILED
                     ? Constant.ORDER_STATUS.FAILED
                    : ''}
                  </div>
                </div>
                

                {refundStatus[0]?.isrefunded ? <div className='column-box-style refundBtnstyle'>
                <div className='refund-btn' >{orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW ?  'Refund' : ''}</div>
                <span className="amountRefundedStyle">Amount Refunded</span>
              </div>
               : orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW 
               ?
               
               <div className='column-box-style'>
                <div className='refund-btn' onClick={openRefundModal} >{orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW ?  'Refund' : ''}</div>
              </div>
              : ''  
            }

              

               {/* { orderDetailsResponce?.isrefunded ? 'refunded' 
               :  <div className='column-box-style'>
                <div className='refund-btn' onClick={openRefundModal} >{orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW ?  'Refund' : ''}</div>
              </div>
                
               } */}


                {/* {orderDetailsResponce?.orderstatus  ===  Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW  || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW
                ?    <div className='column-box-style'>
                <div className='refund-btn' onClick={openRefundModal} >{orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.CUSTOMER_NO_SHOW || orderDetailsResponce?.orderstatus  === Constant.ORDER_API_STATUS.DRIVER_NO_SHOW ?  'Refund' : ''}</div>
              </div>
              : ''
              } */}
             
              </div>
            </div>

          </Card.Body>
        </Card>
        <div className='scrollbars-style' style={{
          maxHeight: dimensions.height - headerHeight - backButtonHeight - topCardRowHeight - 60,
          overflow: 'auto'
        }}>
          <div className='customer-driver-details'>
            <Card className="mainCard table-responsive mb-4 middle-table-style">
              <Card.Body className="p-0">
                <div className="main_card_body">
                  <div className="heading">{t('CUSTOMER_DETAILS')}</div>
                  <div className='basic-details-row'>
                    <div className='column-box-style'>
                      <div className='label-title-style'><i className='far fa-user'></i>{t("CUSTOMER")}</div>
                      <div className='label-text-style'>{orderDetailsResponce?.customerDetails?.fullname || '-'}</div>
                    </div>
                    <div className='column-box-style'>
                      <div className='label-title-style'><i className='mdi mdi-phone-outline'></i>{t("PHONE_NUMBER")}</div>
                      <div className='label-text-style'>{orderDetailsResponce?.customerDetails?.phonenumber || '-'}</div>
                    </div>
                    <div className='column-box-style'>
                      <div className='label-title-style'><i className='mdi mdi-map-marker-outline'></i>{t("ADDRESS")}</div>
                      <div className='label-text-style'>{orderDetailsResponce?.customerDetails?.location}</div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="mainCard table-responsive mb-4 middle-table-style">
              <Card.Body className="p-0">
                <div className="main_card_body">
                  <div className="heading">{t('DRIVER_DETAILS')}</div>
                  <div className='basic-details-row'>
                    <div className='column-box-style'>
                      <div className='label-title-style'><i className='bx bx-bus'></i>{t("DRIVER")}</div>
                      <div className='label-text-style'>{orderDetailsResponce?.driverDetails?.fullname}</div>
                    </div>
                    <div className='column-box-style'>
                      <div className='label-title-style'><i className='mdi mdi-phone-outline'></i>{t("PHONE_NUMBER")}</div>
                      <div className='label-text-style'>{orderDetailsResponce?.driverDetails?.phonenumber}</div>
                    </div>
                    <div className='column-box-style'>
                      <div className='label-title-style'><i className='mdi mdi-map-marker-outline'></i>{t("TRIP_ID")}</div>
                      <div className='label-text-style'>{orderDetailsResponce?.tripDetails?.tripid}</div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <Card className="mainCard table-responsive">
            <Card.Body className="p-0">
              <div className="main_card_body">
                <div className="heading">{t("ORDER_DETAILS")}</div>
                <CSmartTable
                  cleaner
                  clickableRows
                  columns={columns}
                  columnFilter={false}
                  items={orderDetailsListData}
                  itemsPerPage={itemsPerPage}
                  scopedColumns={{
                    productname: (item: any) => {
                      const className = item.status === "deactive" ? "disableTd" : "";
                      return (
                        <td className={className}>
                          <div className="driver-wrapper">
                            <div className="driverName">
                              {item?.productname || '-'}
                            </div>
                            <div className="phone">
                              {item?.description || '-'}
                            </div>
                          </div>
                        </td>
                      );
                    },
                    count: (item: any) => {
                      return (
                        <td
                          className={item.status === "deactive" ? "disableTd" : ""}
                        >
                          <div className="driver-wrapper">
                          <div>
                            {item?.count} x £{item?.price}
                            </div>
                          </div>
                        </td>
                      );
                    },
                    total: (item: any) => {
                      return (
                        <td
                          className={item.status === "deactive" ? "disableTd" : ""}
                        >
                          <div className="driver-wrapper">
                            {/* {placeHolderShimer && <div className="Shimer"></div>} */}
                            <div>
                              {(item?.count * item?.price).toFixed(2)}
                            </div>
                          </div>
                        </td>
                      );
                    },
                  }}
                  tableFilter={false}
                  tableProps={{
                    className: "driver-table",
                    responsive: true,
                    striped: false,
                    hover: false,
                  }}
                  noItemsLabel={
                    // !placeHolderShimer &&
                    <EmptyCell
                      emptyItems={orderDetailsListData}
                      emptyDataTitle={emptyDataTitle}
                      noDataFoundText={noDataFoundText}
                      noDataAddButton={false}
                      noDataImage={noDataImage}
                      noDataTitle={''} />
                  }
                  tableBodyProps={{
                    className: "align-middle table-body-scroll",
                    style: {
                      overflow: 'auto'
                    },
                    // ref: tableContainerRef,
                    onScroll: handleTableBodyScroll,
                  }}
                  ref={tableContainerRef}
                />
                {orderDetailsListData?.length !== 0 && <div className='total-amount-row-style'>
                  <div className='label-title-style'>
                    {t('TOTAL')}
                  </div>
                  <div className='amount-text-style'>
                    £ {orderDetailsResponce?.orderamount}
                  </div>
                </div>}

                {/* <CustomLoadingHTML /> */}
              </div>
            </Card.Body>
          </Card>
        </div>

      </div>
       {/* Disable Model Message Model */}
       <ConfirmationModal
        className={"deactivate refund-modal"}
        isModelIcon={true}
        onHide={closeStatusModal}
        iconColor={"mdi mdi-toggle-switch-off-outline "}
        modalTitle={t("REFUND")}
        modalDescription={t("ARE_YOU_SURE_YOU_WANT_TO_REFUND")}
        isUnderline={false}
        showModal={showStatusModal}
        leftBtnTitle={t("YES_REFUND")}
        isFirstButton={true}
        leftBtnClass={"confirm-btn"}
        onProcced={onProcced}
        rightBtnClass="cancel-btn"
        isSecondButton={true}
        rightBtnTitle={t("CANCEL")}
        rightButtonClick={closeStatusModal}
        messagebold={`£ ${orderDetailsResponce?.orderamount} <span style=' font-weight:400'>to</span> "${orderDetailsResponce?.customerName}"`}
        isBold={true}
        leftdisabled={false}
        rightdisabled={false}
        modalContent={true}
        loaderButtonShow={refundBtnLoader}
      />
    </React.Fragment >
  );
};
export default OrderStatusDetailsComponent;
