import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import orderService from "../services/orderService";


interface refundPaymentParams {
  cusId: string,
  paymentId: string
}

export const getAllOrders = createAsyncThunk("order/get", async (data: string) => {
  const res = await orderService.getAllOrders(data);
  return res?.data;
});

export const getOrderDetail = createAsyncThunk('order/orderDetail', async(orderId: string) => {
  const res = await orderService.getOrderDetail(orderId);
  return res.data;
})

export const refundPayment = createAsyncThunk("oreder/refund", async (data: refundPaymentParams) => {
    const res = await orderService.refundPayment(data);
    return {res, params: data}
})

interface AuthState {
    ordersListResponce:  any,
    refundApiResponce: any,
    refundApiError: any,
    isLoading: boolean,
    orderDetailResponse: any
  }

const orderManagementSlice = createSlice({
    name: "order",
    initialState: {
      orderDetailResponse: {},
      ordersListResponce: [],
      refundApiResponce: {},
      refundApiError: {},
      isLoading: false
    } as AuthState,
    reducers: {},
    extraReducers: (builder) => {

 // refund Api
      builder
        .addCase(refundPayment.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(refundPayment.fulfilled, (state, action) => {          
          state.isLoading = false;
          state.refundApiResponce = action.payload.res;
          //    state.ordersListResponce = state?.ordersListResponce.map(((item: { paymentId: any; }) => {
          //     if (item?.paymentId === action.payload?.params?.paymentId) {
          //        return {
          //         ...item,
          //         isrefunded: true
          //        }
          //     }
          // }))
        })
        .addCase(refundPayment.rejected, (state, action) => {
          state.isLoading = false;
          state.refundApiError = action?.error
        });


        builder
        .addCase(getAllOrders.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getAllOrders.fulfilled, (state, action) => {          
          state.isLoading = false;
          state.ordersListResponce = action.payload;
        })
        .addCase(getAllOrders.rejected, (state) => {
          state.isLoading = false;
        });

        builder
        .addCase(getOrderDetail.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getOrderDetail.fulfilled, (state, action) => {          
          state.isLoading = false;
          state.orderDetailResponse = action.payload;
        })
        .addCase(getOrderDetail.rejected, (state) => {
          state.isLoading = false;
        });

    },
  });


export default orderManagementSlice.reducer;