import React from "react";
import { CSmartTable } from "@coreui/react-pro";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Card from "react-bootstrap/Card";

import EmptyCell from "../../components/emptyCell/emptyCell";
import CustomLoadingHTML from "../../components/customLoadingHTML/customLoadingHTML";
import ConfirmationModal from '../../components/confirmationModal/confirmationModal';
import CustomToggleButton from "../../components/customToggleButton/customToggleButton";
import { customerData } from "../../interface/customerInterface";
import Constant from "../../util/constant";

interface CustomerManagementProps {
  customerData: customerData[]; // Update with actual data type
  columns: any[]; // Update with actual data type
  placeHolderShimer: boolean;
  onSlideSwitch: (e: any, item: customerData) => void; // Update with actual data type
  onTableFilterChange: (item: any) => void;
  emptyDataTitle: string;
  NoDataTitle: string;
  NoDataDesc: string;
  searchClearTitle: string;
  noDataFoundText: string;
  searchValueData: string;
  showStatusModal: boolean;
  onProcced: () => void;
  closeDeactiveModal: () => void;
  noDataAddButtonTitle: string;
  dimensions: any;
  loading: boolean;
  tableContainerRef: any; // Update with actual data type
  itemsPerPage: number,
  defaultNumber: any,
  headerHeight: any,
  pageTitleHeight: any,
  buttonRowHeight: any,
  searchValue: any,
  noDataImage: string,
  searchFilterClear: any,
  itemName: string,
  handleTableBodyScroll: () => void,
  enableBtnLoader: boolean,
  backButtonShow?: boolean

}


/**
 * A functional component that represents the Driver Management page.
 * @param {DriverManagementProps} props - The props for the component.
 * @returns The rendered Driver Management page.
 */
const CustomerManagementComponent: React.FC<CustomerManagementProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    headerHeight,
    pageTitleHeight,
    buttonRowHeight,
    customerData,
    columns,
    onSlideSwitch,
    onTableFilterChange,
    NoDataTitle,
    emptyDataTitle,
    noDataFoundText,
    NoDataDesc,
    noDataAddButtonTitle,
    searchClearTitle,
    showStatusModal,
    onProcced,
    closeDeactiveModal,
    dimensions,
    placeHolderShimer,
    searchFilterClear,
    searchValue,
    noDataImage,
    itemName,
    loading,
    itemsPerPage,
    tableContainerRef,
    handleTableBodyScroll,
    searchValueData,
    enableBtnLoader
  } = props;
  console.log('customerData', customerData)
  return (
    <React.Fragment>
      <div className="page-content customer-management">
        <div className="heading">{t('CUSTOMER_MANAGEMENT')}</div>
        <Card className="mainCard table-responsive">
          <Card.Body className="p-0">
            <div className="main_card_body">
              <div className={searchValue?.length > 200 ? "addBtn height" : "addBtn"}>
                {searchValue?.length > 0 &&
                  <button
                    onClick={searchFilterClear}
                    className="clear-btn-style"
                  >
                    <i className='mdi mdi-window-close'></i>
                  </button>
                }
                {searchValue?.length > 200 &&
                  <span className='search-error-style'>{t('ALLOWED_MAX_200_CHARACTERS')}</span>
                }
              </div>

              <CSmartTable
                cleaner
                clickableRows
                columns={columns}
                columnFilter={false}
                items={customerData}
                itemsPerPage={itemsPerPage}
                // onRowClick={(item) => { navigate("/driver-management/details", { state: item }); }}
                scopedColumns={{
                  fullname: (item: customerData) => {
                    const className = item?.status === t("DEATCTIVE") ? "disableTd" : "";
                    return (
                      <td className={className} >
                        <div className="td-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div className="driverName">
                            {item?.fullname || '-'}
                          </div>
                        </div>
                      </td>
                    );
                  },
                  phonenumber: (item: customerData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="td-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {item?.phonenumber || '-'}
                        </div>
                      </td>
                    );
                  },
                  location: (item: customerData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="td-wrapper">
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div>
                            {item?.location || '-'}
                          </div>
                        </div>
                      </td>
                    );
                  },
                  nooforders: (item: customerData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className='td-wrapper'>
                          {placeHolderShimer && <div className="Shimer"></div>}
                          <div>
                            {item?.count || '0'}
                          </div>
                        </div>

                      </td>
                    );
                  },
                  actions: (item: customerData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="action-wrapper">
                          {placeHolderShimer && (
                            <div className="Shimer"></div>
                          )}

                          <CustomToggleButton
                            id=''
                            checked={item?.status === t("ACTIVE") ? true : false}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onCheckChange={(e) => {
                              onSlideSwitch(e, item);
                            }}
                          />
                        </div>
                      </td>
                    );
                  },
                  vieworders: (item: customerData) => {
                    return (
                      <td
                        className={item?.status === t("DEATCTIVE") ? "disableTd" : ""}
                      >
                        <div className="td-wrapper" onClick={(e) => {
                          window.localStorage.setItem(Constant.LOCALSTORAGEKEYS.CUSTOMERNAME,  (item?.fullname as any))
                          navigate("/order-management", { state: { customerDataFilter: item,  backButtonShow: true } });
                          e.stopPropagation();
                        }}>
                          {placeHolderShimer && <div className="Shimer"></div>}
                          {/* {item.status} */}
                          <i className='fas fa-chevron-right'></i>
                        </div>
                      </td>
                    );
                  },
                }}
                tableFilter={true}
                tableFilterValue={(window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERSEARCHVALUE) as string) === '' ? '' : (window.localStorage.getItem(Constant.LOCALSTORAGEKEYS.CUSTOMERSEARCHVALUE) as string)}
                tableFilterPlaceholder={t("SEARCH_BY_CUSTOMER_NAME_OR_PHONE_NUMBER")}
                onTableFilterChange={onTableFilterChange}
                tableProps={{
                  className: placeHolderShimer ? "customer-table shimer-row-style" : "customer-table",
                  responsive: true,
                  striped: false,
                  hover: false,
                }}
                noItemsLabel={
                  !placeHolderShimer &&
                  <EmptyCell
                    emptyItems={customerData}
                    noDataTitle={NoDataTitle}
                    noDataAddButton={false}
                    noDataDesc={NoDataDesc}
                    emptyDataTitle={emptyDataTitle}
                    noDataFoundText={noDataFoundText}
                    noDataImage={noDataImage}
                    noDataAddButtonTitle={noDataAddButtonTitle}
                    searchClearTitle={searchClearTitle}
                    searchClearButton={searchFilterClear}
                    searchValueData={searchValueData}
                  />
                }
                tableBodyProps={{
                  className: "align-middle table-body-scroll",
                  style: {
                    maxHeight: dimensions.height - headerHeight - pageTitleHeight - buttonRowHeight - 126, overflow: 'auto'
                  },
                  // ref: tableContainerRef,
                  onScroll: handleTableBodyScroll,
                }}
                ref={tableContainerRef}
              />
              {loading && <CustomLoadingHTML />}
            </div>
          </Card.Body>
        </Card>

        {/* Disable Model Message Model */}
        <ConfirmationModal
          className={"deactivate"}
          isModelIcon={true}
          onHide={closeDeactiveModal}
          iconColor={"mdi mdi-toggle-switch-off-outline "}
          iconCricle={""}
          modalTitle={t("DEACTIVATE_CUSTOMER")}
          modalDescription={t("ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_CUSTOMER")}
          isUnderline={false}
          showModal={showStatusModal}
          leftBtnTitle={t("CONFIRM")}
          isFirstButton={true}
          leftBtnClass={"confirm-btn"}
          onProcced={onProcced}
          rightBtnClass="cancel-btn"
          isSecondButton={true}
          rightBtnTitle={t("CANCEL")}
          rightButtonClick={closeDeactiveModal}
          messagebold={`"${itemName}"`}
          isBold={true}
          leftdisabled={false}
          rightdisabled={false}
          modalContent={true}
          loaderButtonShow={enableBtnLoader} />
      </div>
    </React.Fragment>
  );
};

export default CustomerManagementComponent;
