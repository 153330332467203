import { ENABLE_DISBLED_CUSTOMER, GET_CUSTOMER } from "../../../api/endPoint";
import instance from "../../../helpers/axios";
import { enableCustomerParams } from "../../../interface/customerInterface";




/**
 * Retrieves customer data from the server.
 * @returns {Promise} A promise that resolves to the customer data.
 */
const getCustomer = (queryParams: any) => {
    return instance.get(GET_CUSTOMER, { params: queryParams})
}

/**
 * Enable or disable a customer based on the provided data.
 * @param {enableCustomerParams} data - The data object containing the customer ID and status.
 * @returns A promise that resolves to the result of the PUT request.
 */
const enableDisbledCustomer = (data: enableCustomerParams) => {
    return instance.put(ENABLE_DISBLED_CUSTOMER, {
        id: data?.id,
        status: data?.status 
    })
}

const customerService = {
    getCustomer,
    enableDisbledCustomer
}

export default customerService;